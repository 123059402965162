import { all, fork } from 'redux-saga/effects';

import general from './general/saga';
import circleAnalyzer from './circleAnalyzer/saga';
import notification from './notification/saga';
import tournamentSearch from './tournamentSearch/saga';
import tournament from './tournament/saga';
import teamRotationSearch from './teamRotationSearch/saga';
import fileSystem from './fileSystem/saga';
import register from './register/saga';
import verification from './verification/saga';
import forgotPassword from './forgotPassword/saga';
import favoriteUsers from './favoriteUsers/saga';
import resetPassword from './resetPassword/saga';
import changePassword from './changePassword/saga';
import settings from './settings/saga';
import matchAnalyzer from './matchAnalyzer/saga';
import mapCanvasState from './mapCanvasState/saga';
import asideState from './asideState/saga';
import fileState from './fileState/saga';
import rotationAnalyzerV2State from './rotationAnalyzerV2State/saga';
import matchStats from './matchStats/saga';
import track from './track/saga';
import payment from './payment/saga';
import collaboration from './collaboration/saga';

// The root saga
function* rootSaga() {
    // Add all active sagas here to combine them
    yield all([
        fork(general),
        fork(circleAnalyzer),
        fork(notification),
        fork(tournamentSearch),
        fork(tournament),
        fork(teamRotationSearch),
        fork(fileSystem),
        fork(register),
        fork(verification),
        fork(forgotPassword),
        fork(favoriteUsers),
        fork(resetPassword),
        fork(changePassword),
        fork(settings),
        fork(matchAnalyzer),
        fork(mapCanvasState),
        fork(asideState),
        fork(fileState),
        fork(rotationAnalyzerV2State),
        fork(matchStats),
        fork(track),
        fork(payment),
        fork(collaboration),
    ]);
}

export default rootSaga;