import { 
  REQUEST_TOURNAMENTS_PENDING,
  REQUEST_TOURNAMENTS_SUCCESS,
  REQUEST_TOURNAMENTS_ERROR,
} from './types';

const INITIAL_STATE = {
  tournaments: [],
  tournamentsRecent: null,
  tournamentsAll: null,
  tournamentsLoaded: false,
  tournamentsError: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On tournaments loading
    case REQUEST_TOURNAMENTS_PENDING:
      return {
        ...state,
        tournamentsLoaded: false,
        tournamentsError: false,
        matches: [],
        matchesLoaded: true,
        matchesError: false,
        noMatchesMessage: null,
      };
      
    // On successfully loaded tournaments
    case REQUEST_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        tournamentsLoaded: true,
        tournamentsError: false,
        tournamentsRecent: action.payload.tournamentsRecent,
        tournamentsAll: action.payload.tournamentsAll,
      };

    // On tournaments fetch error
    case REQUEST_TOURNAMENTS_ERROR:
      return {
        ...state,
        tournamentsLoaded: true,
        tournamentsError: true
      };

    default:
      return state;
  }
}

export default reducer;