export default {
  menu: {
    matchAnalyzer: 'Match Analyzer',
    tournaments: 'Tournaments',
    news: 'News',
    pgcPoints: 'PGC Points',
    rankings: 'Rankings',
    scrims: 'Scrims',
    userMatches: 'User Matches',
    rotationAnalyzer: 'Rotation Analyzer',
    teamsRotations: 'Team\'s Rotations',
    saved: 'Saved',
    savedMatches: 'Saved Matches',
    productionOverlays: 'Production overlays',
    reportProblem: 'Report a problem',
    discord: 'Join our Discord',
    twitter: 'Follow us on Twitter',
  },
  headerMenu: {
    hi: 'Hi',
    account: 'Account',
    settings: 'Settings',
    contact: 'Contact us',
    logout: 'Logout',
    logoutError: 'Error while logging out. Please try again!',
    goPremium: 'Go Premium',
  },
  common: {
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    search: 'Search',
    ok: 'OK',
    showMore: 'Show More',
    close: 'close',
    loading: 'Loading...',
    learnMore: 'Learn More',
    beta: 'Beta',
    warning: 'Warning',
    goTo: 'Go to',
    goToApp: 'Go To App',
    login: 'Login',
    pleaseWait: 'Please wait...',
    success: 'Success!',
    signUp: 'Sign Up',
  },
  settings: {
    basicSettings: 'Basic Settings',
    username: 'Username',
    email: 'Email',
    subscription: 'Subscription',
    plan: 'Plan',
    validTo: 'Valid To',
    freePlan: 'Free',
    trialPlan: 'Trial',
    premiumPlan: 'Premium',
    manageSubscription: 'You can manage your subscription plan on your PayPal account.',
    pubgUsernames: 'PUBG Usernames (only PUBG PC)',
    pubgUsername: 'PUBG Username',
    enterPubgUsername: 'Please enter PUBG username (case sensitive)',
    status: 'Status',
    pendingConfirmation: 'Pending confirmation',
    invlid: 'Invalid',
    active: 'Active',
    password: 'Password',
    notifications: 'Notifications',
    emailNotifications: 'Email Notifications',
    currentPassword: 'Current Password',
    enterCurrentPassword: 'Please enter your current password',
    newPassword: 'New Password',
    enterNewPassword: 'Please enter your new password',
    passwordConfirmation: 'Password Confirmation',
    enterPasswordConfirmation: 'Please re-enter your new password',
    changePassword: 'Change Password',
    promoCode: 'Promotion code',
    use: 'use',
    wrongPromoCode: 'Wrong promotional code',
    promoCodeSuccess: 'Promotional code used successfully. Page will reload',
  },
  regions: {
    allRegions: 'All Regions',
    global: 'Global',
    asia: 'Asia',
    europe: 'Europe',
    southAmerica: 'South America',
    northAmerica: 'North America',
    oceania: 'Oceania',
    apac: 'APAC',
  },
  tournamentSearch: {
    tournamentFilter: 'Tournament Filter',
    tournamentName: 'Tournament Name',
    clearFilter: 'Clear Filter',
    missingTournament: 'Missing Tournament?',
    recentTournaments: 'Recent Tournaments',
    allTournaments: 'All Tournaments',
    tournament: 'Tournament',
    createTournament: 'Create a Tournament',
    noTournaments: 'No tournaments',
  },
  content: {
    title: 'News',
  },
  teamRanking: {
    title: 'Team Ranking',
    how: 'How ranking is calculated?',
    explanation: 'Team ranking takes into account competitions in last 3 months. To calculate ranking we consider group stages and finals of Tier S, some group stages and all finals of Tier A, and some finals of Tier B competitions. All tournaments are appropriately weighted according to their tier, region, and date. Ranking also includes decay, which means that team score will drop over time if they are inactive or if there were some roster changes. Ranking is implemented in a way that teams can quickly show up among top 100 teams but it is hard to climb top spots and retain the position without good performance.',
  },
  comment: {
    newCommentTitle: 'Write a comment',
    newCommentPlaceholder: 'Your comment',
  },
  tournament: {
    tournament: 'Tournament',
    tournamentInfo: 'Tournament Info',
    region: 'Region',
    matches: 'Matches',
    leaderboards: 'Leaderboards',
    prizeLeaderboards: 'Prize Money Leaderboard',
    playerStats: 'Player Stats',
    teamStats: 'Team Stats',
    selectTournament: 'Select a tournament',
    noMatchesFound: 'No matches found',
    noLeaderboards: 'No leaderboards available for this tournament',
    leaderboardsNotFound: 'Leaderboard does not exists or no matches has been played yet. Try again later',
    noPlayerStats: 'No stats available for this tournament',
    playerStatsNotFound: 'Stats do not exist or no matches has been played yet. Try again later',
    tournamentPhase: 'Tournament phase',
    selectTournamentPhase: 'Select tournament phase',
    loadingMatches: 'Loading matches...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'Search by Username',
    pubgUsername: 'PUBG Username (case sensitive)',
    matchesOf: 'Matches of',
    enterPubgUsername: 'Enter PUBG username',
    usernameNotTracked: 'We are not tracking this username so we might not have all the matches. You can add username in',
    settings: 'settings',
    favoriteUsers: 'Favorites',
  },
  match: {
    rank: 'Rank',
    date: 'Date',
    match: 'Match',
    map: 'Map',
    type: 'Type',
    zone: 'Zone',
    analyzeStats: 'Analyze/Stats',
    mode: 'Mode',
    duration: 'Duration',
  },
  matchDetails: {
    matchDetails: 'Match Details',
    matchStats: 'Match Statistics',
    match: 'Match',
    map: 'Map',
    mode: 'Mode',
    duration: 'Duration',
  },
  matchStats: {
    rank: 'Rank',
    wins: 'WWCD',
    totalPoints: 'Total Points',
    avgTotalPoints: 'Avg Total Points',
    avgRank: 'Avg Rank',
    points: 'Placement Points',
    avgPoints: 'Avg Placement Points',
    twr: 'TWR',
    numOfMatches: 'Number of Matches',
    team: 'Team',
    player: 'Player',
    username: 'Player',
    kills: 'Kills',
    avgKills: 'Avg Kills',
    assists: 'Assists',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Damage Dealt',
    avgDamageDealt: 'Avg Damage Dealt',
    damageTaken: 'Damage Taken',
    avgDamageTaken: 'Avg Damage Taken',
    dbnos: 'Knocks',
    revives: 'Revives',
    headshotKills: 'Headshot Kills',
    longestKill: 'Longest Kill (m)',
    killSteals: 'Stolen Kills',
    killsStolenFrom: 'Stolen Kills From Player',
    grenadePickup: 'Grenades Picked',
    grenadeDrop: 'Grenades Dropped',
    grenadeThrow: 'Grenades Thrown',
    grenadeDamage: 'Grenade Damage',
    molotovPickup: 'Molotovs Picked',
    molotovDrop: 'Molotovs Dropped',
    molotovThrow: 'Molotovs Thrown',
    molotovDamage: 'Molotovs Damage',
    smokebombPickup: 'Smokes Picked',
    smokebombDrop: 'Smokes Dropped',
    smokebombThrow: 'Smokes Thrown',
    flashbangPickup: 'Flashes Picked',
    flashbangDrop: 'Flashes Dropped',
    flashbangThrow: 'Flashes Thrown',
    swimDistance: 'Swim Distance (km)',
    walkDistance: 'Walk Distance (km)',
    rideDistance: 'Ride Distance (km)',
    timeSurvived: 'Time Survived',
    avgTimeSurvived: 'Avg Time Survived',
    deathType: 'Death Type',
    heals: 'Heals',
    healthRecovered: 'Health Recovered',
    boosts: 'Boosts',
    vehicleDestroys: 'Vehicle Destroys',
    kd: 'KD',
    killsKnocks: 'Kills/Knocks',
    arDamage: 'Damage Dealt (AR)',
    dmrDamage: 'Damage Dealt (DMR)',
    diedFirst: 'Died 1st',
    diedSecond: 'Died 2nd',
    diedThird: 'Died 3rd',
    diedForth: 'Died 4th',
    knocked: 'Knocked',
    revived: 'Revived',
    damageDealtDamageTaken: 'Damage Dealt/Damage Taken',
    damageTakenFromBlueZone: 'Damage Taken (Zone)', // TODO missing
    damageTakenFromEnemy: 'Damage Taken (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'Kills stolen - when player kills somebody that were knocked by other team',
    stolenKillsFromExplaines: 'Kills stolen from - when players knocks somebody and they get killed by enemy team',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived',
  },
  tools: {
    save: 'Save',
    undo: 'Undo',
    clearAll: 'Clear All',
    zonesToDraw: 'Draw Zone',
    pen: 'Pen',
    playerDetails: 'Player Details',
    rotationDetails: 'Rotation Details',
    playerNames: 'Player Names',
    teamNames: 'Team Names',
    teamLogos: 'Team Logos',
    showDeadPlayers: 'Show Dead Players',
    grid: 'Grid',
    planePath: 'Plane Path',
    showPlanePaths: 'Show Plane Paths',
    showRotations: 'Show Rotations',
    showCircles: 'Show Circles',
    showLandingSpots: 'Show Landing Spots',
    heatmap: 'Heatmap',
    help: 'Help',
  },
  help: {
    help: 'Help',
    tutorial: 'Tutorial',
    runTutorial: 'Run Tutorial',
    shortcuts: 'Shortcuts',
    keyBinding: 'Key Binding',
    description: 'Description',
    playPause: 'play/pause',
    stepBackward: 'step backward (2 sec.)',
    stepForward: 'step forward (2 sec.)',
    previousSpeed: 'previous playing speed',
    nextSpeed: 'next playing speed',
    gird: 'show/hide grid',
    teamNames: 'show/hide team names',
    playerNames: 'show/hide player names',
    deadPlayers: 'show/hide dead players',
    planePath: 'show/hide plane path',
    playerDetails: 'open/close player details',
    rotationDetails: 'open/close rotation details',
    fullscreen: 'turn on/off fullscreen',
  },
  matchAnalyzer: {
    names: 'Names',
    trace: 'Trace',
    emptyInventory: 'Empty Inventory',
  },
  teamsSearch: {
    searchByTeam: 'Search by Team',
    teamNameOrInitial: 'Team name or initial',
    teams: 'Teams',
    team: 'Team',
    noResults: 'No results',
    oldRotationAnalyzer: 'Old Rotation Analyzer',
    tryNow: 'Try it Now',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Rotation Analyzer',
    selectMap: 'Select Map to Analyze',
    match: 'Match',
    hideAll: 'Hide All',
    show: 'Show',
    rank: 'Rank',
    kills: 'Kills',
    damageDealt: 'Damage Dealt',
    damageTaken: 'Damage Taken',
    zoneDirection: 'Zone Direction',
    phases: 'Phases',
    walking: 'Walking',
    driving: 'Driving',
    endingZoneArea: 'ENDING CIRCLE AREA',
    selectZoneArea: 'Select zone area',
    selectTeam: 'Add at least one team',
    zone: 'Zone',
    reset: 'Reset',
    resetWarning: 'This action will remove all rotations that have been added and reset the selected zone?',
    teams: 'Teams',
    add: 'Add',
    noTeams: 'No teams selected',
  },
  saved: {
    noPremium: 'This feature is for premium users only. You are still able to access your old saved matches but you cannot save new ones.',
    savedMatches: 'Saved Matches',
    rename: 'Rename',
    delete: 'Delete',
    renameFile: 'Rename File',
    newFileName: 'New File Name',
    fileDelete: 'File Delete',
    fileDeleteWarning: 'Are you sure you want to delete this file?',
    matchAnalysis: 'Match Analysis',
    modalRenameTitle: 'Match Rename',
    modalRenamePlaceholder: 'New Match Name',
    modalDeleteTitle: 'Match Delete',
    modalDeletePlaceholder: 'Are you sure you want to delete this match?',
  },
  connect: {
    title: 'Keep up with us!',
    discordConnectText: 'Let\'s connect through Discord!',
    connectMe: 'Connect Me!',
  },
  features: {
    title: 'What\'s in it for you?',
    feature1Title: 'Tournament Leaderboards',
    feature1Text: 'Keep up with tournaments from all around the world',
    feature2Title: 'Players\' Statistics',
    feature2Text: 'Compare players and their skill in different categories',
    feature3Title: 'Match Analyzer',
    feature3Text: 'Analyze your matches and power-up your remote work collaboratively',
    feature4Title: 'Rotation Analyzer',
    feature4Text: 'Know your opponents rotation and prepare your attack strategy',
  },
  games: {
    title: 'Which games do you play?',
    availableNow: 'available now',
    comingSoon: 'coming soon',
  },
  partners: {
    title: 'Partners',
  },
  pricing: {
    title: 'Pricing',
    advertisement: 'Advertisement',
    matchAnalyzer: 'Match Analyzer',
    rotationAnalyzer: 'Rotation Analyzer',
    drawing: 'Drawing',
    collaborativeDrawing: 'Collaborative Drawing',
    savingMatches: 'Saving Matches',
    premium: 'Premium',
    freePeriod: '14 Days Trial',
    paidMonthly: 'Paid monthly',
    paidQuarterly: 'Paid quarterly',
    noCardRequired: 'No card required',
    planContact1: 'If you or your team needs a custom plan contact us on our',
    planContact2: 'or via',
    planContact3: '',
    free: 'Free',
  },
  payment: {
    title: 'Pricing',
    saved: 'Saved',
    ads: 'Remove ads',
    analyzerMapReplay: 'Analyze map replays',
    seeTeamsRotations: 'See teams\' rotations',
    workCollaboratively: 'Work collaboratively',
    shareDrawingInRealTime: 'Share drawing in real time',
    drawing: 'Drawing',
    markImportantMoments: 'Mark important moments',
    saveitForLater: 'Save it for later',
    recommended: 'Recommended',
    billedQuarterly: 'Billed quarterly',
    billedMonthly: 'Billed monthly',
    selectPlan: 'Select Plan',
    billedNow: 'Billed now',
    alreadyPremium: 'You are already a premium member',
    expiresInDays: 'Free trial expires in 14 days',
    learnMore: 'Learn more',
  },
  welcome: {
    title1: 'Kickstart your',
    title2: 'ESPORTS CAREER',
    subtitle: 'Analytics tools for winners',
    register: 'Register for free',
    learnMore: 'I want to learn more',
  },
  forgotPassword: {
    emailSent: 'Email Sent!',
    emailSentMessage: 'Email with reset password link was sent to you.',
    forgotPassword: 'Forgot Password',
    inputUsername: 'Input your username',
    sentResetLink: 'Send Reset Link',
  },
  login: {
    signUp: 'Sign up',
    dontHaveAccountLong: 'Don\'t have an account yet? What are you waiting for! Register and start improving your skills with our awesome tools!',
    dontHaveAccount: 'Don\'t have an account yet?',
    registerNow: 'Register Now!',
    login: 'Login',
    resetPassword: 'Reset password',
    reset: 'Reset',
    signInAccount: 'Sign In to your account',
    passwordResetNeeded: 'You need to reset your password',
    username: 'Username',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    forgotPassword: 'Forgot Password?',
    resetPasswordRequired: 'You need to reset your password',
    wrongCredentials: 'Invalid username or password',
    passwordsDontMatch: 'Passwords do not match',
    passwordToShort: 'Passwords must include minimum 8 characters, at least one letter and one number',
  },
  register: {
    register: 'Register',
    thankYou: 'Thank you!',
    thankYouLong: 'Thank you for creating account! Check your email to finish registration.',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    subscribeToNewsletter: 'Subscribe to our newsletter',
    readAndUnderstand: 'I have read and understand',
    termsOfUse: 'Terms of use',
    createAccount: 'Create Account',
    alreadyRegistered: 'Already Registered?',
    invalidUsername: 'Invalid username',
    invalidEmail: 'Invalid email',
    invalidPassword: 'Passwords must include minimum 8 characters, at least one letter and one number',
    passwordsDontMatch: 'Passwords do not match',
    termsRequired: 'You must agree to Terms of use',
  },
  resetPassword: {
    resetPassword: 'Reset Password',
    selectNewPassword: 'Select new password',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: 'Verification failed! Your link is broken, expired or already been verified.',
    resendEmail: 'Resend email',
    verifyingEmail: 'Verifying your email...',
    verificationSuccess: 'Verification completed! You may login now!',
  }
}