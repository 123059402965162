import { 
  REQUEST_SUBSCRIPTION_PLANS_PENDING,
  REQUEST_SUBSCRIPTION_PLANS_SUCCESS,
  REQUEST_SUBSCRIPTION_PLANS_ERROR,
} from './types';

const INITIAL_STATE = {
  subscriptionPlansLoaded: false,
  subscriptionPlansError: false,
  subscriptionPlans: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On subscription plans loading
    case REQUEST_SUBSCRIPTION_PLANS_PENDING:
      return {
        ...state,
        subscriptionPlansLoaded: false,
        subscriptionPlansError: false,
        subscriptionPlans: [],
      };
      
    // On subscription plans success
    case REQUEST_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        subscriptionPlansLoaded: true,
        subscriptionPlansError: false,
        subscriptionPlans: action.payload,
      };

    // On subscription plans error
    case REQUEST_SUBSCRIPTION_PLANS_ERROR:
      return {
        ...state,
        subscriptionPlansLoaded: true,
        subscriptionPlansError: true,
        subscriptionPlans: [],
      };

    default:
      return state;
  }
}

export default reducer;