import { 
  REQUEST_TOURNAMENTS,
} from './types';

/**
 * Action form getting tournaments from server
 * 
 * @param {*} region region for which tournaments should be fetched
 */
export const requestTournaments = (type) => ({
  type: REQUEST_TOURNAMENTS,
  payload: type,
});