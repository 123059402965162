// Action for saving new file
export const REQUEST_FILE_SAVE_NEW = 'REQUEST_FILE_SAVE_NEW';
// Action for saving new file
export const REQUEST_FILE_SAVE_TEMP = 'REQUEST_FILE_SAVE_TEMP';
export const REQUEST_FILE_SAVE_TEMP_SUCCESS = 'REQUEST_FILE_SAVE_TEMP_SUCCESS';
// Action for making file public
export const REQUEST_MAKE_FILE_PUBLIC = 'REQUEST_MAKE_FILE_PUBLIC';
// Action for saving highlights to server
export const REQUEST_FILE_HIGHLIGHTS_SAVE = 'REQUEST_FILE_HIGHLIGHTS_SAVE';
// Action for loading highlights from server
export const REQUEST_FILE_HIGHLIGHTS_LOADED = 'REQUEST_FILE_HIGHLIGHTS_LOADED';
// Action for clearing state
export const FILE_HIGHLIGHTS_CLEAR_STATE = 'FILE_HIGHLIGHTS_CLEAR_STATE';
// Action setting file state
export const SET_FILE_STATE = 'SET_FILE_STATE';
// Action setting file state
export const REQUEST_SUBSCRIPTION_TO_FILE = 'REQUEST_SUBSCRIPTION_TO_FILE';