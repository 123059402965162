// Types of action for getting PUBG username
export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const REQUEST_SETTINGS_PENDING = 'REQUEST_SETTINGS_PENDING';
export const REQUEST_SETTINGS_SUCCESS = 'REQUEST_SETTINGS_SUCCESS';
export const REQUEST_SETTINGS_ERROR = 'REQUEST_SETTINGS_ERROR';
// Types of action for saving PUBG username
export const REQUEST_SAVE_PUBG_USERNAME = 'REQUEST_SAVE_PUBG_USERNAME';
export const REQUEST_SAVE_PUBG_USERNAME_PENDING = 'REQUEST_SAVE_PUBG_USERNAME_PENDING';
export const REQUEST_SAVE_PUBG_USERNAME_SUCCESS = 'REQUEST_SAVE_PUBG_USERNAME_SUCCESS';
export const REQUEST_SAVE_PUBG_USERNAME_ERROR = 'REQUEST_SAVE_PUBG_USERNAME_ERROR';
// Types of action for saving notifications settings
export const REQUEST_SAVE_NOTIFICATIONS = 'REQUEST_SAVE_NOTIFICATIONS';
export const REQUEST_SAVE_NOTIFICATIONS_PENDING = 'REQUEST_SAVE_NOTIFICATIONS_PENDING';
export const REQUEST_SAVE_NOTIFICATIONS_SUCCESS = 'REQUEST_SAVE_NOTIFICATIONS_SUCCESS';
export const REQUEST_SAVE_NOTIFICATIONS_ERROR = 'REQUEST_SAVE_NOTIFICATIONS_ERROR';
// Types of action for using promo code
export const REQUEST_PROMO_CODE_USE = 'REQUEST_PROMO_CODE_USE';
export const REQUEST_PROMO_CODE_USE_PENDING = 'REQUEST_PROMO_CODE_USE_PENDING';
export const REQUEST_PROMO_CODE_USE_SUCCESS = 'REQUEST_PROMO_CODE_USE_SUCCESS';
export const REQUEST_PROMO_CODE_USE_ERROR = 'REQUEST_PROMO_CODE_USE_ERROR';