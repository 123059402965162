import { 
  REQUEST_TOURNAMENT_MATCHES_PENDING,
  REQUEST_TOURNAMENT_MATCHES_SUCCESS,
  REQUEST_TOURNAMENT_MATCHES_ERROR,
  REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING,
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_SUCCESS,
  CLEAR_MATCHES,
  CLEAR_TOURNAMENT_STATE,
} from './types';
import { getNoMatchesMessage } from '../../util/MessageUtils';

const INITIAL_STATE = {
  tournament: null,
  tournamentLoaded: false,
  tournamentError: false,
  matchPage: 1,
  matches: [],
  filters: [],
  prizePools: [],
  matchesLoaded: true,
  matchesLoadedAdditional: true,
  matchesError: false,
  noMatchesMessage: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // on tournament loading
    case REQUEST_TOURNAMENT_PENDING:
      return {
        ...state,
        tournament: null,
        tournamentLoaded: false,
        tournamentError: false,
      }

    // on tournament load error
    case REQUEST_TOURNAMENT_ERROR:
      return {
        ...state,
        tournament: null,
        tournamentLoaded: false,
        tournamentError: true,
      }

    // on tournament load success
    case REQUEST_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournament: action.payload.tournament,
        filters: action.payload.tournamentFilters,
        prizePools: action.payload.prizePools,
        tournamentLoaded: true,
        tournamentError: false,
      }

    // On matches loading
    case REQUEST_TOURNAMENT_MATCHES_PENDING:
      return {
        ...state,
        matchesLoaded: false,
        matchesError: false,
        noMatchesMessage: null,
      }

    // On matches loading
    case REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING:
      return {
        ...state,
        matchesLoadedAdditional: false,
      }

    // On successfully loaded matches
    case REQUEST_TOURNAMENT_MATCHES_SUCCESS:
      return {
        ...state,
        matchesLoaded: true,
        matchesLoadedAdditional: true,
        matchesError: false,
        matches: action.payload.page > 1 ? [...state.matches, ...action.payload.matches] : action.payload.matches,
        matchPage: action.payload.page,
        noMatchesMessage: getNoMatchesMessage(action),
      }

    // On matches fetch error
    case REQUEST_TOURNAMENT_MATCHES_ERROR:
      return {
        ...state,
        matchesLoaded: true,
        matchesLoadedAdditional: true,
        matchesError: true
      }

    case CLEAR_MATCHES:
      return {
        ...state,
        matchPage: 1,
        matches: [],
        matchesLoaded: false,
        matchesLoadedAdditional: true,
        matchesError: false,
        noMatchesMessage: null,
      }

    case CLEAR_TOURNAMENT_STATE:
      return {
        ...state,
        tournament: null,
        tournamentLoaded: false,
        tournamentError: false,
        matchPage: 1,
        matches: [],
        matchesLoaded: false,
        matchesLoadedAdditional: false,
        matchesError: false,
        noMatchesMessage: null,
      }

    default:
      return state;
  }
}

export default reducer;