import { 
  REQUEST_TRACK,
  FIX_SESSION,
} from './types';

/**
 * Action for tracking user
 * 
 * @param {*} event
 */
export const requestTrack = (event) => ({
  type: REQUEST_TRACK,
  payload: event,
});

/**
 * Action for fixing session
 */
export const fixSession = () => ({
  type: FIX_SESSION,
});