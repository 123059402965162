// Types of action for authenticated user
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const UNSET_AUTH_USER = 'UNSET_AUTH_USER';
// Get user subscription action
export const REQUEST_USER_SUBSCRIPTION = 'REQUEST_USER_SUBSCRIPTION';
export const REQUEST_USER_SUBSCRIPTION_PENDING = 'REQUEST_USER_SUBSCRIPTION_PENDING';
export const REQUEST_USER_SUBSCRIPTION_SUCCESS = 'REQUEST_USER_SUBSCRIPTION_SUCCESS';
export const REQUEST_USER_SUBSCRIPTION_ERROR = 'REQUEST_USER_SUBSCRIPTION_ERROR';
// Action for getting logged in user
export const REQUEST_LOGGED_IN_USER = 'REQUEST_LOGGED_IN_USER';
// Action for setting selected game
export const SET_SELECTED_GAME = 'SET_SELECTED_GAME';
export const SET_SELECTED_GAME_SUCCESS = 'SET_SELECTED_GAME_SUCCESS';