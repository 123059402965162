import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { privateQuery } from '../../graphql/requestHelper';
import {  
  REQUEST_USER_SUBSCRIPTION, 
  REQUEST_USER_SUBSCRIPTION_SUCCESS, 
  REQUEST_USER_SUBSCRIPTION_PENDING, 
  REQUEST_USER_SUBSCRIPTION_ERROR,
  REQUEST_LOGGED_IN_USER,
  SET_SELECTED_GAME,
  SET_SELECTED_GAME_SUCCESS,
} from './types';
import { ERROR_LOADING_DATA_REFRESH } from '../../util/ErrorMessages';
import { userSubscription } from '../../graphql/private/queries';
import { setAuthUser, unsetAuthUser } from './actions';
import { requestTournaments } from '../tournamentSearch/actions';
import Cookies from 'js-cookie';
import { PREMIUM_COOKIE } from '../../util/Constants';

/**
 * Displays success notification
 */
function* requestUserSubscription(action) {
  try {
    yield put({ type: REQUEST_USER_SUBSCRIPTION_PENDING });
    // Calling the server
    const result = yield privateQuery(userSubscription);
    if (result.data.userSubscription.status) {
      Cookies.set(PREMIUM_COOKIE, "true");
      unloadAdScript();
    } else {
      Cookies.remove(PREMIUM_COOKIE);
      loadAdScript();
    }
    yield put({ type: REQUEST_USER_SUBSCRIPTION_SUCCESS, payload: result.data.userSubscription });
  } catch (err) {
    Cookies.remove(PREMIUM_COOKIE);
    yield call(toast.error, ERROR_LOADING_DATA_REFRESH);
    yield put({ type: REQUEST_USER_SUBSCRIPTION_ERROR });
  }
}

/**
 * Gets logged in user if exists
 * 
 * @param {*} action 
 */
function* requestLoggedInUser(action) {
  try {
    // Calling the server
    const user = yield call([Auth, 'currentAuthenticatedUser']);
    // Triggering success action
    yield put(setAuthUser(user));
    yield put({ type: REQUEST_USER_SUBSCRIPTION });
  } catch (err) {
    loadAdScript();
    yield put(unsetAuthUser());
    Cookies.remove(PREMIUM_COOKIE);
  }
}

/**
 * Gets logged in user if exists
 * 
 * @param {*} action 
 */
function* setSelectedGame(action) {
  yield put({ type: SET_SELECTED_GAME_SUCCESS, payload: action.payload });
  yield put(requestTournaments('tournament'));
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_USER_SUBSCRIPTION, requestUserSubscription),
    takeLatest(REQUEST_LOGGED_IN_USER, requestLoggedInUser),
    takeLatest(SET_SELECTED_GAME, setSelectedGame),
  ]);
}

/**
 * Loads Ad script
 */
export const loadAdScript = () => {
  const displayAds = true;
  const script = document.getElementById("ad-script");
  if (script) {
    return;
  }
  if (displayAds && document.location.hostname === "analytics.twire.gg") {
    const script = document.createElement("script");
    script.src = "https://adncdnend.azureedge.net/adtags/twire.adn.js";
    script.id = "ad-script";
    document.getElementsByTagName("head")[0].append(script);

    const videoScript = document.createElement("script");
    videoScript.src =
      "https://adncdnend.azureedge.net/adn-video/Twire_ADN_Player.js";
    videoScript.id = "adnimationjs";
    videoScript.setAttribute("async", "");
    videoScript.setAttribute("data-info", "Twire_Player");
    videoScript.setAttribute("data-info-mobile", "Twire_Player");
    document.getElementsByTagName("head")[0].append(videoScript);
  }
};

/**
 * Unloads Ad script
 */
export const unloadAdScript = () => {
  const script = document.getElementById("ad-script");
  if (script) {
    script.remove();
  }
  const videoScript = document.getElementById("AV63d8dd51bfe299666e04c6b5");
  if (videoScript) {
    videoScript.remove();
  }
};