import { all, takeLatest, select } from 'redux-saga/effects';
import {  
  REQUEST_ACTION,
  REQUEST_ACTION_RECEIVE,
} from './types';
import { privateMutation, privateSubscription } from '../../graphql/requestHelper';
import { collaboration } from '../../graphql/private/mutations';
import { collaborationSubscribe } from '../../graphql/private/subscriptions';

/**
 * Sends actions to stream map
 */
function* requestAction(action) {
  // Calling the server
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    yield privateMutation(collaboration, {
      game: selectedGame,
      fileId: action.payload.fileId,
      collaborationInput: JSON.stringify(action.payload.params),
    });
  } catch (err) {
  }
}

/**
 * Subscribes stream map to actions
 */
function* requestActionReceive(action) {
  // Calling the server
  try {
    yield privateSubscription(collaborationSubscribe, { fileId: action.payload.fileId }, action.payload.next);
  } catch (err) {
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_ACTION, requestAction),
      takeLatest(REQUEST_ACTION_RECEIVE, requestActionReceive),
  ]);
}