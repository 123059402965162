// eslint-disable
// this is an auto generated file. This will be overwritten

export const subscribeToFileById = `subscription SubscribeToFileById {
  subscribeToFileById {
    fileId
    isPublic
    filename
    highlights {
      time
      comment
      drawings
      sub
      username
    }
  }
}
`;
export const collaborationSubscribe = `subscription CollaborationSubscribe {
  collaborationSubscribe {
    fileId
    settings
  }
}
`;
