// Match List messages
export const MATCH_LIST_NO_MORE_MATCHES = 'No more matches';
export const MATCH_LIST_NO_MATCHES = 'No matches found';

// Verification
export const VERIFICATION_RESEND_SUCCESS = 'Verification email sent!';

// Forgot password
export const FORGOT_PASSWORD_SUCCESS = 'Email sent successfully!';

// Reset password
export const RESET_PASSWORD_SUCCESS = 'Password was successfully reset!';

// Reset password
export const CHANGE_PASSWORD_SUCCESS = 'Password changed!';

// Settings
export const SETTINGS_SAVE_SUCCESS = 'Changes saved!';
export const SETTINGS_PUBG_USERNAME_SAVE_SUCCESS = 'Username saved! We will notify you about username confirmation by email.';
export const SETTINGS_PUBG_USERNAME_DELETE_SUCCESS = 'Username deleted!';

// File save messages
export const FILE_SAVE_MODAL_TITLE = 'Save new match';
export const FILE_SAVE_MODAL_PLACEHOLDER = 'Match name';
export const FILE_SAVE_SUCCESS = 'Match saved succesfully!';
export const FILE_CHANGES_SAVE_SUCCESS = 'Changes saved succesfully!';
export const FILE_SHARE_MODAL_TITLE = 'Share analysis';
export const FILE_SHARE_MODAL_PLACEHOLDER = 'Who would you like to share analysis with?';

// Payment
export const PAYMENT_SUCCESSFULLY_SAVED = 'Payment successful! It might take a couple of minutes for us to verify it.';

// Comment
export const ADD_COMMENT_SUCCESS = 'Comment successfully added.';