import { 
  TOGGLE_ASIDE_SUCCESS,
  CLOSE_ASIDE_SUCCESS,
  OPEN_ASIDE_SUCCESS,
} from './types';

const INITIAL_STATE = {
  asideOpen: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On toggle aside
    case TOGGLE_ASIDE_SUCCESS:
      return {
        ...state,
        asideOpen: action.payload,
      };

    // On close aside
    case CLOSE_ASIDE_SUCCESS:
      return {
        ...state,
        asideOpen: false,
      };

    // On open aside
    case OPEN_ASIDE_SUCCESS:
      return {
        ...state,
        asideOpen: true,
      };
      
    default:
      return state;
  }
}

export default reducer;