import { 
  INITIALIZE_ROTATION_ANALYZER_V2,
  SET_ROTATION_ANALYZER_V2_ZONE_AREA,
  RESET_ROTATION_ANALYZER_V2_ZONE_AREA,
  SKIP_ROTATION_ANALYZER_V2_ZONE_AREA,
  REQUEST_TEAM_ROTATIONS_PENDING,
  REQUEST_TEAM_ROTATIONS_SUCCESS,
  REQUEST_TEAM_ROTATIONS_ERROR,
  SET_ROTATION_ANALYSER_V2_STATE,
  TOGGLE_ROTATION_V2,
  SET_ROTATOIN_ANALYZER_V2_SELECTED_PHASES,
  SHOW_ROTATION_V2,
  HIDE_ALL_ROTATIONS_V2,
  ROTATION_ANALYZER_V_2_ADD_TEAM,
  ROTATION_ANALYZER_V_2_REMOVE_TEAM,
  ROTATION_ANALYZER_V_2_CLEAR_STATE,
} from './types';

const INITIAL_STATE = {
  rotationLoaded: true,
  rotationError: false,
  selectedTeams: [],
  allRotations: [],
  shownRotations: {},
  shownRotationPhases: {},
  selectedMap: null,
  zoneArea: null,
  skipZoneArea: false,
  renderLandingSpots: true,
  renderRotations: true,
  renderCircles: true,
  renderPlanePaths: true,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Set map
    case INITIALIZE_ROTATION_ANALYZER_V2:
      return {
        ...state,
        selectedMap: action.payload,
      };

    // Set zone area
    case SET_ROTATION_ANALYZER_V2_ZONE_AREA:
      return {
        ...state,
        zoneArea: action.payload,
        skipZoneArea: false,
      };

    // Reset zone area
    case RESET_ROTATION_ANALYZER_V2_ZONE_AREA:
      return {
        ...state,
        zoneArea: null,
        skipZoneArea: false,
        allRotations: [],
        selectedTeams: [],
      };

    // Skip zone area
    case SKIP_ROTATION_ANALYZER_V2_ZONE_AREA:
      return {
        ...state,
        zoneArea: null,
        skipZoneArea: true,
      };

    // Adds new team to selected teams
    case ROTATION_ANALYZER_V_2_ADD_TEAM:
      return {
        ...state,
        selectedTeams: [...state.selectedTeams, action.payload],
      }

    // Removes a team from selected teams
    case ROTATION_ANALYZER_V_2_REMOVE_TEAM:
      const selectedTeams = [...state.selectedTeams];
      selectedTeams.splice(action.payload.index, 1);
      const shownRotation = {...state.shownRotations};
      const allRotations = state.allRotations.filter(r => {
        if (r.team.id === action.payload.id) {
          delete shownRotation[r.id];
        }
        return r.team.id !== action.payload.team.id;
      });
      return {
        ...state,
        selectedTeams: selectedTeams,
        allRotations: allRotations,
      }

    // On rotation loading
    case REQUEST_TEAM_ROTATIONS_PENDING:
      return {
        ...state,
        rotationLoaded: false,
        rotationError: false
      };
      
    // On successfully loaded rotation
    case REQUEST_TEAM_ROTATIONS_SUCCESS:
      return {
        ...state,
        rotationLoaded: true,
        rotationError: false,
        allRotations: [...state.allRotations, ...action.payload.allRotations],
        shownRotationPhases: {...state.shownRotationPhases, ...action.payload.shownRotationPhases},
      };

    // On rotation fetch error
    case REQUEST_TEAM_ROTATIONS_ERROR:
      return {
        ...state,
        rotationLoaded: true,
        rotationError: true
      };

    // Set rotation analysis state
    case SET_ROTATION_ANALYSER_V2_STATE:
      return {
        ...state,
        ...action.payload
      };

    // Set show rotation phases
    case SET_ROTATOIN_ANALYZER_V2_SELECTED_PHASES:
      const shownRotationPhases = {...state.shownRotationPhases};
      shownRotationPhases[action.payload.matchId] = {
        from: action.payload.range[0],
        to: action.payload.range[1],
      }
      return {
        ...state,
        shownRotationPhases: shownRotationPhases
      };

    // Toggle rotation
    case TOGGLE_ROTATION_V2:
      const shownRotations = {...state.shownRotations};
      shownRotations[action.payload] = !shownRotations[action.payload];
      return {
        ...state,
        shownRotations: shownRotations,
      };

    // Show rotation
    case SHOW_ROTATION_V2:
      const rotations = {...state.shownRotations};
      rotations[action.payload] = true;
      return {
        ...state,
        shownRotations: rotations,
      };

    // Hide all rotation
    case HIDE_ALL_ROTATIONS_V2:
      const hiddenRotations = {...state.shownRotations};
      Object.keys(hiddenRotations).forEach(k => hiddenRotations[k] = false);
      return {
        ...state,
        shownRotations: hiddenRotations,
      };

    // Clears rotation analyzer V2 state
    case ROTATION_ANALYZER_V_2_CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default reducer;