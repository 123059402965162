import { clamp } from 'lodash';
import mapSizeDictionary from '../assets/pubg/mapSizeDictionary';
import mapMultiplierDictionary from '../assets/pubg/mapMultiplierDictionary';

const SCALE_DELTA = 1.3;
const SCALE_TOUCH_DELTA = 1.02;
const SCALE_MIN = 1;
const SCALE_MAX = 100;

/**
 * Calculates size of the map (not for fullscreen mode).
 */
const calculateMapSize = () => {
  let mapSize = 0;
  if (window.matchMedia("(orientation: portrait)").matches) {
    mapSize = document.body.clientWidth - 60;
  } else if (window.matchMedia("(orientation: landscape)").matches) {
    mapSize = window.innerHeight * 0.75;
  }
  return Math.min(mapSize, 700);
}

/**
 * Calculates height if the map when fullscreen is on
 */
const calculateFullScreenMapHeight = () => {
  return window.innerHeight - 80;
}

/**
 * Calculates width if the map when fullscreen is on
 */
const calculateFullScreenMapWidth = () => {
  return document.body.clientWidth - 250;
}

/**
 * Calucalted height of the analyzer. Calculation is different
 * if analyzer is in full more. If in full more the full 
 * height is takem minus the size of the controls (timeline).
 */
export const calculateHeight = (isFullScreen) => {
  return isFullScreen ? calculateFullScreenMapHeight() : calculateMapSize();
}

/**
 * Calucalted width of the analyzer. Calculation is different
 * if analyzer is in full more. If in full more the full 
 * width is takem minus the size of the details.
 */
export const calculateWidth = (isFullScreen) => {
  return isFullScreen ? calculateFullScreenMapWidth() : calculateMapSize();
}

/**
 * Calculates new position of the map.
 * 
 * @param {*} isFullScreen true if full screen is on
 * @param {*} positionX position X of the mouse or drag
 * @param {*} positionY position Y of the mouse or drag
 * @param {*} scale current scale or newly calculated scale
 */
export const calcualteNewPosition = (isFullScreen, positionX, positionY, scale) => {
  const height = calculateHeight(isFullScreen);
  const widthOffest = (isFullScreen ? calcualteFullScreenWidthOffset(scale) : 0);
  const minPosition = calculateMinMapPosition(scale, height);
  // We have to use width both times because image is a square
  // and full screen mode analyzer is a rectangle
  return {
    x: isMapSmallerThanCanvasWidth(scale) && isFullScreen ? widthOffest : clamp(positionX, isFullScreen ? widthOffest * 2 : minPosition, 0),
    y: clamp(positionY, minPosition, 0)
  }
}

/**
 * Calculates new position of the map after scale.
 * 
 * @param {*} isFullScreen true if fullscreen is on
 * @param {*} centerX X of mouse position when scaling
 * @param {*} centerY Y of mouse position when scaling
 * @param {*} positionX X of current map position
 * @param {*} positionY Y of curent map position
 * @param {*} oldScale old scale
 * @param {*} newScale new scale
 */
export const calcualteNewPositionAfterScale = (isFullScreen, centerX, centerY, positionX, positionY, oldScale, newScale) => {
  // Calculating mouse position on map
  const mousePosition = {
    x: centerX / oldScale - positionX / oldScale,
    y: centerY / oldScale - positionY / oldScale
  }
  const newPositionX = -(mousePosition.x - centerX / newScale) * newScale;
  const newPositionY = -(mousePosition.y - centerY / newScale) * newScale;

  // Calculating new map position
  // Note that height mustbe calculated differently if analyzer is in full 
  // screen mode since it is not square but rectangle
  return calcualteNewPosition(isFullScreen, newPositionX, newPositionY, newScale);
}

/**
 * Calculates minimum position that map can have on canvas.
 * This number is negative.
 * 
 * @param {*} scale current scale
 * @param {*} size size of analyzer (the bigger side)
 */
const calculateMinMapPosition = (scale, size) => {
  return -((scale - SCALE_MIN) * size / SCALE_MIN);
}

/**
 * Calculates is map is smaller than width. This is used
 * when in full screen mode when map needs to be placed on the center.
 * 
 * @param {*} scale 
 */
const isMapSmallerThanCanvasWidth = (scale) => {
  return calculateFullScreenMapHeight() * scale <= calculateFullScreenMapWidth();
}

/**
 * Calculates how much map needs to be moved to the right
 * when on full screen mode.
 * 
 * @param {*} scale 
 */
const calcualteFullScreenWidthOffset = (scale) => {
  return (calculateFullScreenMapWidth() - (calculateFullScreenMapHeight() * scale)) / 2;
}

/**
 * Calculates new scale and map position according to scroll event
 * 
 * @param {*} scale current scale value
 * @param {*} isZoomOut true if zoom out
 * @param {*} isFullScreen true if fullscreen is on
 */
export const calculateNewScaleScroll = (scale, isZoomOut, isFullScreen) => {
  return calculateNewScale(scale, isZoomOut, SCALE_DELTA, isFullScreen);
}

/**
 * Calculates new scale and map position according to pinch event
 * 
 * @param {*} scale current scale value
 * @param {*} isZoomOut true if zoom out
 */
export const calculateNewScalePinch = (scale, isZoomOut) => {
  return calculateNewScale(scale, isZoomOut, SCALE_TOUCH_DELTA);
}

/**
 * Calculates new scale and map position according to scroll event
 * 
 * @param {*} scale current scale value
 * @param {*} isZoomOut true if zoom out
 * @param {*} delta scale delta
 * @param {*} isFullScreen true if fullscreen is on
 */
const calculateNewScale = (scale, isZoomOut, delta, isFullScreen) => {
  // Calculating new scale
  return clamp(isZoomOut ? scale / delta : scale * delta, SCALE_MIN, SCALE_MAX);
}

/**
 * Converts PUBG distance to pixles so that objects can be
 * displayed on the map
 * 
 * @param {*} distance distance that needs to be converted
 * @param {*} mapName map that is currently being used
 * @param {*} scale current scale of the map
 */
export const distanceToPixels = (distance, mapName, scale, isFullScreen) => {
  return ((distance / mapSizeDictionary[mapName]) * (isFullScreen ?  calculateFullScreenMapHeight() : calculateMapSize()) * scale) * (mapSizeDictionary[mapName] / mapMultiplierDictionary[mapName]);
}

/**
 * Converts pixles to PUBG distance so that objects can be
 * displayed on the map
 * 
 * @param {*} distance distance that needs to be converted
 * @param {*} mapName map that is currently being used
 * @param {*} scale current scale of the map
 */
export const pixelsToDistance = (distance, mapName, scale, isFullScreen) => {
  return (distance * mapSizeDictionary[mapName]) / ((isFullScreen ?  calculateFullScreenMapHeight() : calculateMapSize()) * scale);
}