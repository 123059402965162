// Games ids
export const PUBG_GAME_ID = 1;

// Games constants
export const PUBG_GAME = 'pubg';
export const PUBGC_GAME = 'pubgc';

export const GAME_NAMES = {
  [PUBG_GAME]: 'PUBG PC',
  [PUBGC_GAME]: 'PUBG Console',
}

export const GAMES = [
  PUBG_GAME,
  PUBGC_GAME,
]