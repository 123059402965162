import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { publicQuery } from '../../graphql/requestHelper';
import {
  matchByTournament,
  tournamentInitialData,
} from '../../graphql/public/queries';
import {
  REQUEST_TOURNAMENT,
  REQUEST_TOURNAMENT_PENDING,
  REQUEST_TOURNAMENT_SUCCESS,
  REQUEST_TOURNAMENT_ERROR,
  REQUEST_TOURNAMENT_MATCHES,
  REQUEST_TOURNAMENT_MATCHES_PENDING,
  REQUEST_TOURNAMENT_MATCHES_SUCCESS,
  REQUEST_TOURNAMENT_MATCHES_ERROR,
  REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING,
} from './types';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';
import { getQueryParamFromUrl } from '../../util/CommonUtils';

/**
 * Makes a request to server for tournament matches
 * 
 * @param {*} action 
 */
function* requestTournament(action) {
  yield put({ type: REQUEST_TOURNAMENT_PENDING });
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const tournamentInfo = (yield publicQuery(tournamentInitialData, {
      game: selectedGame,
      id: action.payload,
    })).data.tournamentInitialData;
    // const tournamentPrizePools = (yield publicQuery(getTournamentPrizeInfo, {
    //   game: selectedGame,
    //   shard: tournamentInfo.tournament.shard,
    // })).data.getTournamentPrizeInfo;
    // Triggering success action for tournament matches
    yield put({
      type: REQUEST_TOURNAMENT_MATCHES_SUCCESS,
      payload: {
        matches: tournamentInfo.matches,
        page: 1,
      }
    });
    // Triggering success action for tournament data
    yield put({
      type: REQUEST_TOURNAMENT_SUCCESS,
      payload: { ...tournamentInfo, prizePools: [] },
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_TOURNAMENT_ERROR });
  }
}

/**
 * Makes a request to server for tournament matches
 * 
 * @param {*} action 
 */
function* requestTournamentMatches(action) {
  if (action.payload.page === 1) {
    yield put({ type: REQUEST_TOURNAMENT_MATCHES_PENDING });
  } else {
    yield put({ type: REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING });
  }

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const matches = yield publicQuery(matchByTournament, {
      game: selectedGame,
      shardInfo: action.payload.shardInfo,
      page: action.payload.page,
      token: getQueryParamFromUrl('token'),
    });
    // Triggering success action
    yield put({
      type: REQUEST_TOURNAMENT_MATCHES_SUCCESS,
      payload: {
        matches: matches.data.matchByTournament,
        page: action.payload.page,
      }
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_TOURNAMENT_MATCHES_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(REQUEST_TOURNAMENT, requestTournament),
    takeLatest(REQUEST_TOURNAMENT_MATCHES, requestTournamentMatches),
  ]);
}