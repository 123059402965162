import { 
  REQUEST_VERIFICATION_PENDING,
  REQUEST_VERIFICATION_SUCCESS,
  REQUEST_VERIFICATION_ERROR,
  VERIFICATION_FORM_CLEAN
} from './types';

const INITIAL_STATE = {
  verificationLoaded: false,
  verificationError: false,
  verificationResendLoaded: false,
  verificationResendError: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On verification in progress
    case REQUEST_VERIFICATION_PENDING:
    case VERIFICATION_FORM_CLEAN:
      return {
        ...state,
        verificationLoaded: false,
        verificationError: false
      };
      
    // On successful verification
    case REQUEST_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationLoaded: true,
        verificationError: false
      };

    // On verification error
    case REQUEST_VERIFICATION_ERROR:
      return {
        ...state,
        verificationLoaded: false,
        verificationError: true
      };

    default:
      return state;
  }
}

export default reducer;