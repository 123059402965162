// Types of action for retrieving files from the server
export const REQUEST_FILES = 'REQUEST_FILES';
export const REQUEST_FILES_PENDING = 'REQUEST_FILES_PENDING';
export const REQUEST_FILES_SUCCESS = 'REQUEST_FILES_SUCCESS';
export const REQUEST_FILES_ERROR = 'REQUEST_FILES_ERROR';
// Types of action for renaming file
export const REQUEST_RENAME_FILE = 'REQUEST_RENAME_FILE';
export const REQUEST_RENAME_FILE_SUCCESS = 'REQUEST_RENAME_FILE_SUCCESS';
// Types of action for deteling file
export const REQUEST_DELETE_FILE = 'REQUEST_DELETE_FILE';
export const REQUEST_DELETE_FILE_SUCCESS = 'REQUEST_DELETE_FILE_SUCCESS';