import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_FORGOT_PASSWORD,
  REQUEST_FORGOT_PASSWORD_PENDING,
  REQUEST_FORGOT_PASSWORD_SUCCESS,
  REQUEST_FORGOT_PASSWORD_ERROR
} from './types';
import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from '../notification/types';
import { ERROR_FORGOT_PASSWORD_SEND_FAILED } from '../../util/ErrorMessages';
import { FORGOT_PASSWORD_SUCCESS } from '../../util/Messages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestForgotPassword(action) {
  yield put({ type: REQUEST_FORGOT_PASSWORD_PENDING });

  try {
    // Calling the server
    yield call([Auth, 'forgotPassword'], action.payload);
    // Triggering success action
    yield put({ type: REQUEST_FORGOT_PASSWORD_SUCCESS });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: FORGOT_PASSWORD_SUCCESS });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_FORGOT_PASSWORD_SEND_FAILED });
    yield put({ type: REQUEST_FORGOT_PASSWORD_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_FORGOT_PASSWORD, requestForgotPassword)
  ]);
}