import { 
  REQUEST_FILES_PENDING,
  REQUEST_FILES_SUCCESS,
  REQUEST_FILES_ERROR,
  REQUEST_RENAME_FILE_SUCCESS,
  REQUEST_DELETE_FILE_SUCCESS
} from './types';

const INITIAL_STATE = {
  files: [],
  filesLoaded: false,
  filesError: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On files loading
    case REQUEST_FILES_PENDING:
      return {
        ...state,
        filesLoaded: false,
        filesError: false
      };
      
    // On successfully loaded files
    case REQUEST_FILES_SUCCESS:
      return {
        ...state,
        filesLoaded: true,
        filesError: false,
        files: action.payload
      };

    // On files fetch error
    case REQUEST_FILES_ERROR:
      return {
        ...state,
        filesLoaded: true,
        filesError: true
      };

    // On file rename success
    case REQUEST_RENAME_FILE_SUCCESS:
      // TODO: figure out a way to trigger re-render without copying array
      const renameFiles = [...state.files];
      const renameFileIndex = renameFiles.indexOf(action.payload.file);
      renameFiles[renameFileIndex].filename = action.payload.filename;
      return {
        ...state,
        files: renameFiles
      };

    // On file delete success
    case REQUEST_DELETE_FILE_SUCCESS:
      // TODO: figure out a way to trigger re-render without copying array
      const deleteFiles = [...state.files];
      const deleteFileIndex = deleteFiles.indexOf(action.payload);
      deleteFiles.splice(deleteFileIndex, 1);
      return {
        ...state,
        files: deleteFiles
      };

    default:
      return state;
  }
}

export default reducer;