import { all, takeLatest, put } from 'redux-saga/effects';
import {  
  TOGGLE_ASIDE,
  TOGGLE_ASIDE_SUCCESS,
  CLOSE_ASIDE,
  CLOSE_ASIDE_SUCCESS,
  OPEN_ASIDE,
  OPEN_ASIDE_SUCCESS,
} from './types';

/**
 * Opens or closes aside
 */
function* toggleAside() {
  document.body.classList.toggle('aside-menu-lg-show'); 
  document.body.classList.toggle('aside-menu-show'); 
  yield put({ type: TOGGLE_ASIDE_SUCCESS, payload: document.body.classList.contains('aside-menu-show') });
}

/**
 * Closes aside
 */
function* closeAside() {
  document.body.classList.remove('aside-menu-lg-show'); 
  document.body.classList.remove('aside-menu-show'); 
  yield put({ type: CLOSE_ASIDE_SUCCESS, payload: document.body.classList.contains('aside-menu-show') });
}

/**
 * Opens aside
 */
function* openAside() {
  document.body.classList.add('aside-menu-lg-show'); 
  document.body.classList.add('aside-menu-show'); 
  yield put({ type: OPEN_ASIDE_SUCCESS, payload: document.body.classList.contains('aside-menu-show') });
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(TOGGLE_ASIDE, toggleAside),
      takeLatest(CLOSE_ASIDE, closeAside),
      takeLatest(OPEN_ASIDE, openAside),
  ]);
}