import Cookies from 'js-cookie';
import { 
  SET_MATCH_ANALYZER_STATE,
  SET_NEW_MATCH_ANALYZER_STATE,
  SET_MATCH_ANALYZER_MATCH,
  MATCH_ANALYZER_MATCH_CLEAR_STATE,
} from './types';

const COOKIE_SETTINGS_PREFIX = 'matchAnalyzer-';
const COOKIE_SETTINGS = [
  'showPlayerNames',
  'showTeamNames',
  'showTeamLogos',
  'showDeadPlayers',
  'showPlanePath',
];

const INITIAL_STATE = {
  matchId: null,
  play: () => {},
  pause: () => {},
  jumpTo: () => {},
  jumpBackward: () => {},
  jumpForward: () => {},
  changePlayingSpeed: () => {},
  isPlaying: false,
  playingSpeed: 10,
  timeFromStart: 0,
  lastStepTime: null,
  duration: 0,
  gameCreatedAt: 0,
  map: null,
  name: null,
  currentIndex: 0,
  currentState: null,
  states: [],
  teams: {},
  teamRankings: [],
  players: {},
  playerAdditionalInfo: {},
  zones: [],
  attacks: {},
  planePath: {},
  attackStates: {},
  showPlayerNames: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'showPlayerNames'), false),
  showTeamPlayerNames: {},
  showTeamNames: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'showTeamNames'), false),
  showTeamLogos: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'showTeamLogos'), false),
  showTeamTrace: {},
  showDeadPlayers: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'showDeadPlayers'), false),
  showPlanePath: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'showPlanePath'), true),
  zoneSizes: [],
  showSingularNameHover: [],
  showSingularNameClick: [],
  teamNames: [],
  highlightedTeam: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On new state
    case SET_NEW_MATCH_ANALYZER_STATE:
      const currentIndex = Math.floor(action.payload.timeFromStart / 100);
      const currentState = state.states[currentIndex];
      return {
        ...state,
        timeFromStart: action.payload.timeFromStart,
        lastStepTime: action.payload.lastStepTime,
        currentIndex: currentIndex,
        currentState: currentState,
      }

    // On new state
    case SET_MATCH_ANALYZER_STATE:
      updateCookieSettings(state, action.payload);
      return {
        ...state,
        ...action.payload
      };

    // On loaded and parsed telemetry
    case SET_MATCH_ANALYZER_MATCH:
      return {
        ...state,
        timeFromStart: 0,
        lastStepTime: null,
        matchId: action.payload.matchId,
        currentIndex: 0,
        currentState: action.payload.telemetry.states[0],
        states: action.payload.telemetry.states,
        teams: action.payload.telemetry.teams,
        teamRankings: action.payload.telemetry.teamRankings,
        players: action.payload.telemetry.players,
        playerAdditionalInfo: action.payload.telemetry.playerAdditionalInfo,
        zones: action.payload.telemetry.zones,
        attacks: action.payload.telemetry.attacks,
        planePath: action.payload.telemetry.planePath,
        duration: action.payload.duration,
        gameCreatedAt: action.payload.gameCreatedAt,
        map: action.payload.map,
        name: action.payload.name,
        zoneSizes: action.payload.zoneSizes,
        teamNames: action.payload.teamNames,
      }

    // On clear state
    case MATCH_ANALYZER_MATCH_CLEAR_STATE:
      return {
        ...INITIAL_STATE,
        showTeamTrace: {},
      };
      
    default:
      return state;
  }
}

function updateCookieSettings(oldState, state) {
  COOKIE_SETTINGS.forEach(s => {
    if (oldState && oldState[s] !== state[s] && state[s] !== undefined) {
      Cookies.set(COOKIE_SETTINGS_PREFIX + s, state[s]);
    }
  });
}

function parseCookieSetting(settingValue, defaultValue) {
  if (settingValue === 'undefined' || settingValue === '' || settingValue === undefined) {
    return defaultValue;
  }
  return settingValue === 'true';
}

export default reducer;
