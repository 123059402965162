import { 
  REQUEST_MATCH_PENDING,
  REQUEST_MATCH_SUCCESS,
  REQUEST_MATCH_ERROR
} from './types';

const INITIAL_STATE = {
  matchLoaded: false,
  matchError: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On match loading
    case REQUEST_MATCH_PENDING:
      return {
        ...state,
        matchLoaded: false,
        matchError: false
      };
      
    // On successfully loaded match
    case REQUEST_MATCH_SUCCESS:
      return {
        ...state,
        matchLoaded: true,
        matchError: false
      };

    // On match fetch error
    case REQUEST_MATCH_ERROR:
      return {
        ...state,
        matchLoaded: true,
        matchError: true
      };

    default:
      return state;
  }
}

export default reducer;