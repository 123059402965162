export default {
  menu: {
    matchAnalyzer: 'Analizador de partidas',
    tournaments: 'Torneos',
    news: 'Noticias',
    pgcPoints: 'PGC Puntos',
    rankings: 'Rankings', // TODO missing
    scrims: 'Scrims',
    userMatches: 'Partidas del usuario',
    rotationAnalyzer: 'Analizador de rotaciones',
    teamsRotations: 'Rotaciones',
    saved: 'Guardado',
    savedMatches: 'Partidas guardadas',
    productionOverlays: 'Producción de overlays',
    reportProblem: 'Reportar un problema',
    discord: 'Unete a nuestro Discord',
    twitter: 'Síguenos en Twitter',
  },
  headerMenu: {
    hi: 'Hola',
    account: 'Cuenta',
    settings: 'Ajustes',
    contact: 'Contáctanos',
    logout: 'Cerrar sesión',
    logoutError: 'Error al cerrar sesión. Por favor intentelo de nuevo!',
    goPremium: 'Hazte Premium',
  },
  common: {
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Borrar',
    cancel: 'Cancelar',
    search: 'Buscar',
    ok: 'OK',
    showMore: 'Mostrar más',
    close: 'cerrar',
    loading: 'Cargando...',
    learnMore: 'Aprende más',
    beta: 'Beta',
    warning: 'Advertencia',
    goTo: 'Ir',
    goToApp: 'Ir a la aplicación',
    login: 'Iniciar sesión',
    pleaseWait: 'Por favor esperé...',
    success: 'Exito!',
    signUp: 'Inscribete',
  },
  settings: {
    basicSettings: 'Ajustes básicos',
    username: 'Nombre de usuario',
    email: 'Correo electrónico',
    subscription: 'Suscripción',
    plan: 'Plan',
    validTo: 'Válido para',
    freePlan: 'Gratis',
    trialPlan: 'Prueba',
    premiumPlan: 'Premium',
    manageSubscription: 'Puede administrar el plan de su suscripción en su cuenta de PayPal.',
    pubgUsernames: 'Nombre de usuario de PUBG (SOLO PUBG PC)',
    pubgUsername: 'Nombre de usuario',
    enterPubgUsername: 'Por favor ingrese su nombre de usuario de PUBG (distingue mayúsculas y minúsculas)',
    status: 'Estado',
    pendingConfirmation: 'Confirmación pendiente',
    invlid: 'Invalido',
    active: 'Activo',
    password: 'Contraseña',
    notifications: 'Notificaciones',
    emailNotifications: 'Notificaciónes de correo electrónico',
    currentPassword: 'Contraseña actual',
    enterCurrentPassword: 'Por favor ingrese su contraseña actual',
    newPassword: 'Contraseña nueva',
    enterNewPassword: 'Por favor ingrese su contraseña nueva',
    passwordConfirmation: 'Confirmación de contraseña',
    enterPasswordConfirmation: 'Por favor re-ingrese la nueva contraseña',
    changePassword: 'Cambiar contraseña',
    promoCode: 'Codigo promocional',
    use: 'usar',
    wrongPromoCode: 'Codigo promocional incorrecto',
    promoCodeSuccess: 'Códigos promocional usado exitosamente. La pagina se actualizara',
  },
  regions: {
    allRegions: 'Todas las regiones',
    global: 'Global',
    asia: 'Asia',
    europe: 'Europa',
    southAmerica: 'America del sur',
    northAmerica: 'America del norte',
    oceania: 'Oceania',
    apac: 'APAC',
  },
  tournamentSearch: {
    tournamentFilter: 'Filtros de torneos',
    tournamentName: 'Nombre del torneo',
    clearFilter: 'Limpiar filtro',
    missingTournament: 'Torneo faltante?',
    recentTournaments: 'Torneos recientes',
    allTournaments: 'Todos los torneos',
    tournament: 'Torneo',
    createTournament: 'Crear un torneo',
    noTournaments: 'No hay torneos',
  },
  content: {
    title: 'Noticias',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'Escribir un comentario',
    newCommentPlaceholder: 'Tu comentario',
  },
  tournament: {
    tournament: 'Torneo',
    tournamentInfo: 'Información del torneo',
    region: 'Región',
    matches: 'Partidas',
    leaderboards: 'Tabla de posiciones',
    prizeLeaderboards: 'Recompensas',
    playerStats: 'Estadísticas del jugador',
    teamStats: 'Estadísticas del equipo',
    selectTournament: 'Seleccionar un torneo',
    noMatchesFound: 'Partidas no encontradas',
    noLeaderboards: 'Tabla de posiciones no disponible para este torneo',
    leaderboardsNotFound: 'La tabla de posiciones no existe o todavia no se han jugado las partidas. Intentalo de nuevo más tarde',
    noPlayerStats: 'No hay estadísticas disponibles para este torneo',
    playerStatsNotFound: 'Las estadisticas no existen o todavia no hay partidas jugadas. Intentalo más tarde',
    tournamentPhase: 'Fase del torneo',
    selectTournamentPhase: 'Seleccionar fase del torneo',
    loadingMatches: 'Cargando partidas...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'Buscar por nombre de usuario',
    pubgUsername: 'Nombre de usuario de PUBG (distingue mayúsculas y minúsculas)',
    matchesOf: 'Partidas de',
    enterPubgUsername: 'Ingrese nombre de usuario de PUBG',
    usernameNotTracked: 'No conseguimos seguir este nombre de usuario por lo que podriamos no tener todas las partidas. Puedes agregar un nombre de usuario en',
    settings: 'Ajustes',
    favoriteUsers: 'Favoritos',
  },
  match: {
    rank: 'Posición',
    date: 'Fecha',
    match: 'Partida',
    map: 'Mapa',
    type: 'Tipo',
    zone: 'Zona',
    analyzeStats: 'Analizar/Estadísticas',
    mode: 'Modo',
    duration: 'Duración',
  },
  matchDetails: {
    matchDetails: 'Detalles de la partida',
    matchStats: 'Estadísticas de la partida',
    match: 'Partida',
    map: 'Mapa',
    mode: 'Modo',
    duration: 'Duración',
  },
  matchStats: {
    rank: 'Posición',
    wins: 'WWCD',
    totalPoints: 'Puntos totales',
    avgTotalPoints: 'Promedio total de puntos',
    avgRank: 'Posición promedio',
    points: 'Puntos de posición',
    avgPoints: 'Puntos de posicionamiento promedio',
    twr: 'TWR',
    numOfMatches: 'Numero de partidas',
    team: 'Equipo',
    player: 'Jugador',
    username: 'Jugador',
    kills: 'Asesinatos',
    avgKills: 'Promedio de asesinatos',
    assists: 'Asistencias',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Daño infligido',
    avgDamageDealt: 'Promedio de daño infligido',
    damageTaken: 'Daño recibido',
    avgDamageTaken: 'Promedio de daño recibido',
    dbnos: 'Noqueados',
    revives: 'Revividos',
    headshotKills: 'Muertes por disparos a la cabeza',
    longestKill: 'Tiro mas largo (m)',
    killSteals: 'Asesinatos robados',
    killsStolenFrom: 'Asesinatos robados al jugador',
    grenadePickup: 'Granadas tomadas',
    grenadeDrop: 'Granadas soltadas',
    grenadeThrow: 'Granadas lanzadas',
    grenadeDamage: 'Daño por granadas',
    molotovPickup: 'Molotovs tomadas',
    molotovDrop: 'Molotovs soltadas',
    molotovThrow: 'Molotovs lanzadas',
    molotovDamage: 'Daño por molotov',
    smokebombPickup: 'Humos tomados',
    smokebombDrop: 'Humos soltados',
    smokebombThrow: 'Humos lanzados',
    flashbangPickup: 'Flashes tomadas',
    flashbangDrop: 'Flashes soltadas',
    flashbangThrow: 'Flashes lanzadas',
    swimDistance: 'Distancia nadada (km)',
    walkDistance: 'Distancia caminada (km)',
    rideDistance: 'Distancia manejada (km)',
    timeSurvived: 'Tiempo sobrevivido',
    avgTimeSurvived: 'Tiempo promedio sobrevivido',
    deathType: 'Tipo de muerte',
    heals: 'Curas',
    healthRecovered: 'Salud recuperada',
    boosts: 'Boosts',
    vehicleDestroys: 'Vehiculos destruidos',
    kd: 'KD',
    killsKnocks: 'Asesinatos/Noqueados',
    arDamage: 'Daño infligido (AR)', // TODO missing
    dmrDamage: 'Daño infligido (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'Daño infligido/Daño recibido',
    damageTakenFromBlueZone: 'Daño recibido (Zone)', // TODO missing
    damageTakenFromEnemy: 'Daño recibido (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'Asesinatos robados - Cuando el jugador asesina a alguien que fue noqueado por otro equipo',
    stolenKillsFromExplaines: 'Asesinatos robados por - Cuando el player noquea a un jugador y es finalizado por otro equipo',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: 'Guardar',
    undo: 'Deshacer',
    clearAll: 'Limpiar todo',
    zonesToDraw: 'Zona de dibujo',
    pen: 'Boligrafo',
    playerDetails: 'Detalles del jugador',
    rotationDetails: 'Detalles de rotación',
    playerNames: 'Nombres de los jugadores',
    teamNames: 'Nombres de los equipos',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: 'Mostrar jugadores muertos',
    grid: 'Cuadrícula',
    planePath: 'Ruta del avión',
    showPlanePaths: 'Mostrar ruta del avión',
    showRotations: 'Mostrar rotaciónes',
    showCircles: 'Mostrar circulos',
    showLandingSpots: 'Mostrar spot de aterrizaje',
    heatmap: 'Heatmap', // TODO missing
    help: 'Ayuda',
  },
  help: {
    help: 'Ayuda',
    tutorial: 'Tutorial',
    runTutorial: 'Ejecutar tutorial',
    shortcuts: 'Atajos',
    keyBinding: 'Bindeo de teclas',
    description: 'Descripción',
    playPause: 'Reproducir/pausar',
    stepBackward: 'Dar un paso atrás (2 seg.)',
    stepForward: 'Dar un paso adelante (2 seg.)',
    previousSpeed: 'Velocidad previa de reproducción',
    nextSpeed: 'Siguiente velocidad de reproducción',
    gird: 'mostrar/esconder cuadricula',
    teamNames: 'mostrar/esconder nombres de equipos',
    playerNames: 'mostrar/esconder nombres de los jugadores',
    deadPlayers: 'mostrar/esconder jugadores muertos',
    planePath: 'mostrar/esconder ruta del avión',
    playerDetails: 'abrir/cerrar detalles de los jugadores',
    rotationDetails: 'abrir/cerrar detalles de las rotaciónes',
    fullscreen: 'encender/apagar pantalla completa',
  },
  matchAnalyzer: {
    names: 'Nombres',
    trace: 'Rastro',
    emptyInventory: 'Inventario vacío',
  },
  teamsSearch: {
    searchByTeam: 'Buscar por equipo',
    teamNameOrInitial: 'Nombre del equipo o inicial',
    teams: 'Equipos',
    team: 'Equipo',
    noResults: 'No hay resultados',
    oldRotationAnalyzer: 'Analizador de rotaciónes antiguo',
    tryNow: 'Intentalo ahora',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Analizador de rotación',
    selectMap: 'Seleccionar mapa a analizar',
    match: 'Partida',
    hideAll: 'Esconder todo',
    show: 'Mostrar',
    rank: 'Posición',
    kills: 'Asesinatos',
    damageDealt: 'Daño infligido',
    damageTaken: 'Daño recibido',
    zoneDirection: 'Dirección de la zona',
    phases: 'Fases',
    walking: 'Caminar',
    driving: 'Manejar',
    endingZoneArea: 'ÁREA DEL CÍRCULO FINAL',
    selectZoneArea: 'Seleccionar área de la zona',
    selectTeam: 'Añadir al menos un equipo',
    zone: 'Zona',
    reset: 'Restablecer',
    resetWarning: 'Esta acción eliminara todas las rotaciones que han sido añadidas y restablecera la zona seleccionada',
    teams: 'Equipos',
    add: 'Añadir',
    noTeams: 'No hay equipos seleccionados',
  },
  saved: {
    noPremium: 'Esta caracteristica es solo para usuarios Premium. Aún puede acceder a sus antiguas partidas guardadas, pero no puede guardar las nuevas.',
    savedMatches: 'Partidas guardadas',
    rename: 'Renombrar',
    delete: 'Eliminar',
    renameFile: 'Renombrar archivo',
    newFileName: 'Nuevo nombre de archivo',
    fileDelete: 'Archivo eliminado',
    fileDeleteWarning: '¿Estas seguro que quieres eliminar este archivo?',
    matchAnalysis: 'Analisis de partida',
    modalRenameTitle: 'Renombrar partida',
    modalRenamePlaceholder: 'Nuevo nombre de partida',
    modalDeleteTitle: 'Eliminar partida',
    modalDeletePlaceholder: '¿Estas seguro que quieres eliminar esta partida?',
  },
  connect: {
    title: 'Mantente al día con nosotros!',
    discordConnectText: '¡Conectemonos a través de Discord!',
    connectMe: 'Conectame!',
  },
  features: {
    title: '¿Que consigues con esto?',
    feature1Title: 'Tabla de posiciones',
    feature1Text: 'Mantente al día con los torneos de todo el mundo.',
    feature2Title: 'Jugadores\' Estadísticas',
    feature2Text: 'Compara jugadores y su habilidad en diferentes categorías.',
    feature3Title: 'Analizador de partidas',
    feature3Text: 'Analiza tus partidas y potencia tu trabajo remoto de forma colaborativa',
    feature4Title: 'Analizador de rotaciónes',
    feature4Text: 'Conoce las rotaciónes de tus oponentes y prepara tu estrategia de ataque',
  },
  games: {
    title: '¿Qué juegos juegas?',
    availableNow: 'disponible ahora',
    comingSoon: 'proximamente',
  },
  partners: {
    title: 'Partners',
  },
  pricing: {
    title: 'Premios',
    advertisement: 'Anuncio publicitario',
    matchAnalyzer: 'Analizador de partidas',
    rotationAnalyzer: 'Analizador de rotaciónes',
    drawing: 'Dibujo',
    collaborativeDrawing: 'Dibujo colaborativo',
    savingMatches: 'Guardando partidas',
    premium: 'Premium',
    freePeriod: '14 días de prueba',
    paidMonthly: 'Pagado mensualmente',
    paidQuarterly: 'Pagado trimestralmente',
    noCardRequired: 'No se requiere tarjeta',
    planContact1: 'Si tu o tu equipo necesita un plan modificado contactanos!',
    planContact2: 'o via',
    planContact3: '',
    free: 'Gratis',
  },
  payment: {
    title: 'Premios',
    saved: 'Guardado',
    ads: 'Quitar anuncios',
    analyzerMapReplay: 'Analizar repeticiones del mapa',
    seeTeamsRotations: 'Ver equipos\' rotaciónes',
    workCollaboratively: 'Trabaja colaborativamente',
    shareDrawingInRealTime: 'Comparte dibujos en tiempo real',
    drawing: 'Dibujo',
    markImportantMoments: 'Marca dibujos importante',
    saveitForLater: 'Guárdalo para después',
    recommended: 'Recomendado',
    billedQuarterly: 'Facturado trimestralmente',
    billedMonthly: 'Facturado mensualmente',
    selectPlan: 'Seleccionar plan',
    billedNow: 'Facturado ahora',
    alreadyPremium: 'Ya eres un miembro Premium',
    expiresInDays: 'La prueba gratuita vence en 14 días',
    learnMore: 'Aprende más',
  },
  welcome: {
    title1: 'Pon en marcha tu',
    title2: 'CARRERA EN LOS ESPORTS',
    subtitle: 'Herramientas de análisis para los ganadores',
    register: 'Registrate gratis',
    learnMore: 'Quiero aprender más',
  },
  forgotPassword: {
    emailSent: 'Correo electronico enviado!',
    emailSentMessage: 'Se le envió un correo electrónico con el enlace para restablecer la contraseña.',
    forgotPassword: 'Contraseña olvidada',
    inputUsername: 'Has olvidado tu contraseña',
    sentResetLink: 'Enviar enlace de reinicio',
  },
  login: {
    signUp: 'Inscribete',
    dontHaveAccountLong: '¿Todavia no tienes una cuenta? ¡Que estas esperando! Regístrate y comienza a mejorar tus habilidades con nuestras increíbles herramientas!',
    dontHaveAccount: '¿Todavia no tienes una cuenta?',
    registerNow: '¡Regístrate ahora!',
    login: 'Iniciar sesión',
    resetPassword: 'Restablecer contraseña',
    reset: 'Restablecer',
    signInAccount: 'Iniciar sesión en su cuenta',
    passwordResetNeeded: 'Necesitas restablecer tu contraseña',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmación de contraseña',
    forgotPassword: '¿Has olvidado tu contraseña?',
    resetPasswordRequired: 'Necesitas restablecer tu contraseña',
    wrongCredentials: 'Nombre de usuario o contraseña invalida',
    passwordsDontMatch: 'Las contraseñas no coinciden',
    passwordToShort: 'Las contraseñas deben incluir al menos 8 caracteres, al menos una letra y un numero',
  },
  register: {
    register: 'Registrarse',
    thankYou: 'Gracias!',
    thankYouLong: 'Gracias por crear una cuenta! Revisa tu correo electrónico para finalizar el registro.',
    username: 'Nombre de usuario',
    email: 'Correo electronico',
    password: 'Contraseña',
    repeatPassword: 'Repite contraseña',
    subscribeToNewsletter: 'Suscríbete a nuestro boletín',
    readAndUnderstand: 'He leido y entendido',
    termsOfUse: 'Terminos de uso',
    createAccount: 'Crear cuenta',
    alreadyRegistered: '¿Ya estas registrado?',
    invalidUsername: 'Nombre de usuario invalido',
    invalidEmail: 'Correo electronico invalido',
    invalidPassword: 'Las contraseñas deben incluir al menos 8 caracteres, al menos una letra y un numero',
    passwordsDontMatch: 'Las contraseñas no coinciden',
    termsRequired: 'Debes aceptar los Términos de uso.',
  },
  resetPassword: {
    resetPassword: 'Restablecer contraseña',
    selectNewPassword: 'Seleccionar nueva contraseña',
    password: 'Contraseña',
    passwordConfirmation: 'Confirmación de contraseña',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: '¡Fallo en la verificación! Tu enlace esta roto, expirado o ya ha sido verificado.',
    resendEmail: 'Re-enviar correo electronico',
    verifyingEmail: 'Verificando correo electronico...',
    verificationSuccess: 'Verificación completada! Puedes iniciar sesión ahora!',
  }
}