import { 
  REQUEST_RESET_PASSWORD_PENDING,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR,
  RESET_PASSWORD_CLEAN
} from './types';

const INITIAL_STATE = {
  resetPasswordLoaded: false,
  resetPasswordError: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On forgot password in progress
    case REQUEST_RESET_PASSWORD_PENDING:
    case RESET_PASSWORD_CLEAN:
      return {
        ...state,
        resetPasswordLoaded: false,
        resetPasswordError: false
      };
      
    // On successful send mail
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoaded: true,
        resetPasswordError: false
      };

    // On forgot password error
    case REQUEST_RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordLoaded: false,
        resetPasswordError: true
      };

    default:
      return state;
  }
}

export default reducer;