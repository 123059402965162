import moment from 'moment';
import { ATTACK_OFFSET, PLAYER_KNOCKED, PLAYER_DEAD, PLAYER_ALIVE } from '../util/Constants';

const TELEMETRY_VERSION_1 = 'v1';
const TELEMETRY_VERSION_2 = 'v2';

// TODO deank: throwables count does not work 100% correctly
/** 
 * Represents how long the attack lasts because
 * attack event is created when the bullet hits
 * the victim.
 */
const MAX_BOOST_HEAL = 8;
const BOOST_POINTS = { 'Item_Boost_AdrenalineSyringe_C': 100, 'Item_Boost_PainKiller_C': 60, 'Item_Boost_EnergyDrink_C': 40 };

/**
 * This function parses given telemetry and returns it 
 * in a form that is ready for display.
 * 
 * @param {*} telemetry telemetry in from od JSON
 * @param {*} matchCreatedAt UTC time when match was created
 * @param {*} matchDuration match duration in seconds
 */
// TDOD deank: when referencing last and next index, maybe next is not needed
export default function parseTelemetry(telemetry, matchCreatedAt, matchDuration) {
  const startTime = moment.utc(matchCreatedAt).valueOf();
  const states = Array((matchDuration + 5) * 10);
  const currentState = {};
  const teams = {};
  const teamsAlive = {};
  const teamRankings = [];
  const players = {};
  const playerAdditionalInfo = {};
  const zones = [];
  const stateSteps = {};
  const attacks = {};
  const planePoints = [];

  /**
   * Merges given state with current one to update values
   * 
   * @param {*} name name of the player
   * @param {*} state new state (only variables to be updated)
   * @param {*} index current index
   */
  const mergePlayerState = (name, state, index) => {
    currentState.players[name] = {...currentState.players[name], ...state};
    if (!states[index]) {
      states[index] = { players: {} };
    }
    states[index].players[name] = {...currentState.players[name], ...state};
  }

  /**
   * Merges given state with current one to update values
   * 
   * @param {*} name name of the player
   * @param {*} state new state (only variables to be updated)
   * @param {*} index current index
   */
  const mergeEquipmentState = (name, state, index) => {
    const playersEquipment = playerAdditionalInfo[name].equipment;
    mergePlayerState(name, { equipment: playersEquipment.length }, index);
    const newState = {...playersEquipment[playersEquipment.length - 1], ...state};
    playersEquipment.push(newState);
  }

  /**
   * Removes given item from equipment state
   * 
   * @param {*} name name of the player
   * @param {*} item item to be removed
   */
  const removeEquipmentState = (name, item) => {
    const playersEquipment = playerAdditionalInfo[name].equipment;
    const newState = {...playersEquipment[playersEquipment.length - 1]};
    delete newState[item];
    playersEquipment.push(newState);
  }

  /**
   * Attaches given item to weapon of given category
   * 
   * @param {*} name name of the player that attached the item
   * @param {*} currentPlayersEquipment current equipment of player
   * @param {*} category category of parent item + Att (mainGun1Att)
   * @param {*} item item that is being attached
   * @param {*} index current index
   */
  const attachItem = (name, currentPlayersEquipment, category, item, index) => {
    const attachments = currentPlayersEquipment[category] ? [...currentPlayersEquipment[category]] : [];
    attachments.push(item.itemId);
    mergeEquipmentState(name, { [category]: attachments }, index);
  }

  /**
   * Detaches given item from weapon of given category
   * 
   * @param {*} name name of the player that detached the item
   * @param {*} currentPlayersEquipment current equipment of player
   * @param {*} category category of parent item + Att (mainGun1Att)
   * @param {*} item item that is being detached
   * @param {*} index current index
   */
  const detachItem = (name, currentPlayersEquipment, category, item, index) => {
    const attachments = currentPlayersEquipment[category] ? [...currentPlayersEquipment[category]] : [];
    const i = attachments.indexOf(item.itemId);
    if (i > -1) {
      attachments.splice(i, 1);
    }
    mergeEquipmentState(name, { [category]: attachments }, index);
  }

  /**
   * Loggs users location
   * 
   * @param {*} character 
   */
  const logPlayerPosition = (character, index) => {
    const { name, location, health } = character;

    if (!playerAdditionalInfo[name]) {
      return;
    }

    const lastLocation = playerAdditionalInfo[name].locations.length;
    playerAdditionalInfo[name].locations.push(location.x);
    playerAdditionalInfo[name].locations.push(location.y);
    const nextLocation = playerAdditionalInfo[name].locations.length;
    mergePlayerState(name, {
      lastLocation: lastLocation,
      nextLocation: nextLocation,
      health: health,
      lastStateIndex: index
    }, index);
  }

  /**
   * Logs player's attack that actually result in 
   * victim taking damage.
   * 
   * @param {*} event LogPlayerTakeDamage event
   * @param {*} index index of state that event belongs to
   */
  const logPlayerTakeDamage = (event, index) => {
    const { attacker, victim, damage } = event;

    if (!currentState.players[victim.name]) {
      return;
    }

    // Decreasing victim's health
    const newHealth = currentState.players[victim.name].health - damage;
    mergePlayerState(victim.name, { health: newHealth }, index);
    // Increasing attacker's damage if attacker is player
    if (attacker && attacker.name !== victim.name && currentState.players[attacker.name]) {
      const newDamage = currentState.players[attacker.name].damage + damage;
      attacks[attacker.name][index - ATTACK_OFFSET] = victim.name;
      mergePlayerState(attacker.name, { damage: newDamage }, index);
      if (playerAdditionalInfo[attacker.name].attacks[index]) {
        playerAdditionalInfo[attacker.name].attacks[index].push(victim.name);
      } else {
        playerAdditionalInfo[attacker.name].attacks[index] = [victim.name];
      }
    }
  }

  /**
   * Logs player's attack.
   * 
   * @param {*} event LogPlayerAttack event
   * @param {*} index index of state that event belongs to
   */
  const logPlayerAttack = (event, index) => {
    const { attacker, weapon } = event;
    if (attacker && weapon.subCategory === 'Throwable') {

      if (!playerAdditionalInfo[attacker.name]) {
        return;
      }
      
      // Getting states
      const playersEquipment = playerAdditionalInfo[attacker.name].equipment;
      const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
      const currentItems = currentPlayersEquipment.items ? {...currentPlayersEquipment.items} : {};
      if (currentItems[weapon.itemId]) {
        currentItems[weapon.itemId] -= 1;
      }
      mergeEquipmentState(attacker.name, { items: currentItems }, index);
    }
  }

  /**
   * Logs player's knock
   * 
   * @param {*} event LogPlayerMakeGroggy event
   * @param {*} index index of state that event belongs to
   */
  const logPlayerMakeGroggy = (event, index) => {
    const { victim } = event;
    mergePlayerState(victim.name, { 
      status: PLAYER_KNOCKED,
      health: 0,
      boost: 0
    }, index);
  }

  /**
   * Logs player's kill
   * 
   * @param {*} event LogPlayerKill event
   * @param {*} index index of state that event belongs to
   */
  const logPlayerKill = (event, index) => {
    const { killer, victim } = event;
    const team = teamsAlive[victim.teamId];
    const i = team.indexOf(victim.name);
    if (i > -1) {
      team.splice(i, 1);
      if (team.length === 0) {
        const rank = Object.values(teamsAlive).filter(t => t.length !== 0).length + 1;
        teamRankings[rank] = victim.teamId;
      }
    }
    mergePlayerState(victim.name, { 
      status: PLAYER_DEAD,
      health: 0,
      boost: 0
    }, index);
    if (killer && killer.name) {
      if (killer.name === victim.name || !currentState.players[killer.name]) return;
      mergePlayerState(killer.name, { 
        kills: currentState.players[killer.name].kills + 1 
      }, index)
    }
  }

  /**
   * Logs player's booster heal
   * 
   * @param {*} event LogHeal event
   * @param {*} index index of state that event belongs to
   */
  const logHeal = (event, index) => {
    const { character, healAmount } = event;
    if (healAmount < MAX_BOOST_HEAL) {

      if (!currentState.players[character.name]) {
        return;
      }

      const newBoost = currentState.players[character.name].boost - Math.abs(healAmount);
      mergePlayerState(character.name, { boost: newBoost }, index);
    }
  }

  /**
   * Logs item use evet.
   * Only boosts and heals can be used. Since healt is already
   * calcaulated during logPlayerPosition event only boost are
   * considered here.
   * 
   * @param {*} event LogItemUse event
   * @param {*} index index of state that event belongs to
   */
  const logItemUse = (event, index) => {
    const { character, item } = event;
    // Checking if item used was boost
    if (item.subCategory === 'Boost') {
      // Calculating new amount of boost points for player (max 100)
      const newBoost = Math.min(currentState.players[character.name].boost + BOOST_POINTS[item.itemId], 100);
      mergePlayerState(character.name, { boost: newBoost }, index);
    }
    const playersEquipment = playerAdditionalInfo[character.name].equipment;
    const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
    const currentItems = currentPlayersEquipment.items ? {...currentPlayersEquipment.items} : {};
    currentItems[item.itemId] = currentItems[item.itemId] > 0 ? currentItems[item.itemId] - 1 : 0;
    mergeEquipmentState(character.name, { items: currentItems }, index);
  }

  /**
   * Logs player's revive.
   * 
   * @param {*} event LogPlayerRevive event
   * @param {*} index index of state that event belongs to
   */
  const logPlayerRevive = (event, index) => {
    const { victim } = event;
    mergePlayerState(victim.name, { status: PLAYER_ALIVE }, index);
  }

  /**
   * Logs item equip event.
   * This event only happens when user equips
   * weapon, helmet, armor or backpack.
   * 
   * @param {*} event LogItemEquip event
   * @param {*} index index of state that event belongs to
   */
  const logItemEquip = (event, index) => {
    const { character, item } = event;

    if (!playerAdditionalInfo[character.name]) {
      return;
    }

    // Getting the subcategory of item
    const itemSubCategory = getItemSubCategory(item);
    const playersEquipment = playerAdditionalInfo[character.name].equipment;
    const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
    mergePlayerState(character.name, { equipment: playersEquipment.length }, index);
    if (itemSubCategory === 'main') {
      // If subcategory is main determination of empty slot is required
      if (!currentPlayersEquipment.mainGun1) {
        mergeEquipmentState(character.name, { 'mainGun1Att': [] }, index);
        mergeEquipmentState(character.name, { mainGun1: item.itemId }, index);
      } else {
        mergeEquipmentState(character.name, { 'mainGun2Att': [] }, index);
        mergeEquipmentState(character.name, { mainGun2: item.itemId }, index);
      }
    } else {
      // Setting item
      mergeEquipmentState(character.name, { [itemSubCategory]: item.itemId }, index);
    }
  }

  /**
   * Logs item unequip event.
   * This event only happens when user unequips
   * weapon, helmet, armor or backpack.
   * 
   * @param {*} event LogItemUnequip event
   * @param {*} index index of state that event belongs to
   */
  const logItemUnequip = (event, index) => {
    const { character, item } = event;
    if (item.itemId.toLowerCase().includes('parachute') || !playerAdditionalInfo[character.name]) {
      return;
    }
    // Getting the subcategory of item
    const itemSubCategory = getItemSubCategory(item);
    const playersEquipment = playerAdditionalInfo[character.name].equipment;
    const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
    mergePlayerState(character.name, { equipment: playersEquipment.length }, index);
    if (itemSubCategory === 'main') {
      // If subcategory is main determination of empty slot is required
      if (currentPlayersEquipment.mainGun1 === item.itemId) {
        mergeEquipmentState(character.name, { 'mainGun1Att': [] }, index);
        removeEquipmentState(character.name, 'mainGun1');
      } else {
        mergeEquipmentState(character.name, { 'mainGun2Att': [] }, index);
        removeEquipmentState(character.name, 'mainGun2');
      }
    } else if (itemSubCategory === 'handgun') {
      // Setting item
      removeEquipmentState(character.name, itemSubCategory);
    }
  }

  /**
   * Logs armor destroy event.
   * This event happens when
   * weapon, helmet, armor or backpack is destroyed.
   * 
   * @param {*} event LogArmorDestroy event
   * @param {*} index index of state that event belongs to
   */
  const logArmorDestroy = (event, index) => {
    const { victim, item } = event;
    // Getting the subcategory of item
    const itemSubCategory = getItemSubCategory(item);
    const playersEquipment = playerAdditionalInfo[victim.name].equipment;
    mergePlayerState(victim.name, { equipment: playersEquipment.length }, index);
    removeEquipmentState(victim.name, itemSubCategory);
  }

  /**
   * Logs item attached event.
   * 
   * @param {*} event LogItemAttach event
   * @param {*} index index of state that event belongs to
   */
  const logItemAttach = (event, index) => {
    const { character, parentItem, childItem } = event;
    // Getting the subcategory of item
    const itemSubCategory = getItemSubCategory(parentItem);
    const playersEquipment = playerAdditionalInfo[character.name].equipment;
    const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
    mergePlayerState(character.name, { equipment: playersEquipment.length }, index);
    if (itemSubCategory === 'main') {
      // If subcategory is main determination of right slot is required
      // Setting attachment for main
      if (currentPlayersEquipment.mainGun1 === parentItem.itemId) {
        // Adding attached item to parent item for current state
        attachItem(character.name, currentPlayersEquipment, 'mainGun1Att', childItem, index);
      } else {
        // Adding attached item to parent item for current state
        attachItem(character.name, currentPlayersEquipment, 'mainGun2Att', childItem, index);
      }
    } else if (itemSubCategory === 'handgun') {
      // Adding attached item to parent item for current state
      attachItem(character.name, currentPlayersEquipment, 'handgunAtt', childItem, index);
    }
  }

  /**
   * Logs item detached event.
   * 
   * @param {*} event LogItemDetach event
   * @param {*} index index of state that event belongs to
   */
  const logItemDetach = (event, index) => {
    const { character, parentItem, childItem } = event;
    // Getting the subcategory of item
    const itemSubCategory = getItemSubCategory(parentItem);
    const playersEquipment = playerAdditionalInfo[character.name].equipment;
    const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
    mergePlayerState(character.name, { equipment: playersEquipment.length }, index);
    if (itemSubCategory === 'main') {
      // If subcategory is main determination of right slot is required
      // Setting attachment for main
      if (currentPlayersEquipment.mainGun1 === parentItem.itemId) {
        // Removing detached item from parent item for current state
        detachItem(character.name, currentPlayersEquipment, 'mainGun1Att', childItem, index);
      } else {
        // Removing detached item from parent item for current state
        detachItem(character.name, currentPlayersEquipment, 'mainGun2Att', childItem, index);
      }
    } else if (itemSubCategory === 'handgun') {
      // Removing detached item from parent item for current state
      detachItem(character.name, currentPlayersEquipment, 'handgun', childItem, index);
    }
  }

  /**
   * Logs item pickup event.
   * 
   * @param {*} event LogItemPickup or LogItemPickupFromLootBox or LogItemPickupFromCarepackage event
   * @param {*} index index of state that event belongs to
   */
  const logItemPickup = (event, index) => {
    const { character, item } = event;
    // Checking if this item is for saving
    if (isItemForSaving(item)) {
      // Getting states
      const playersEquipment = playerAdditionalInfo[character.name].equipment;
      const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
      const currentItems = currentPlayersEquipment.items ? {...currentPlayersEquipment.items} : {};
      if (currentItems[item.itemId]) {
        currentItems[item.itemId] += Math.abs(item.stackCount);
      } else {
        currentItems[item.itemId] = Math.abs(item.stackCount);
      }
      mergeEquipmentState(character.name, { items: currentItems }, index);
    }
  }

  /**
   * Logs player entering vehicle
   * 
   * @param {*} event LogVehicleRide event
   * @param {*} index index of state that event belongs to
   */
  const logVehicleRide = (event, index) => {
    const { character, vehicle } = event;
    mergePlayerState(character.name, { vehicle: vehicle.vehicleId }, index);
  }

  /**
   * Logs player leaving vehicle
   * 
   * @param {*} event LogVehicleLeave
   * @param {*} index index of state that event belongs to
   */
  const logVehicleLeave = (event, index) => {
    const { character, vehicle } = event;
    if (vehicle.vehicleId === 'DummyTransportAircraft_C' || 
      vehicle.vehicleId === 'TransportAircraft_Chimera_C' || 
      vehicle.vehicleId === 'WarModeTransportAircraft_C') {
      planePoints.push(character.location);
    }
    mergePlayerState(character.name, { vehicle: false }, index);
  }

  /**
   * Logs item drop event.
   * 
   * @param {*} event LogItemDrop event
   * @param {*} index index of state that event belongs to
   */
  const logItemDrop = (event, index) => {
    const { character, item } = event;
    // Checking if this item is for saving
    if (isItemForSaving(item)) {
      // Getting states
      const playersEquipment = playerAdditionalInfo[character.name].equipment;
      const currentPlayersEquipment = playersEquipment.length === 0 ? {} : playersEquipment[playersEquipment.length - 1];
      const currentItems = currentPlayersEquipment.items ? {...currentPlayersEquipment.items} : {};
      if (currentItems[item.itemId]) {
        currentItems[item.itemId] = Math.max(0, currentItems[item.itemId] - Math.abs(item.stackCount));
      }
      mergeEquipmentState(character.name, { items: currentItems }, index);
    }
  }

  /**
   * Logs game state periodic event.
   * 
   * @param {*} event LogGameState event
   * @param {*} index index of state that event belongs to
   */
  const logGameStatePeriodic = (event, index) => {
    const { 
      safetyZonePosition, safetyZoneRadius, 
      redZonePosition, redZoneRadius, 
      poisonGasWarningPosition, poisonGasWarningRadius, 
      blackZonePosition, blackZoneRadius 
    } = event.gameState;
    if (!states[index]) {
      states[index] = { players: {}, zones: {} };
    } else {
      states[index].zones = {};
    }
    states[index].zones.lastZone = zones.length - 1;
    const currentZones = {
      safe: {
        x: poisonGasWarningPosition.x,
        y: poisonGasWarningPosition.y,
        r: poisonGasWarningRadius
      },
      blue: {
        x: safetyZonePosition.x,
        y: safetyZonePosition.y,
        r: safetyZoneRadius
      },
      red: {
        x: redZonePosition.x,
        y: redZonePosition.y,
        r: redZoneRadius
      },
    };
    if (blackZonePosition) {
      currentZones.black = {
        x: blackZonePosition.x,
        y: blackZonePosition.y,
        r: blackZoneRadius
      };
    }
    zones.push(currentZones);
    states[index].zones.nextZone = zones.length;
    states[index].zones.lastStateIndex = index;
  }

  /**
   * Logs match end event to get team rankings.
   * 
   * @param {*} event LogMatchEnd event
   */
  const logMatchEnd = (event) => {
    const winningPlayer = event.gameResultOnFinished.results[0];
    if (winningPlayer) {
      teamRankings[winningPlayer.rank] = winningPlayer.teamId;
    }
  }

  let telemetryVersion = TELEMETRY_VERSION_1;
  telemetry.forEach((d, i) => {
    // Match as not started yet
    if (i === 0 || d.common.isGame === 0) {
      return;
    }

    // Parsing start of the match event
    if (d._T === 'LogMatchStart') {
      telemetryVersion = getTelemetryVersion(d._D);
      currentState.players = {};
      d.characters.forEach((c, j) => {
        // Generating empty player state for every player
        if (c.character) c = c.character;
        const character = {};
        character.lastLocation = -2;
        character.nextLocation = 2;
        character.status = PLAYER_ALIVE;
        character.health = 100;
        character.boost = 0;
        character.vehicle = false;
        character.kills = 0;
        character.damage = 0;
        character.lastStateIndex = 0;
        currentState.players[c.name] = character;
        // Adding initial state of player's additional info
        playerAdditionalInfo[c.name] = {
          start: null,
          locations: [c.location.x, c.location.y],
          equipment: [],
          attacks: {}
        };
        // Adding player to a team
        if (!teams[c.teamId]) {
          teams[c.teamId] = [];
          teamsAlive[c.teamId] = [];
        }
        teams[c.teamId].push(c.name);
        teamsAlive[c.teamId].push(c.name);
        // Adding team to a player
        players[c.name] = c.teamId;
        // State steps
        stateSteps[c.name] = 0;
        // Attacks
        attacks[c.name] = {};
      });
      // Deep copying intial state
      states[0] = deepCopy(currentState);
      return;
    }

    // Calculating current index in state array
    const msSinceStart = new Date(d._D).getTime() - startTime;
    const currentStateIndex = Math.floor(msSinceStart / 100);

    if (d._T === 'LogParachuteLanding') {
      playerAdditionalInfo[d.character.name].startIndex = currentStateIndex;
      playerAdditionalInfo[d.character.name].start = playerAdditionalInfo[d.character.name].locations.length;
    }

    // Checkinig if player's location is present in current event
    if (d.character && d.character.name) {
      logPlayerPosition(d.character, currentStateIndex);
    }
    // TODO deank: remove the second pard of the condition when bug with zero location on attacker will be fixed
    if (d.attacker && d.attacker.name && !(d.victim && d.victim.name === d.attacker.name && d.damageTypeCategory === 'Damage_Groggy')) {
      logPlayerPosition(d.attacker, currentStateIndex);
    }
    if (d.victim && d.victim.name) {
      logPlayerPosition(d.victim, currentStateIndex);
    }
    if (d.killer && d.killer.name) {
      logPlayerPosition(d.killer, currentStateIndex);
    }

    // Parsing game state preriodic event
    if (d._T === 'LogGameStatePeriodic' && d.common.isGame > 0.5) {
      logGameStatePeriodic(d, currentStateIndex);
    }

    // Parsing player attack event
    if (d._T === 'LogPlayerAttack') {
      logPlayerAttack(d, currentStateIndex);
    }

    // Parsing player take damage event
    if (d._T === 'LogPlayerTakeDamage') {
      logPlayerTakeDamage(d, currentStateIndex);
    }

    // Parsing groggy event
    if (d._T === 'LogPlayerMakeGroggy') {
      logPlayerMakeGroggy(d, currentStateIndex);
    }

    // Parsing kill event
    if ((d._T === 'LogPlayerKill' && telemetryVersion === TELEMETRY_VERSION_1) 
      || (d._T === 'LogPlayerKillV2' && telemetryVersion === TELEMETRY_VERSION_2)) {
      logPlayerKill(d, currentStateIndex);
    }

    // Parsing armor destroy event
    if (d._T === 'LogArmorDestroy') {
      logArmorDestroy(d, currentStateIndex);
    }

    // Parsing heal event
    if (d._T === 'LogHeal') {
      logHeal(d, currentStateIndex);
    }

    // Parsing item use event
    if (d._T === 'LogItemUse') {
      logItemUse(d, currentStateIndex);
    }

    // Parsing item equip event
    if (d._T === 'LogItemEquip') {
      logItemEquip(d, currentStateIndex);
    }

    // Parsing item unequip event
    if (d._T === 'LogItemUnequip') {
      logItemUnequip(d, currentStateIndex);
    }

    // Parsing item attach event
    if (d._T === 'LogItemAttach') {
      logItemAttach(d, currentStateIndex);
    }

    // Parsing item attach event
    if (d._T === 'LogItemDetach') {
      logItemDetach(d, currentStateIndex);
    }

    // Parsing item pickup event
    if (d._T === 'LogItemPickup') {
      logItemPickup(d, currentStateIndex);
    }

    // Parsing item drop event
    if (d._T === 'LogItemDrop') {
      logItemDrop(d, currentStateIndex);
    }

    // Parsing revive event
    if (d._T === 'LogPlayerRevive') {
      logPlayerRevive(d, currentStateIndex);
    }

    if (d._T === 'LogVehicleRide') {
      logVehicleRide(d, currentStateIndex);
    }

    if (d._T === 'LogVehicleLeave') {
      logVehicleLeave(d, currentStateIndex);
    }

    if (d._T === 'LogMatchEnd') {
      logMatchEnd(d);
    }

  });

  const lastRealLocation = {...stateSteps};
  let numOfZoneSteps = 0;
  let lastZoneRealLocation = null;
  for (let i=1; i < states.length; i++) {
    Object.keys(stateSteps).forEach(playerName => {
      if (states[i] && states[i].players[playerName]) {
        states[lastRealLocation[playerName]].players[playerName].numOfSteps = stateSteps[playerName];
        lastRealLocation[playerName] = i;
        stateSteps[playerName] = 0;
      } else {
        stateSteps[playerName] += 1;
      }
    });
    if (states[i]) {
      states[i].players = { ...states[i - 1].players, ...states[i].players };
    } else {
      states[i] = { players: states[i - 1].players };
    }
    if (states[i - 1].zones && !states[i].zones) {
      numOfZoneSteps += 1;
      states[i].zones = {...states[i - 1].zones, lastZone: i - 1 === lastZoneRealLocation ? states[i - 1].zones.lastZone + 1 : states[i - 1].zones.lastZone};
    } else {
      if (states[i].zones) {
        if (lastZoneRealLocation) {
          states[lastZoneRealLocation].zones.numOfSteps = numOfZoneSteps;
        }
        lastZoneRealLocation = i;
      }
      numOfZoneSteps = 0;
    }
  }

  // TODO: ugly fix for figuring out which team won if not already determined
  const notIncludedTeams = Object.keys(teams).filter(teamId => !Object.values(teamRankings).includes(parseInt(teamId)));
  if (notIncludedTeams.length === 1 && !teamRankings[1]) {
    teamRankings[1] = notIncludedTeams[0];
  }

  const planePath = planePoints.length > 1 ? {
    start: [planePoints[0].x, planePoints[0].y],
    end: [planePoints[planePoints.length - 1].x, planePoints[planePoints.length - 1].y],
  } 
  :
  null;
  return { states, teams, teamRankings, players, playerAdditionalInfo, zones, attacks, planePath };
}

/**
 * Deep copies given object
 * 
 * @param {*} o object which has to be deep copied
 */
function deepCopy(o) {
  return JSON.parse(JSON.stringify(o));
}

/**
 * Returns weapon's subcategory.
 * This method is needed because of a bug where
 * flare gun is considered main weapon instead of
 * hand gun.
 * 
 * @param {*} item 
 */
function getItemSubCategory(item) {
  // TODO deank: remove this if when bug with FlareGun will be fixed (it's subcategory is now main instead of handgun)
  return item.itemId === 'Item_Weapon_FlareGun_C' || item.itemId === 'Item_Weapon_vz61Skorpion_C' ? 
    'handgun' 
    : 
    item.subCategory.toLowerCase();
}

/**
 * This function detemines if item is meant to be saved into
 * the backpack state. For example backpack, vest, helment, guns,
 * clothing,.. will not be saved into backpack.
 * 
 * @param {*} item item that needs to be evaluated
 */
function isItemForSaving(item) {
  return item.category === 'Use' || item.subCategory === 'Throwable';
}

/**
 * Returns the version of the telemetry
 * 
 * @param {*} date 
 * @returns 
 */
function getTelemetryVersion(date) {
  if (date > '2021-05-19') {
    console.log('telemetry version 2');
    return TELEMETRY_VERSION_2;
  } else {
    console.log('telemetry version 1');
    return TELEMETRY_VERSION_1;
  }
}