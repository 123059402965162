import { MATCH_LIST_NO_MATCHES, MATCH_LIST_NO_MORE_MATCHES } from "./Messages";

/**
 * Returns message that needs to be displayed 
 * when there is no matches to load.
 * If user is on first page message is different
 * since there is no matches at all.
 * 
 * @param {*} action 
 */
export const getNoMatchesMessage = (action) => {
  const matches = action.payload.matches;
  const page = action.payload.page;
  if (!matches || matches.length === 0) {
    return page === 1 ? MATCH_LIST_NO_MATCHES : MATCH_LIST_NO_MORE_MATCHES;
  }
  return null;
}