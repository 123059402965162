import { all, takeLatest, put, select } from 'redux-saga/effects';
import { privateMutation, privateSubscription } from '../../graphql/requestHelper';
import {  
  REQUEST_FILE_HIGHLIGHTS_SAVE, REQUEST_FILE_SAVE_NEW, REQUEST_FILE_SAVE_TEMP, REQUEST_FILE_SAVE_TEMP_SUCCESS, SET_FILE_STATE, REQUEST_MAKE_FILE_PUBLIC, REQUEST_SUBSCRIPTION_TO_FILE
} from './types';
import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from '../notification/types';
import { ERROR_SAVE_FAILED, ERROR_SUBSCRIPTION_TO_FILE } from '../../util/ErrorMessages';
import { saveHighlights, saveFile, renameFile, makeFilePublic } from '../../graphql/private/mutations';
import { FILE_SAVE_SUCCESS, FILE_CHANGES_SAVE_SUCCESS } from '../../util/Messages';
import { subscribeToFileById } from '../../graphql/private/subscriptions';

/**
 * Saves highlights to sever
 */
function* requestSaveNewFile(action) {
  try {
    const matchAnalyzerState = yield select(s => s.matchAnalyzerState);
    const mapCanvasState = yield select(s => s.mapCanvasState);
    const selectedGame = yield select(state => state.general.selectedGame);

    const result = yield privateMutation(saveFile, {
      game: selectedGame,
      file: {
        actionType: mapCanvasState.mapType,
        matchId: matchAnalyzerState.matchId,
        filename: action.payload.fileName,
        highlights: action.payload.highlights,
        isPublic: action.payload.isPublic,
      } 
    });

    yield put({ type: SET_FILE_STATE, payload: { 
      fileId: result.data.saveFile, 
      isPublic: action.payload.isPublic, 
      fileName: action.payload.fileName 
    } });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: FILE_SAVE_SUCCESS });
    if (action.payload.callback) {
      action.payload.callback();
    }
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SAVE_FAILED });
  }
}

/**
 * Makes a request to server temporary file (file with no name - rename action)
 * 
 * @param {*} action 
 */
function* requestSaveTempFile(action) {
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    yield privateMutation(renameFile, {
      game: selectedGame,
      fileId: action.payload.fileId,
      filename: action.payload.fileName 
    });
    // Triggering success action
    yield put({ 
      type: REQUEST_FILE_SAVE_TEMP_SUCCESS, 
      payload: {
        fileName: action.payload.fileName
      }
    });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: FILE_SAVE_SUCCESS });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SAVE_FAILED });
  }
}

/**
 * Makes a request to server temporary file (file with no name - rename action)
 * 
 * @param {*} action 
 */
function* requestMakeFilePublic(action) {
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    yield privateMutation(makeFilePublic, { 
      game: selectedGame,
      fileId: action.payload.fileId,
    })

    yield put({ type: SET_FILE_STATE, payload: { 
      isPublic: true, 
    } });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SAVE_FAILED });
  }
}

/**
 * Saves highlights to sever
 */
function* requestSaveHighlights(action) {
  try {
    const selectedGame = yield select(state => state.general.selectedGame);
    const fileState = yield select(s => s.fileState);
    const fileId = fileState.fileId;

    yield privateMutation(saveHighlights, {
      game: selectedGame,
      fileId: fileId, 
      highlights: action.payload.highlights 
    });
    if (action.payload.showSuccess) {
      yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: FILE_CHANGES_SAVE_SUCCESS });  
    }
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SAVE_FAILED });
  }
}

/**
 * Subscribes user to a file so they get updates on drawings
 * 
 * @param {*} action 
 */
function* requestSubscriptionToFile(action) {
  const next = action.payload.next;
  try {
    // TODO: uncomment fileId when appsync bug will be fixed
    yield privateSubscription(subscribeToFileById, { /*fileId: fileId*/ }, next);
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SUBSCRIPTION_TO_FILE });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_FILE_HIGHLIGHTS_SAVE, requestSaveHighlights),
      takeLatest(REQUEST_FILE_SAVE_NEW, requestSaveNewFile),
      takeLatest(REQUEST_FILE_SAVE_TEMP, requestSaveTempFile),
      takeLatest(REQUEST_MAKE_FILE_PUBLIC, requestMakeFilePublic),
      takeLatest(REQUEST_SUBSCRIPTION_TO_FILE, requestSubscriptionToFile),
  ]);
}