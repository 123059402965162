import { 
  REQUEST_CHANGE_PASSWORD_PENDING,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  REQUEST_CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_CLEAN
} from './types';

const INITIAL_STATE = {
  changePasswordLoaded: true,
  changePasswordError: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On change password in progress
    case REQUEST_CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePasswordLoaded: false,
        changePasswordError: false
      };
      
    // On successfully changed password
    case REQUEST_CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_CLEAN:
      return {
        ...state,
        changePasswordLoaded: true,
        changePasswordError: false
      };

    // On change password error
    case REQUEST_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordLoaded: true,
        changePasswordError: true
      };

    default:
      return state;
  }
}

export default reducer;