export default {
  menu: {
    matchAnalyzer: 'マッチ分析',
    tournaments: 'トーナメント',
    news: 'ニュース',
    pgcPoints: 'PGC Points',
    rankings: 'Rankings', // TODO missing
    scrims: 'Scrims',
    userMatches: 'ユーザーマッチ',
    rotationAnalyzer: 'ムーブ分析',
    teamsRotations: 'チームのムーブ',
    saved: 'Saved',
    savedMatches: 'マッチの保存',
    productionOverlays: 'Production overlays',
    reportProblem: '問題を報告',
    discord: 'Discordに参加',
    twitter: 'Twitterをフォロー',
  },
  headerMenu: {
    hi: 'こんにちは',
    account: 'アカウント',
    settings: '設定',
    contact: '問い合わせ',
    logout: 'ログアウト',
    logoutError: 'Error while logging out. Please try again!',
    goPremium: 'プレミアム会員になる',
  },
  common: {
    save: '保存',
    edit: '編集',
    delete: '削除',
    cancel: 'キャンセル',
    search: '検索',
    ok: 'OK',
    showMore: 'さらに見る',
    close: '閉じる',
    loading: 'ロード中...',
    learnMore: 'さらに詳しく',
    beta: 'ベータ',
    warning: '危険',
    goTo: 'Go to',
    goToApp: 'アプリへ移動',
    login: 'ログイン',
    pleaseWait: 'Please wait...',
    success: 'Success!',
    signUp: 'サインアップ',
  },
  settings: {
    basicSettings: '基本設定',
    username: 'ユーザーネーム',
    email: 'Eメール',
    subscription: 'サブスクリプション',
    plan: 'プラン',
    validTo: '有効期限',
    freePlan: '無料',
    trialPlan: '試用する',
    premiumPlan: 'プレミアム',
    manageSubscription: 'PayPalアカウントでサブスクリプションプランを管理できます',
    pubgUsernames: 'PUBGのプレイヤー名 (only PUBG PC)',
    pubgUsername: 'PUBGのプレイヤー名',
    enterPubgUsername: 'PUBGのプレイヤー名を入力(大文字・小文字を正確に)',
    status: 'ステータス or 状態',
    pendingConfirmation: '承認待ち',
    invlid: '無効',
    active: '有効',
    password: 'パスワード',
    notifications: '通知',
    emailNotifications: 'Eメールでの通知',
    currentPassword: '現在のパスワード',
    enterCurrentPassword: '現在のパスワードを入力してください',
    newPassword: '新しいパスワード',
    enterNewPassword: '新しいパスワードを入力してください',
    passwordConfirmation: 'パスワードの確認',
    enterPasswordConfirmation: 'もう一度新しいパスワードを入力してください',
    changePassword: 'パスワードの変更',
    promoCode: '販売促進コード',
    use: '使用する',
    wrongPromoCode: 'Wrong promotional code',
    promoCodeSuccess: 'Promotional code used successfully. Page will reload',
  },
  regions: {
    allRegions: '全地域',
    global: 'グローバル',
    asia: 'アジア(AS)',
    europe: 'ヨーロッパ(EU)',
    southAmerica: '南アメリカ(SA)',
    northAmerica: '北アメリカ(NA)',
    oceania: 'オセアニア(OCE)',
    apac: 'アジア太平洋(APAC)',
  },
  tournamentSearch: {
    tournamentFilter: 'トーナメントフィルター',
    tournamentName: 'トーナメント名',
    clearFilter: 'フィルターを解除',
    missingTournament: 'トーナメントが見つかりませんか',
    recentTournaments: '最近のトーナメント',
    allTournaments: '全てのトーナメント',
    tournament: 'トーナメント',
    createTournament: 'Create a Tournament',
    noTournaments: 'No tournaments',
  },
  content: {
    title: 'ニュース',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'Write a comment',
    newCommentPlaceholder: 'Your comment',
  },
  tournament: {
    tournament: 'トーナメント',
    tournamentInfo: 'トーナメント情報',
    region: '地域',
    matches: 'マッチ',
    leaderboards: 'リーダーボード',
    prizeLeaderboards: 'Prize Money Leaderboard',
    playerStats: 'プレイヤーの成績',
    teamStats: 'Team Stats',
    selectTournament: 'トーナメントの選択',
    noMatchesFound: 'マッチが見つかりませんでした',
    noLeaderboards: 'このトーナメントではリーダーボードは利用できません',
    leaderboardsNotFound: 'リーダーボードはないか、マッチは未プレイです。後ほどお試しください',
    noPlayerStats: 'このトーナメントの成績は利用できません',
    playerStatsNotFound: '成績がないかマッチがプレイされていません。後ほどお試しください',
    tournamentPhase: 'トーナメントフェーズ',
    selectTournamentPhase: 'トーナメントフェーズを選択',
    loadingMatches: 'マッチをロード中...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'プレイヤー名で検索',
    pubgUsername: 'PUBGのプレイヤー名(大文字・小文字を正確に)',
    matchesOf: 'マッチ一覧',
    enterPubgUsername: 'PUBGのプレイヤー名を入力してください',
    usernameNotTracked: '全てのマッチを取得できないため、このプレイヤー名は追跡していません。自身で追加することができます',
    settings: '設定',
    favoriteUsers: 'お気に入り',
  },
  match: {
    rank: 'ランク',
    date: '日時',
    match: 'マッチ',
    map: 'マップ',
    type: 'タイプ',
    zone: 'ゾーン',
    analyzeStats: '分析/成績',
    mode: 'モード',
    duration: '時間',
  },
  matchDetails: {
    matchDetails: 'マッチ詳細',
    matchStats: 'マッチ成績',
    match: 'マッチ',
    map: 'マップ',
    mode: 'モード',
    duration: '時間',
  },
  matchStats: {
    rank: 'ランク',
    wins: 'WWCD',
    totalPoints: 'Total Points',
    avgTotalPoints: 'Avg Total Points',
    avgRank: 'Avg Rank',
    points: 'Placement Points',
    avgPoints: 'Avg Placement Points',
    twr: 'TWR',
    numOfMatches: 'Number of Matches',
    team: 'チーム',
    player: 'プレイヤー',
    username: 'プレイヤー',
    kills: 'キル',
    assists: 'アシスト',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: '与えたダメージ',
    avgDamageDealt: '平均ダメージ',
    damageTaken: '受けたダメージ',
    avgDamageTaken: '平均被ダメージ',
    dbnos: 'ダウン',
    revives: '蘇生',
    headshotKills: 'ヘッドショット',
    longestKill: '最長キル(m)',
    killSteals: '奪われたキル',
    killsStolenFrom: '奪ったキル',
    grenadePickup: 'Grenades Picked',
    grenadeDrop: 'Grenades Dropped',
    grenadeThrow: 'Grenades Thrown',
    grenadeDamage: 'Grenade Damage',
    molotovPickup: 'Molotovs Picked',
    molotovDrop: 'Molotovs Dropped',
    molotovThrow: 'Molotovs Thrown',
    molotovDamage: 'Molotovs Damage',
    smokebombPickup: 'Smokes Picked',
    smokebombDrop: 'Smokes Dropped',
    smokebombThrow: 'Smokes Thrown',
    flashbangPickup: 'Flashes Picked',
    flashbangDrop: 'Flashes Dropped',
    flashbangThrow: 'Flashes Thrown',
    swimDistance: '泳いだ距離(km)',
    walkDistance: '歩行距離(km)',
    rideDistance: '乗車距離(km)',
    timeSurvived: '生存時間',
    avgTimeSurvived: '平均生存時間',
    killStreaks: '連続キル',
    deathType: '死亡原因',
    heals: '回復',
    healthRecovered: 'Health Recovered',
    boosts: 'ブースト',
    vehicleDestroys: '車両破壊',
    kd: 'KD',
    killsKnocks: 'キル / ダウン',
    arDamage: '与えたダメージ (AR)', // TODO missing
    dmrDamage: '与えたダメージ (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: '与えたダメージ / 受けたダメージ',
    damageTakenFromBlueZone: '受けたダメージ (Zone)', // TODO missing
    damageTakenFromEnemy: '受けたダメージ (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'キルを奪った数 - 他のチームがダウンさせた敵をキルした数',
    stolenKillsFromExplaines: 'キルを奪われた数 - 自分がダウンさせた敵をキルされた数',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: '保存',
    undo: '元に戻す',
    clearAll: '全てクリア',
    zonesToDraw: 'Draw Zone',
    pen: 'ペン',
    playerDetails: 'プレイヤーの詳細',
    rotationDetails: 'ムーブ詳細',
    playerNames: 'プレイヤー名',
    teamNames: 'チーム名',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: '死んだプレイヤーを表示する',
    grid: 'グリッド',
    planePath: '航路',
    showPlanePaths: '航路を表示する',
    showRotations: 'ムーブを表示する',
    showCircles: 'Show Circles',
    showLandingSpots: '降下場所を表示する',
    heatmap: 'Heatmap', // TODO missing
    help: 'ヘルプ',
  },
  help: {
    help: 'ヘルプ',
    tutorial: 'チュートリアル',
    runTutorial: 'チュートリアルを実行',
    shortcuts: 'ショートカット',
    keyBinding: 'キーバインド',
    description: '説明',
    playPause: '再生/一時停止',
    stepBackward: '前に戻る (2 sec.)',
    stepForward: '先に進む (2 sec.)',
    previousSpeed: '早戻しのスピード',
    nextSpeed: '早送りのスピード',
    gird: 'グリッドを表示/隠す',
    teamNames: 'チーム名を表示/隠す',
    playerNames: 'プレイヤー名を表示/隠す',
    deadPlayers: '死んだプレイヤーを表示/隠す',
    planePath: '航路を表示/隠す',
    playerDetails: 'プレイヤー詳細を開く/閉じる',
    rotationDetails: 'ムーブ詳細を開く/閉じる',
    fullscreen: 'フルスクリーンにする/やめる',
  },
  matchAnalyzer: {
    names: '名前',
    trace: 'トレース',
    emptyInventory: 'インベントリーが空',
  },
  teamsSearch: {
    searchByTeam: 'チームで検索',
    teamNameOrInitial: 'チーム名もしくは略称',
    teams: 'チーム',
    team: 'チーム',
    noResults: '結果なし',
    oldRotationAnalyzer: '前のバージョン',
    tryNow: '今すぐ試す',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'ムーブ分析',
    selectMap: 'アナライザーへのマップを選択',
    match: 'マッチ',
    hideAll: 'すべて隠す',
    show: '表示',
    rank: 'ランク',
    kills: 'キル',
    damageDealt: '与えたダメージ',
    damageTaken: '受けたダメージ',
    zoneDirection: 'ゾーン方向',
    phases: 'フェーズ',
    walking: '歩行',
    driving: '運転',
    endingZoneArea: 'ENDING CIRCLE AREA',
    selectZoneArea: 'ゾーンエリアの選択',
    selectTeam: '最低1チームを追加',
    zone: 'ゾーン',
    reset: 'リセット',
    resetWarning: 'この操作は追加されたムーブと選択されたゾーンを削除します？',
    teams: 'チーム',
    add: '追加',
    noTeams: 'チームが選択されていません',
  },
  saved: {
    noPremium: 'この機能はプレミアムユーザー限定です。過去に保存したマッチにアクセスすることはできますが、新しいマッチを保存することはできません',
    savedMatches: '保存されたマッチ',
    rename: 'リネーム',
    delete: '削除',
    renameFile: 'ファイル名の変更',
    newFileName: '新しいファイル名',
    fileDelete: 'ファイル削除',
    fileDeleteWarning: '本当に削除して良いですか？',
    matchAnalysis: 'マッチ分析',
    modalRenameTitle: 'マッチ名の変更',
    modalRenamePlaceholder: '新しいマッチ名',
    modalDeleteTitle: 'マッチ削除',
    modalDeletePlaceholder: 'このマッチを削除しても良いですか？',
  },
  connect: {
    title: '遅れないで！',
    discordConnectText: 'Discordを通じて繋がろう！',
    connectMe: '繋がる',
  },
  features: {
    title: 'あなたに何の特があるのでしょうか？',
    feature1Title: 'Tournament Leaderboards',
    feature1Text: 'Keep up with tournaments from all around the world',
    feature2Title: 'Players\' Statistics',
    feature2Text: 'Compare players and their skill in different categories',
    feature3Title: 'ムーブ分析',
    feature3Text: 'マッチを分析して遠隔作業をより強化しよう',
    feature4Title: 'ムーブ分析',
    feature4Text: '敵チームのムーブを知って、攻める戦略を準備しよう',
  },
  games: {
    title: 'どちらのゲームをプレイしていますか？',
    availableNow: 'available now',
    comingSoon: 'coming soon',
  },
  partners: {
    title: 'パートナー',
  },
  pricing: {
    title: '価格',
    advertisement: '広告',
    matchAnalyzer: 'マッチ分析',
    rotationAnalyzer: 'ムーブ分析',
    drawing: '書き込み',
    collaborativeDrawing: '共同での書き込み',
    savingMatches: 'マッチの保存',
    premium: 'プレミアム',
    freePeriod: '14日間無料',
    paidMonthly: '月額課金',
    paidQuarterly: '四半期払い',
    noCardRequired: 'クレジットカード不要',
    planContact1: 'あなたのチームでカスタムプランが必要なら',
    planContact2: 'サーバか',
    planContact3: 'までご連絡ください',
    free: '無料',
  },
  payment: {
    title: '価格',
    saved: '保存しました',
    ads: '広告を削除',
    analyzerMapReplay: 'マップリプレイを分析',
    seeTeamsRotations: 'チームのムーブを見る',
    workCollaboratively: '共同作業をする',
    shareDrawingInRealTime: '描いたものをリアルタイムで共有',
    drawing: '描く',
    markImportantMoments: '重要な瞬間をマークする',
    saveitForLater: '保存する',
    recommended: 'おすすめ',
    billedQuarterly: '四半期払い',
    billedMonthly: '月払い',
    selectPlan: 'プランを選択',
    billedNow: '今すぐ支払う',
    alreadyPremium: '既にプレミアムメンバーです',
    expiresInDays: '無料トライアル期間は14日後に終了します',
    learnMore: 'さらに詳しく',
  },
  welcome: {
    title1: 'Esports',
    title2: 'キャリアを始めよう',
    subtitle: '勝者のための分析ツール',
    register: '登録は無料',
    learnMore: 'もっと知りたい',
  },
  forgotPassword: {
    emailSent: 'Email Sent!',
    emailSentMessage: 'Email with reset password link was sent to you.',
    forgotPassword: 'パスワードを忘れた',
    inputUsername: 'ユーザー名を入力',
    sentResetLink: 'Send Reset Link',
  },
  login: {
    signUp: 'サインアップ',
    dontHaveAccountLong: 'アカウントを持っていませんか？何を待っているんでしょう？登録をして、素晴らしいツールで技術を磨きましょう！',
    dontHaveAccount: 'アカウントを持っていませんか？',
    registerNow: '今すぐ登録',
    login: 'ログイン',
    resetPassword: 'パスワードを再設定する',
    reset: 'リセット',
    signInAccount: 'アカウントでログインする',
    passwordResetNeeded: 'パスワードをリセットする必要があります',
    username: 'ユーザーネーム',
    password: 'パスワード',
    passwordConfirmation: 'Password Confirmation',
    forgotPassword: 'パスワードを忘れた？',
    resetPasswordRequired: 'パスワードをリセットする必要があります',
    wrongCredentials: '無効なユーザー名またはパスワード',
    passwordsDontMatch: 'パスワードが一致していません',
    passwordToShort: 'Passwords must include minimum 8 characters, at least one letter and one number',
  },
  register: {
    register: '登録',
    thankYou: 'ありがとうございました！',
    thankYouLong: 'Thank you for creating account! Check your email to finish registration.',
    username: 'ユーザーネーム',
    email: 'Eメール',
    password: 'パスワード',
    repeatPassword: 'パスワードを再度入力してください',
    subscribeToNewsletter: 'ニュースレターを購読する',
    readAndUnderstand: '',
    termsOfUse: '使用条件を読み、同意しました',
    createAccount: 'アカウント作成',
    alreadyRegistered: '登録済みですか？',
    invalidUsername: '無効なユーザー名',
    invalidEmail: 'Invalid email',
    invalidPassword: 'Passwords must include minimum 8 characters, at least one letter and one number',
    passwordsDontMatch: 'パスワードが一致していません',
    termsRequired: 'You must agree to Terms of use',
  },
  resetPassword: {
    resetPassword: 'パスワードを再設定する',
    selectNewPassword: 'Select new password',
    password: 'パスワード',
    passwordConfirmation: 'Password Confirmation',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: 'Verification failed! Your link is broken, expired or already been verified.',
    resendEmail: 'メールを再送',
    verifyingEmail: 'Verifying your email...',
    verificationSuccess: '確認が完了しました！ログインが可能です！',
  }
}