import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {  
  REQUEST_HEAT_MAP,
  REQUEST_HEAT_MAP_PENDING,
  REQUEST_HEAT_MAP_SUCCESS,
  REQUEST_HEAT_MAP_ERROR,
  CALCULATE_NEXT_CIRCLE,
} from './types';
import { PREPARE_NEW_CANVAS_STATE, REQUEST_LOAD_MAP_CANVAS_HEATMAP, REQUEST_LOAD_MAP_CANVAS_MAP, SET_MAP_CANVAS_STATE } from '../mapCanvasState/types';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';
import { CIRCLE_ANALYSIS } from '../../util/Constants';
import zoneSizes from '../../assets/canvas/zoneSizes';

/**
 * Makes a request to server to get heatmap data
 * 
 * @param {*} action 
 */
function* requestHeatMap(action) {
  yield put({ type: REQUEST_HEAT_MAP_PENDING });
  yield put({ type: PREPARE_NEW_CANVAS_STATE });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { mapType: CIRCLE_ANALYSIS } });

    // Use this when generating new hetmap image
    // yield processDataForHeatmapExport(action, selectedGame);
    yield getDataForHeatmapPrediction(action, selectedGame);
    
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_HEAT_MAP_ERROR });
  }
}

/**
 * Downloads and process data for heatmap image export
 * 
 * @param {*} action 
 * @param {*} selectedGame 
 */
// eslint-disable-next-line no-unused-vars
function* processDataForHeatmapExport(action, selectedGame) {
  const response = yield fetch(`https://twire-assets.s3-eu-west-1.amazonaws.com/${selectedGame}/heatmaps/${action.payload}.json.gz`);
  if (response.ok) {
    yield put({ type: REQUEST_LOAD_MAP_CANVAS_MAP, payload: action.payload });
    yield put({ type: REQUEST_LOAD_MAP_CANVAS_HEATMAP, payload: action.payload });
    
    const heatmapData = JSON.parse(yield response.text());
    let heatmapMaxValue = 0;
    heatmapData.forEach(p => {
      if (p.value > heatmapMaxValue) heatmapMaxValue = p.value;
      p.x = p.x * 8; 
      p.y = p.y * 8; 
    });
    
    yield put({ type: REQUEST_HEAT_MAP_SUCCESS, payload: {
      heatmapData: heatmapData,
      heatmapMaxValue: heatmapMaxValue,
    }});
  } else {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Downloads and process data for heatmap image export
 * 
 * @param {*} action 
 * @param {*} selectedGame 
 */
function* getDataForHeatmapPrediction(action, selectedGame) {
  const response = yield fetch(`https://twire-assets.s3-eu-west-1.amazonaws.com/${selectedGame}/heatmaps/${action.payload}_Grid.json.gz`);
  if (response.ok) {
    yield put({ type: REQUEST_LOAD_MAP_CANVAS_MAP, payload: action.payload });
    yield put({ type: REQUEST_LOAD_MAP_CANVAS_HEATMAP, payload: action.payload });
    
    const heatmapPredictionData = JSON.parse(yield response.text());
    
    yield put({ type: REQUEST_HEAT_MAP_SUCCESS, payload: {
      heatmapPredictionData: heatmapPredictionData,
    }});
  } else {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Calculates next circle position
 * 
 * @param {*} action 
 */
function* calculateNextCircle(action) {
  try {
    const { radius, positionX, positionY } = action.payload;
    const { mapName, zonesToDraw } = yield select(state => state.mapCanvasState);
    const { heatmapPredictionData } = yield select(state => state.circleAnalyzer);
    const zoneIndex = zoneSizes[mapName].indexOf(radius);
    const radiusNext = zoneSizes[mapName][zoneIndex + 1];
    const { x, y } = calculateNextCircleCenter(Math.round(radius), positionX, positionY, positionX, positionY, heatmapPredictionData);
    const { x: xFixed, y: yFixed } = fitZoneIntoPreviousOne(radius, positionX, positionY, radiusNext, x, y);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { 
      zonesToDraw: [{ 
        prediction: true, 
        radius: radiusNext, 
        x: xFixed, 
        y: yFixed, 
      }, ...zonesToDraw]}
    });
  } catch (err) {
    console.log(err)
  }
}

/**
 * Calculates the center of the most possible next circle
 * 
 * @param {*} radius 
 * @param {*} centerX 
 * @param {*} centerY 
 * @param {*} positionX 
 * @param {*} positionY 
 * @param {*} heatmapPredictionData 
 * @returns 
 */
function calculateNextCircleCenter(radius, centerX, centerY, positionX, positionY, heatmapPredictionData) {
  const positions = [];
  const numberOfSectionsPerAxis = 20;
  const d = 2 * radius;
  for (let rx = 0; rx < numberOfSectionsPerAxis; rx+=0.5) {
    for (let ry = 0; ry < numberOfSectionsPerAxis; ry+=0.5) {
      positions.push(calculateRangeSum(
        centerX,
        centerY,
        positionX - radius + Math.round(rx * d/numberOfSectionsPerAxis),
        positionX - radius + Math.round((rx + 1) * d/numberOfSectionsPerAxis),
        positionY - radius + Math.round(ry * d/numberOfSectionsPerAxis),
        positionY - radius + Math.round((ry + 1) * d/numberOfSectionsPerAxis),
        radius,
        heatmapPredictionData,
      ));

    }
  }
  const bestMatch = positions.sort((a, b) => a.sum > b.sum ? -1 : 1)[0];
  return bestMatch.sum === 0 ? { x: centerX, y: centerY } : { x: bestMatch.x, y: bestMatch.y };
}

/**
 * Calculates the weight (possibility) of the range on heatmap
 * 
 * @param {*} centerX 
 * @param {*} centerY 
 * @param {*} startX 
 * @param {*} stopX 
 * @param {*} startY 
 * @param {*} stopY 
 * @param {*} radius 
 * @param {*} heatmapPredictionData 
 * @returns 
 */
function calculateRangeSum(centerX, centerY, startX, stopX, startY, stopY, radius, heatmapPredictionData) {
  let sum = 0;
  for (let y = Math.max(Math.round(startY/800), 0); y <= Math.round(stopY/800) && y < heatmapPredictionData.length; y++) {
    for (let x = Math.max(Math.round(startX/800), 0); x <= Math.round(stopX/800) && x < heatmapPredictionData[y].length; x++) {
      if (radius >= Math.sqrt((x*800 - centerX) * (x*800 - centerX) + (y*800 - centerY) * (y*800 - centerY))) {
        sum += heatmapPredictionData[y][x];
      }
    }
  }
  return { sum: sum, x: Math.round(startX + ((stopX - startX)/2)), y: Math.round(startY + ((stopY - startY)/2)) };
}

/**
 * This function moves the next zone into the previous one
 * if it falls out
 * 
 * @param {*} r1 
 * @param {*} x1 
 * @param {*} y1 
 * @param {*} r2 
 * @param {*} x2 
 * @param {*} y2 
 * @returns 
 */
function fitZoneIntoPreviousOne(r1, x1, y1, r2, x2, y2) {
  const deltaR = r1 - 2 * r2;
  const currentDistance = Math.sqrt((x1-x2) * (x1-x2) + (y1-y2) * (y1-y2));
  if (deltaR + r2 < currentDistance) {
    const ratio = (deltaR + r2) / currentDistance;
    const x = ratio * x2 + (1 - ratio) * x1;
    const y = ratio * y2 + (1 - ratio) * y1;
    return { x: x, y: y };
  }
  return { x: x2, y: y2 };
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_HEAT_MAP, requestHeatMap),
      takeLatest(CALCULATE_NEXT_CIRCLE, calculateNextCircle),
  ]);
}