import { 
  REQUEST_TEAMS_PENDING,
  REQUEST_TEAMS_SUCCESS,
  REQUEST_TEAMS_ERROR,
} from './types';

const INITIAL_STATE = {
  teams: [],
  loaded: false,
  error: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On teams loading
    case REQUEST_TEAMS_PENDING:
      return {
        ...state,
        loaded: false,
        error: false
      };
      
    // On successfully loaded teams
    case REQUEST_TEAMS_SUCCESS:
      return {
        ...state,
        loaded: true,
        error: false,
        teams: action.payload
      };

    // On teams fetch error
    case REQUEST_TEAMS_ERROR:
      return {
        ...state,
        loaded: true,
        error: true
      };

    default:
      return state;
  }
}

export default reducer;