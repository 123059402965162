export default {
  menu: {
    matchAnalyzer: 'Analisador de Partida',
    tournaments: 'Competições',
    news: 'Notícias',
    pgcPoints: 'PGC Pontos',
    rankings: 'Rankings', // TODO missing
    scrims: 'Scrims',
    userMatches: 'Partidas do Utilizador',
    rotationAnalyzer: 'Analisador de Rotações',
    teamsRotations: 'Rotações das Equipes',
    saved: 'Guardado',
    savedMatches: 'Partidas Guardadas',
    productionOverlays: 'Overlays de produção',
    reportProblem: 'Reportar um problema',
    discord: 'Entre no Discord',
    twitter: 'Siga-nos no Twitter',
  },
  headerMenu: {
    hi: 'Olá',
    account: 'Conta',
    settings: 'Definições',
    contact: 'Entre em contacto',
    logout: 'Logout',
    logoutError: 'Erro no logout. Por favor tente de novo!',
    goPremium: 'Obter Premium',
  },
  common: {
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Apagar',
    cancel: 'Cancelar',
    search: 'Procurar',
    ok: 'OK',
    showMore: 'Mostrar mais',
    close: 'fechar',
    loading: 'Loading...',
    learnMore: 'Saber mais',
    beta: 'Beta',
    warning: 'Aviso',
    goTo: 'Ir para',
    goToApp: 'Ir Para App',
    login: 'Login',
    pleaseWait: 'Por favor aguarde...',
    success: 'Successo!',
    signUp: 'Inscrever',
  },
  settings: {
    basicSettings: 'Definições básicas',
    username: 'Utilizador',
    email: 'Email',
    subscription: 'Subscrição',
    plan: 'Plano',
    validTo: 'Válido para',
    freePlan: 'Gratuito',
    trialPlan: 'Trial',
    premiumPlan: 'Premium',
    manageSubscription: 'Pode gerir o seu plano de subscrição na sua conta PayPal.',
    pubgUsernames: 'Nome de utilizador PUBG',
    pubgUsername: 'Nome de utilizador PUBG',
    enterPubgUsername: 'Insira o nome de utilizador PUBG (sensível a maiúsculas e minúsculas)',
    status: 'Estado',
    pendingConfirmation: 'A aguardar confirmação',
    invlid: 'Inválido',
    active: 'Activo',
    password: 'Senha',
    notifications: 'Notificações',
    emailNotifications: 'Notificações de email',
    currentPassword: 'Password atual',
    enterCurrentPassword: 'Por favor insira a sua senha atual',
    newPassword: 'Nova senha',
    enterNewPassword: 'Por favor insira a sua nova senha',
    passwordConfirmation: 'Confirmar senha',
    enterPasswordConfirmation: 'Confirme a sua senha',
    changePassword: 'Mudar Senha',
    promoCode: 'Código promocional',
    use: 'usar',
    wrongPromoCode: 'Código promocional errado',
    promoCodeSuccess: 'Código promocional usado com sucesso. A págira irá atualizar',
  },
  regions: {
    allRegions: 'Todas as regiões',
    global: 'Global',
    asia: 'Ásia',
    europe: 'Europa',
    southAmerica: 'América do Sul',
    northAmerica: 'América do Norte',
    oceania: 'Oceânia',
    apac: 'APAC',
  },
  tournamentSearch: {
    tournamentFilter: 'Filtro de Competição',
    tournamentName: 'Nome da Competição',
    clearFilter: 'Limpar filtros',
    missingTournament: 'Competição em falta?',
    recentTournaments: 'Competições recentes',
    allTournaments: 'Todas as competições',
    tournament: 'Competição',
    createTournament: 'Criar competição',
    noTournaments: 'Sem competições',
  },
  content: {
    title: 'Notícias',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'Deixe um comentário',
    newCommentPlaceholder: 'O seu comentário',
  },
  tournament: {
    tournament: 'Competição',
    tournamentInfo: 'Informação da Competição',
    region: 'Região',
    matches: 'Partidas',
    leaderboards: 'Classificação',
    prizeLeaderboards: 'Classificação de Ganhos',
    playerStats: 'Estatísticas de Jogadores',
    teamStats: 'Estatísticas da Equipe',
    selectTournament: 'Seleccionar competição',
    noMatchesFound: 'Partidas não encontradas',
    noLeaderboards: 'Não há Classificação disponível para esta competição',
    leaderboardsNotFound: 'Classificação não existe ou as partidas ainda não foram jogadas. Tente mais tarde',
    noPlayerStats: 'Estatísticas indisponíveis para esta competição',
    playerStatsNotFound: 'Estatísticas não existem ou as partidas ainda não foram jogadas. Tente mais tarde',
    tournamentPhase: 'Fase da competição',
    selectTournamentPhase: 'Selecione a fase da competição',
    loadingMatches: 'Carregando partidas...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'Procurar por nome de utilizador',
    pubgUsername: 'Nome de Utilizador PUBG (sensível a maiúsculas e minúsculas)',
    matchesOf: 'Partidas de',
    enterPubgUsername: 'Insira o nome de utilizador PUBG',
    usernameNotTracked: 'Não estamos rastreando este nome de utilizador por isso podemos não ter todas as partidas. Pode adicionar o nome de utilizador',
    settings: 'configurações',
    favoriteUsers: 'Favoritos',
  },
  match: {
    rank: 'Posição',
    date: 'Data',
    match: 'Partida',
    map: 'Mapa',
    type: 'Tipo',
    zone: 'Zona',
    analyzeStats: 'Analisar/Estatísticas',
    mode: 'Modo',
    duration: 'Duração',
  },
  matchDetails: {
    matchDetails: 'Detalhes da partida',
    matchStats: 'Estatísticas da partida',
    match: 'Partida',
    map: 'Mapa',
    mode: 'Modo',
    duration: 'Duração',
  },
  matchStats: {
    rank: 'Posição',
    wins: 'WWCD',
    totalPoints: 'Pontos',
    avgTotalPoints: 'Média de Pontos',
    avgRank: 'Classificação média',
    points: 'Pontos de Classificação',
    avgPoints: 'Média de Pontos de Classificação',
    twr: 'TWR',
    numOfMatches: 'Número de partidas',
    team: 'Equipe',
    player: 'Jogador',
    username: 'Jogador',
    kills: 'Abates',
    avgKills: 'Média de Abates',
    assists: 'Assistências',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Dano Causado',
    avgDamageDealt: 'Dano médio por partida',
    damageTaken: 'Danos tomados',
    avgDamageTaken: 'Dano tomado',
    dbnos: 'Nocautes',
    revives: 'Reanimações',
    headshotKills: 'Abates de Headshot',
    longestKill: 'Abate de maior distância (m)',
    killSteals: 'Abates roubados',
    killsStolenFrom: 'Abates roubados do jogador',
    grenadePickup: 'Granadas coletadas',
    grenadeDrop: 'Granadas dropadas',
    grenadeThrow: 'Granadas lançadas',
    grenadeDamage: 'Dano de Granada',
    molotovPickup: 'Molotovs coletados',
    molotovDrop: 'Molotovs largados',
    molotovThrow: 'Molotovs lançados',
    molotovDamage: 'Danos de Molotov',
    smokebombPickup: 'Smokes coletadas',
    smokebombDrop: 'Smokes largadas',
    smokebombThrow: 'Smokes lançadas',
    flashbangPickup: 'Flashes coletadas',
    flashbangDrop: 'Flashes Dropped'/'Flashes largados',
    flashbangThrow: 'Flashes lançadas',
    swimDistance: 'Distância nadada (km)',
    walkDistance: 'Distância caminhada (km)',
    rideDistance: 'Distância viajada (km)',
    timeSurvived: 'Sobreviveu',
    avgTimeSurvived: 'Tempo médio vivo',
    deathType: 'Tipo de morte',
    heals: 'Curativos',
    healthRecovered: 'Vida recuperada',
    boosts: 'Consumíveis',
    vehicleDestroys: 'Veículos destruídos',
    kd: 'KD',
    killsKnocks: 'Abates/Nocautes',
    arDamage: 'Dano Causado (AR)', // TODO missing
    dmrDamage: 'Dano Causado (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'Dano Causado/Danos tomados',
    damageTakenFromBlueZone: 'Danos tomados (Zone)', // TODO missing
    damageTakenFromEnemy: 'Danos tomados (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'Abates Roubadas - quando um jogador elimina alguém que foi nocaute por outra equipe',
    stolenKillsFromExplaines: 'Abates roubados do jogador - quando os jogodores dão nocaute em alguém que é morto por um membro de outra equipe',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: 'Guardar',
    undo: 'Retroceder',
    clearAll: 'Limpar tudo',
    zonesToDraw: 'Desenhar Zona',
    pen: 'Caneta',
    playerDetails: 'Detalhes do Jogador',
    rotationDetails: 'Detalhes das Rotações',
    playerNames: 'Nome dos Jogadores',
    teamNames: 'Nome das Equipas',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: 'Mostrar jogadores abatidos',
    grid: 'Grade',
    planePath: 'Trajeto do Avião',
    showPlanePaths: 'Mostrar Trajetos do Avião',
    showRotations: 'Mostrar Rotações',
    showCircles: 'Mostrar Círculos',
    showLandingSpots: 'Mostrar Zonas de Aterragem',
    heatmap: 'Heatmap', // TODO missing
    help: 'Ajuda',
  },
  help: {
    help: 'Ajuda',
    tutorial: 'Tutorial',
    runTutorial: 'Correr Tutorial',
    shortcuts: 'Atalhos',
    keyBinding: 'Key Binding',
    description: 'Descrição',
    playPause: 'play/pause',
    stepBackward: 'retroceder (2 sec.)',
    stepForward: 'avançar (2 sec.)',
    previousSpeed: 'velocidade anterior',
    nextSpeed: 'próxima velocidade',
    gird: 'mostrar/esconder grade',
    teamNames: 'mostrar/esconder nomes das equipes',
    playerNames: 'mostrar/esconder nomes dos jogadores',
    deadPlayers: 'mostrar/esconder jogadores abatidos',
    planePath: 'mostrar/esconder trajeto do avião',
    playerDetails: 'abrir/fechar detalhes do jogador',
    rotationDetails: 'abrir/fechar detalhes de rotação',
    fullscreen: 'ligar/desligar a tela cheia',
  },
  matchAnalyzer: {
    names: 'Nomes',
    trace: 'Trace',
    emptyInventory: 'Inventário vazio',
  },
  teamsSearch: {
    searchByTeam: 'Procurar por equipe',
    teamNameOrInitial: 'Nome da equipe ou inicial',
    teams: 'Equipes',
    team: 'Equipe',
    noResults: 'Sem resultados',
    oldRotationAnalyzer: 'Antigo Analisador de Rotações',
    tryNow: 'Experimente agora',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Analisador de Rotações',
    selectMap: 'Escolher Mapa para Analisar',
    match: 'Partida',
    hideAll: 'Esconder TUDO',
    show: 'Mostrar',
    rank: 'Rank',
    kills: 'Abates',
    damageDealt: 'Dano causado',
    damageTaken: 'Dano tomado',
    zoneDirection: 'Direção da zona',
    phases: 'Fases',
    walking: 'Caminhando',
    driving: 'Dirigindo',
    endingZoneArea: 'ÁREA DO CÍRCULO FINAL',
    selectZoneArea: 'Selecione a área da zona',
    selectTeam: 'Adicionar pelo menos uma equipe',
    zone: 'Zona',
    reset: 'Redefinir',
    resetWarning: 'Esta ação removerá todas as rotações que foram adicionadas e redefinirá a zona selecionada?',
    teams: 'Equipes',
    add: 'Adicionar',
    noTeams: 'Equipas não selecionadas',
  },
  saved: {
    noPremium: 'This feature is for premium users only. You are still able to access your old saved matches but you cannot save new ones.',
    savedMatches: 'Saved MatchesEsta ferramenta é apenas para utilizadores premium. Você ainda pode aceder aos seus antigos jogos guardados, mas não pode guardar os novos',
    salvasMatches: 'Partidas Guardadas',
    rename: 'Renomear',
    delete: 'Apagar',
    renameFile: 'Renomear ficheiro',
    newFileName: 'Novo nome de ficheiro',
    fileDelete: 'Eliminar ficheiro',
    fileDeleteWarning: 'Você tem certeza de que quer apagar este arquivo?',
    matchAnalysis: 'Análise de partida',
    modalRenameTitle: 'Renomear partida',
    modalRenamePlaceholder: 'Novo nome da partida',
    modalDeleteTitle: 'Eliminar Partida',
    modalDeletePlaceholder: 'Você tem certeza de que quer apagar esta partida?',
  },
  connect: {
    title: 'Acompanhe-nos!',
    discordConnectText: 'Vamos nos conectar através do Discord!',
    connectMe: 'Conecte-me!',
  },
  features: {
    title: 'O que tem a ganhar?',
    feature1Title: 'Classificações das Competições',
    feature1Text: 'Acompanhar competições de todo o mundo',
    feature2Title: 'Jogadores\' Estatísticas',
    feature2Text: 'Compare os jogadores e suas habilidades em diferentes categorias',
    feature3Title: 'Analisador de partida',
    feature3Text: 'Analise suas partidas e energize seu trabalho remoto de forma colaborativa',
    feature4Title: 'Analisador de Rotações',
    feature4Text: 'Conheça a rotação de seus oponentes e prepare sua estratégia de ataque',
  },
  games: {
    title: 'Que jogos você joga?',
    availableNow: 'disponível agora',
    comingSoon: 'em breve',
  },
  partners: {
    title: 'Parceiros',
  },
  pricing: {
    title: 'Preços',
    advertisement: 'Propaganda',
    matchAnalyzer: 'Analisador de Partidas',
    rotationAnalyzer: 'Analisador de Rotações',
    drawing: 'Desenhar',
    collaborativeDrawing: 'Desenho colaborativo',
    savingMatches: 'Guardando partidas',
    premium: 'Premium',
    freePeriod: '14 dias de período experimental',
    paidMonthly: 'Pago mensalmente',
    paidQuarterly: 'Pago trimestralmente',
    noCardRequired: 'Não é necessário cartão',
    planContact1: 'Se você ou sua equipe precisam de um plano personalizado, entre em contato connosco no nosso',
    planContact2: 'ou via',
    planContact3: '',
    free: 'Gratuito',
  },
  payment: {
    title: 'Preços',
    saved: 'Guardado',
    ads: 'Remover propagandas',
    analyzerMapReplay: 'Analisar repetições de mapas',
    seeTeamsRotations: 'Ver equipes\' rotações',
    workCollaboratively: 'Trabalhar de forma colaborativa',
    shareDrawingInRealTime: 'Compartilhar desenho em tempo real',
    drawing: 'Desenhar',
    markImportantMoments: 'Marcar momentos importantes',
    saveitForLater: 'Guardar para mais tarde',
    recommended: 'Recomendado',
    billedQuarterly: 'Cobrança trimestral',
    billedMonthly: 'Cobrança mensal',
    selectPlan: 'Selecionar Plano',
    billedNow: 'Cobrança agora',
    alreadyPremium: 'Você já é um membro premium',
    expiresInDays: 'O período experimental termina em 14 dias',
    learnMore: 'Saber mais',
  },
  welcome: {
    title1: 'Comece a sua',
    title2: 'CARREIRA NOS ESPORTS',
    subtitle: 'Ferramentas analíticas para campeões',
    register: 'Registrar gratuitamente',
    learnMore: 'Eu quero saber mais',
  },
  forgotPassword: {
    emailSent: 'Email Enviado!',
    emailSentMessage: 'Email com link para redefinir a senha foi enviado para você.',
    forgotPassword: 'Esqueceu senha',
    inputUsername: 'Coloque seu nome de utilizador',
    sentResetLink: 'Enviar link de redefinição',
  },
  login: {
    signUp: 'Sign up',
    dontHaveAccountLong: 'Ainda não tem uma conta? De que você está esperando!? Registre-se e comece a melhorar suas habilidades com nossas ferramentas incríveis!',
    dontHaveAccount: 'Ainda não tem uma conta?',
    registerNow: 'Registrar agora!',
    login: 'Entrar',
    resetPassword: 'Redefinir senha',
    reset: 'Redefinir',
    signInAccount: 'Entrar na sua conta',
    passwordResetNeeded: 'Você precisa redefinir sua senha',
    username: 'Nome de utilizador',
    password: 'Senha',
    passwordConfirmation: 'Confirmação de senha',
    forgotPassword: 'Esqueceu a senha?',
    resetPasswordRequired: 'Você precisa redefinir sua senha',
    wrongCredentials: 'Senha ou nome de utilizador inválidos',
    passwordsDontMatch: 'Senhas não correspondem',
    passwordToShort: 'As senhas devem incluir no mínimo 8 caracteres, pelo menos uma letra e um número',
  },
  register: {
    register: 'Registrar',
    thankYou: 'Obrigado!',
    thankYouLong: 'Obrigado por criar a sua conta! Verifique o seu email para completar o registro.',
    username: 'Utilizador',
    email: 'Email',
    password: 'Senha',
    repeatPassword: 'Repetir senha',
    subscribeToNewsletter: 'Subscreva a nossa newsletter',
    readAndUnderstand: 'Eu li e compreendi',
    termsOfUse: 'Termos de utilização',
    createAccount: 'Criar conta',
    alreadyRegistered: 'Já está registrado?',
    invalidUsername: 'Nome de utilizador inválido',
    invalidEmail: 'Email inválido',
    invalidPassword: 'As senhas devem incluir no mínimo 8 caracteres, pelo menos uma letra e um número',
    passwordsDontMatch: 'Senhas não correspondem',
    termsRequired: 'Você tem que concordar com os Termos de Utilização',
  },
  resetPassword: {
    resetPassword: 'Redefinir senha',
    selectNewPassword: 'Selecione nova senha',
    password: 'senha',
    passwordConfirmation: 'Confirmação de senha',
  },
  verification: {
    oops: 'Ops..',
    verificationFailed: 'Verificação falhada! O seu link está quebrado, expirado ou já foi verificado.',
    resendEmail: 'Reenviar email',
    verifyingEmail: 'Verificando seu email...',
    verificationSuccess: 'Verificação completa! Pode entrar!',
  }
}