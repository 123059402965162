// Types of action for retrieving tournament from the server
export const REQUEST_TOURNAMENT = 'REQUEST_TOURNAMENT';
export const REQUEST_TOURNAMENT_PENDING = 'REQUEST_TOURNAMENT_PENDING';
export const REQUEST_TOURNAMENT_SUCCESS = 'REQUEST_TOURNAMENT_SUCCESS';
export const REQUEST_TOURNAMENT_ERROR = 'REQUEST_TOURNAMENT_ERROR';
// Types of action for retrieving tournament's matches from the server
export const REQUEST_TOURNAMENT_MATCHES = 'REQUEST_TOURNAMENT_MATCHES';
export const REQUEST_TOURNAMENT_MATCHES_PENDING = 'REQUEST_TOURNAMENT_MATCHES_PENDING';
export const REQUEST_TOURNAMENT_MATCHES_SUCCESS = 'REQUEST_TOURNAMENT_MATCHES_SUCCESS';
export const REQUEST_TOURNAMENT_MATCHES_ERROR = 'REQUEST_TOURNAMENT_MATCHES_ERROR';
export const REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING = 'REQUEST_TOURNAMENT_MATCHES_ADDITIONAL_PENDING';
// Types of action for clearing matches
export const CLEAR_MATCHES = 'CLEAR_MATCHES';
// Types of action for clearing tournament state
export const CLEAR_TOURNAMENT_STATE = 'CLEAR_TOURNAMENT_STATE';