import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {  
  REQUEST_VERIFICATION,
  REQUEST_VERIFICATION_PENDING,
  REQUEST_VERIFICATION_SUCCESS,
  REQUEST_VERIFICATION_ERROR,
  REQUEST_VERIFICATION_RESEND
} from './types';
import { ERROR_VERIFICATION_FAILED, ERROR_VERIFICATION_RESEND_FAILED } from '../../util/ErrorMessages';
import { VERIFICATION_RESEND_SUCCESS } from '../../util/Messages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestVerification(action) {
  yield put({ type: REQUEST_VERIFICATION_PENDING });

  try {
    // Calling the server
    yield call([Auth, 'confirmSignUp'], action.payload.username, action.payload.code);
    // Triggering success action
    yield put({ type: REQUEST_VERIFICATION_SUCCESS });
  } catch (err) {
    yield call(toast.error, ERROR_VERIFICATION_FAILED);
    yield put({ type: REQUEST_VERIFICATION_ERROR });
  }
}

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestVerificationResend(action) {
  try {
    // Calling the server
    yield call([Auth, 'resendSignUp'], action.payload);
    // Triggering success action
    yield call(toast.success, VERIFICATION_RESEND_SUCCESS);
  } catch (err) {
    const message = err.message ? err.message : ERROR_VERIFICATION_RESEND_FAILED;
    yield call(toast.error, message);
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_VERIFICATION, requestVerification),
      takeLatest(REQUEST_VERIFICATION_RESEND, requestVerificationResend)
  ]);
}