import { 
  REQUEST_HEAT_MAP_PENDING,
  REQUEST_HEAT_MAP_SUCCESS,
  REQUEST_HEAT_MAP_ERROR,
  SET_CIRCLE_ANALYZER_STATE,
} from './types';

const INITIAL_STATE = {
  heatmapLoaded: false,
  heatmapError: false,
  heatmapData: [],
  heatmapMaxValue: 0,
  heatmapPredictionData: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On sircle analyzer state change
    case SET_CIRCLE_ANALYZER_STATE:
      return {
        ...state,
        ...action.payload
      };
    // On heatmap loading
    case REQUEST_HEAT_MAP_PENDING:
      return {
        ...state,
        heatmapLoaded: false,
        heatmapError: false,
        heatmapData: [],
        heatmapMaxValue: 0,
        showHeatmap: true,
      };
      
    // On successfully loaded heatmap data
    case REQUEST_HEAT_MAP_SUCCESS:
      return {
        ...state,
        heatmapLoaded: true,
        heatmapError: false,
        ...action.payload,
      };

    // On heatmap data fetch error
    case REQUEST_HEAT_MAP_ERROR:
      return {
        ...state,
        matchLoaded: true,
        matchError: true,
        heatmapData: [],
        heatmapMaxValue: 0,
      };

    default:
      return state;
  }
}

export default reducer;