import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_PENDING,
  REQUEST_CHANGE_PASSWORD_SUCCESS,
  REQUEST_CHANGE_PASSWORD_ERROR
} from './types';
import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from '../notification/types';
import { ERROR_CHANGE_PASSWORD_FALIED } from '../../util/ErrorMessages';
import { CHANGE_PASSWORD_SUCCESS } from '../../util/Messages';

/**
 * Makes a request to server to change password
 * 
 * @param {*} action 
 */
function* requestChangePassword(action) {
  yield put({ type: REQUEST_CHANGE_PASSWORD_PENDING });

  const user = yield Auth.currentAuthenticatedUser();

  try {
    // Calling the server
    yield call([Auth, 'changePassword'], user, action.payload.password, action.payload.newPassword);
    // Triggering success action
    yield put({ type: REQUEST_CHANGE_PASSWORD_SUCCESS });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: CHANGE_PASSWORD_SUCCESS });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_CHANGE_PASSWORD_FALIED });
    yield put({ type: REQUEST_CHANGE_PASSWORD_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_CHANGE_PASSWORD, requestChangePassword)
  ]);
}