import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_PENDING,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR
} from './types';
import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from '../notification/types';
import { ERROR_RESET_PASSWORD_FALIED } from '../../util/ErrorMessages';
import { RESET_PASSWORD_SUCCESS } from '../../util/Messages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestResetPassword(action) {
  yield put({ type: REQUEST_RESET_PASSWORD_PENDING });
  
  try {
    // Calling the server
    yield call([Auth, 'forgotPasswordSubmit'], action.payload.username, action.payload.code, action.payload.password);
    // Triggering success action
    yield put({ type: REQUEST_RESET_PASSWORD_SUCCESS });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: RESET_PASSWORD_SUCCESS });
  } catch (err) {
    const message = err.message ? err.message : ERROR_RESET_PASSWORD_FALIED;
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: message });
    yield put({ type: REQUEST_RESET_PASSWORD_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_RESET_PASSWORD, requestResetPassword)
  ]);
}