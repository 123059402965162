export default {
  menu: {
    matchAnalyzer: 'Phân Tích Trận Đấu',
    tournaments: 'Giải đấu',
    news: 'Tin tức',
    pgcPoints: 'PGC Điểm',
    rankings: 'Rankings', // TODO missing
    scrims: 'Scrims',
    userMatches: 'Tìm Trận Đấu Theo Tài Khoản',
    rotationAnalyzer: 'Phân Tích Di Chuyển',
    teamsRotations: 'Di Chuyển của Đội Tuyển',
    saved: 'Lưu trữ',
    savedMatches: 'Trận đấu đã lưu',
    productionOverlays: 'Overlays Cho Đơn Vị Sản Xuất',
    reportProblem: 'Báo Cáo Lỗi',
    discord: 'Vào Discord Của Twire',
    twitter: 'Theo Dõi Twire tại Twitter',
  },
  headerMenu: {
    hi: 'Xin Chào',
    account: 'Tài Khoản',
    settings: 'Cài Đặt',
    contact: 'Liên Hệ',
    logout: 'Đăng Xuất',
    logoutError: 'Lỗi khi Đăng Xuất. Vui lòng thử lại!',
    goPremium: 'Dùng Premium',
  },
  common: {
    save: 'Lưu',
    edit: 'Chỉnh sửa',
    delete: 'Xóa',
    cancel: 'Hủy Bỏ',
    search: 'Tìm Kiếm',
    ok: 'OK',
    showMore: 'Xem Thêm',
    close: 'Đóng',
    loading: 'Đang tải...',
    learnMore: 'Tìm Hiểu Thêm',
    beta: 'Beta',
    warning: 'Cảnh Báo',
    goTo: 'Đi Đến',
    goToApp: 'Đi Đến Ứng Dụng',
    login: 'Đăng Nhập',
    pleaseWait: 'Xin Đợi...',
    success: 'Thành Công!',
    signUp: 'Đăng Ký Tài Khoản',
  },
  settings: {
    basicSettings: 'Cài Đặt Cơ Bản',
    username: 'Tên Tài Khoản',
    email: 'Email',
    subscription: 'Đăng Ký Gói Cước',
    plan: 'Gói Cước',
    validTo: 'Giá Trị Đến',
    freePlan: 'Miễn Phí',
    trialPlan: 'Dùng Thử',
    premiumPlan: 'Premium',
    manageSubscription: 'Bạn có thể quản lý gói cước trong tài khoản Paypal của bạn.',
    pubgUsernames: 'Các tên tài khoản PUBG (chỉ PUBG PC)',
    pubgUsername: 'Tên tài khoản PUBG',
    enterPubgUsername: 'Hãy nhập tên tài khoản PUBG (nhập đúng kí tự hoa, thường)',
    status: 'Tình Trạng ',
    pendingConfirmation: 'Chờ Xác nhận',
    invlid: 'Không Hợp lệ',
    active: 'Có Hiệu lực',
    password: 'Mật khẩu',
    notifications: 'Thông báo',
    emailNotifications: 'Thông báo Email',
    currentPassword: 'Mật khẩu Hiện tại',
    enterCurrentPassword: 'Hãy nhập Mật khẩu hiện tại',
    newPassword: 'Mật khẩu mới',
    enterNewPassword: 'Hãy nhập Mật khẩu mới',
    passwordConfirmation: 'Xác nhận Mật khẩu',
    enterPasswordConfirmation: 'Hãy nhập lại Mật khẩu mới của bạn',
    changePassword: 'Thay đổi Mật khẩu',
    promoCode: 'Mã Khuyến mãi',
    use: 'Sử dụng',
    wrongPromoCode: 'Mã Khuyến mãi sai',
    promoCodeSuccess: 'Mã khuyến mãi được sử dụng thành công. Trang sẽ được tải lại.',
  },
  regions: {
    allRegions: 'Tất cả Khu vực',
    global: 'Toàn Cầu',
    asia: 'Châu Á',
    europe: 'Châu Âu',
    southAmerica: 'Nam Mỹ',
    northAmerica: 'Bắc Mỹ',
    oceania: 'Châu Đại Dương',
    apac: 'Châu Á-Thái Bình Dương',
  },
  tournamentSearch: {
    tournamentFilter: 'Lọc Giải đấu',
    tournamentName: 'Tên Giải đấu',
    clearFilter: 'Xóa Bộ lọc',
    missingTournament: 'Thiếu Giải đấu?',
    recentTournaments: 'Các Giải đấu gần đây',
    allTournaments: 'Tất cả Giải đấu',
    tournament: 'Giải đấu',
    createTournament: 'Tạo Giải đấu',
    noTournaments: 'Không có Giải đấu',
  },
  content: {
    title: 'Tin tức',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'Viết bình luận',
    newCommentPlaceholder: 'Bình luận của bạn',
  },
  tournament: {
    tournament: 'Giải đấu',
    tournamentInfo: 'Thông tin Giải đấu',
    region: 'Khu vực',
    matches: 'Các Trận đấu',
    leaderboards: 'Bảng Xếp Hạng',
    prizeLeaderboards: 'Bảng Xếp Hạng Giải Thưởng',
    playerStats: 'Thông số Tuyển thủ',
    teamStats: 'Thông số Đội tuyển',
    selectTournament: 'Chọn giải đấu',
    noMatchesFound: 'Không tìm thấy trận đấu',
    noLeaderboards: 'Chưa có bảng xếp hạng cho giải đấu này',
    leaderboardsNotFound: 'Bảng xếp hạng chưa tồn tại hoặc các trận đấu chưa diễn ra. Hãy thử lại sau.',
    noPlayerStats: 'Chưa có thông số cho giải đấu này',
    playerStatsNotFound: 'Thông số chưa tồn tại hoặc các trận đấu chưa diễn ra. Hãy thử lại sau.',
    tournamentPhase: 'Giai đoạn giải đấu',
    selectTournamentPhase: 'Chọn giai đoạn giải đấu',
    loadingMatches: 'Đang tải trận đấu...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'Tìm theo tên tài khoản',
    pubgUsername: 'Tên tài khoản PUBG  (nhập đúng kí tự hoa, thường)',
    matchesOf: 'Các trận đấu của',
    enterPubgUsername: 'Nhập tên tài khoản PUBG',
    usernameNotTracked: 'Chúng tôi chưa theo dõi tên tài khoản này nên chúng tôi chưa có tất cả trận đấu. Bạn có thể thêm tên tài khoản này',
    settings: 'cài đặt',
    favoriteUsers: 'Yêu thích',
  },
  match: {
    rank: 'Hạng',
    date: 'Ngày',
    match: 'Trận đấu',
    map: 'Bản đồ',
    type: 'Thể loại',
    zone: 'khu vực',
    analyzeStats: 'Phân tích/Thông số',
    mode: 'Chế độ',
    duration: 'Thời lượng',
  },
  matchDetails: {
    matchDetails: 'Chi tiết Trận đấu',
    matchStats: 'Thống kê Trận đấu',
    match: 'Trận đấu',
    map: 'Bản đồ',
    mode: 'Chế độ',
    duration: 'Thời lượng',
  },
  matchStats: {
    rank: 'Hạng',
    wins: 'WWCD',
    totalPoints: 'Tổng Điểm',
    avgTotalPoints: 'Tổng Điểm Trung Bình',
    avgRank: 'Hạng Trung Bình',
    points: 'Điểm Hạng',
    avgPoints: 'Điểm Hạng Trung Bình',
    twr: 'TWR',
    numOfMatches: 'Số Trận Đấu',
    team: 'Đội Tuyển',
    player: 'Tuyển Thủ',
    username: 'Tuyển Thủ',
    kills: 'Điểm Mạng',
    avgKills: 'Điểm Mạng Trung Bình',
    assists: 'Kiến Tạo',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Sát thương Gây ra',
    avgDamageDealt: 'Sát thương Gây ra Trung bình',
    damageTaken: 'Sát thương Nhận vào',
    avgDamageTaken: 'Sát thương Nhận vào Trung bình',
    dbnos: 'Hạ gục',
    revives: 'Hồi sinh đồng đội',
    headshotKills: 'Mạng Headshot',
    longestKill: 'Mạng Xa nhất (m)',
    killSteals: 'Mạng Cướp được',
    killsStolenFrom: 'Mạng Bị Cướp',
    grenadePickup: 'Số Lựu đạn Nhặt',
    grenadeDrop: 'Số Lựu đạn Thả',
    grenadeThrow: 'Số Lựu đạn Ném',
    grenadeDamage: 'Sát thương từ Lựu đạn',
    molotovPickup: 'Số Bom lửa Nhặt',
    molotovDrop: 'Số Bom lửa Thả',
    molotovThrow: 'Số Bom lửa Ném',
    molotovDamage: 'Sát thương từ Bom lửa',
    smokebombPickup: 'Số Bom khói Nhặt',
    smokebombDrop: 'Số Bom khói Thả',
    smokebombThrow: 'Số Bom khói Ném',
    flashbangPickup: 'Số Bom choáng Nhặt',
    flashbangDrop: 'Số Bom choáng Thả',
    flashbangThrow: 'Số Bom choáng Ném',
    swimDistance: 'Quãng đường Bơi (km)',
    walkDistance: 'Quãng đường Đi bộ (km)',
    rideDistance: 'Quãng đường Lái (km)',
    timeSurvived: 'Tổng Thời Gian Sinh Tồn',
    avgTimeSurvived: 'Trung Bình Thời Gian Sinh Tồn',
    deathType: 'Kiểu Chết',
    heals: 'Hồi Phục',
    healthRecovered: 'Lượng Máu Hồi Phục',
    boosts: 'Nước đã dùng',
    vehicleDestroys: 'Số Phương Tiện Phá Hủy',
    kd: 'KD',
    killsKnocks: 'Điểm Mạng/Hạ gục',
    arDamage: 'Sát thương Gây ra (AR)', // TODO missing
    dmrDamage: 'Sát thương Gây ra (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'Sát thương Gây ra/Sát thương Nhận vào',
    damageTakenFromBlueZone: 'Sát thương Nhận vào (Zone)', // TODO missing
    damageTakenFromEnemy: 'Sát thương Nhận vào (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'Mạng Cướp được - khi tuyển thủ kết liễu ai đó đã bị hạ gục bởi đội khác',
    stolenKillsFromExplaines: 'Mạng Bị cướp - khi tuyển thủ hạ gục ai đó nhưng bị đội khác kết liễu',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: 'Lưu',
    undo: 'Hoàn Tác',
    clearAll: 'Dọn Sạch',
    zonesToDraw: 'Vùng để Vẽ',
    pen: 'Bút',
    playerDetails: 'Chi tiết Tuyển thủ',
    rotationDetails: 'Chi tiết Di chuyển',
    playerNames: 'Tên Tuyển thủ',
    teamNames: 'Tên Đội tuyển',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: 'Hiển thị tuyển thủ đã chết',
    grid: 'Lưới',
    planePath: 'Đường Bay',
    showPlanePaths: 'Hiển Thị Đường Bay',
    showRotations: 'Hiển Thị Đường Di chuyển',
    showCircles: 'Hiển thị Vòng bo',
    showLandingSpots: 'Hiển thị Điểm nhảy',
    heatmap: 'Heatmap', // TODO missing
    help: 'Trợ giúp',
  },
  help: {
    help: 'Trợ giúp',
    tutorial: 'Hướng dẫn',
    runTutorial: 'Chạy Hướng dẫn',
    shortcuts: 'Phím Tắt',
    keyBinding: 'Tổ Hợp Phím',
    description: 'Mô Tả',
    playPause: 'Chạy/Dừng',
    stepBackward: 'Lùi lại (2 giây)',
    stepForward: 'Tiến tới (2 giây)',
    previousSpeed: 'Giảm Tốc Độ Chạy',
    nextSpeed: 'Tăng Tốc Độ Chạy',
    gird: 'hiện/ẩn lưới',
    teamNames: 'hiện/ẩn tên đội tuyển',
    playerNames: 'hiện/ẩn tên tuyển thủ',
    deadPlayers: 'hiện/ẩn tuyển thủ đã chết',
    planePath: 'hiện/ẩn đường bay',
    playerDetails: 'hiện/ẩn chi tiết tuyển thủ',
    rotationDetails: 'hiện/ẩn chi tiết di chuyển',
    fullscreen: 'bật/tắt toàn màn hình',
  },
  matchAnalyzer: {
    names: 'Tên',
    trace: 'Theo dõi',
    emptyInventory: 'Kho đồ Trống',
  },
  teamsSearch: {
    searchByTeam: 'Tìm kiếm theo Đội',
    teamNameOrInitial: 'Tên đội hoặc viết tắt',
    teams: 'Đội tuyển',
    team: 'Đội tuyển',
    noResults: 'Không có Kết quả',
    oldRotationAnalyzer: 'Phân tích di chuyển cũ',
    tryNow: 'Hãy Thử Ngay',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Phân tích Di chuyển',
    selectMap: 'Lựa chọn Bản đồ để Phân tích',
    match: 'Trận đấu',
    hideAll: 'Ẩn Tất cả',
    show: 'Hiện',
    rank: 'Hạng',
    kills: 'Điểm Hạ gục',
    damageDealt: 'Sát thương Gây ra',
    damageTaken: 'Sát thương Nhận',
    zoneDirection: 'Hướng vòng Bo',
    phases: 'Giai đoạn',
    walking: 'Đi Bộ',
    driving: 'Lái Xe',
    endingZoneArea: 'KHU VỰC BO CUỐI',
    selectZoneArea: 'Lựa chọn khu vực vòng bo',
    selectTeam: 'Thêm ít nhất một đội tuyển',
    zone: 'Khu vực',
    reset: 'Cài lại',
    resetWarning: 'Bước này sẽ gỡ bỏ toàn bộ phần di chuyển đã thêm vào và cài lại khu vực đã chọn?',
    teams: 'Đội tuyển',
    add: 'Thêm',
    noTeams: 'Chưa có đội tuyển được chọn',
  },
  saved: {
    noPremium: 'Tính năng này chỉ dành cho người dùng Premium. Bạn vẫn có thể truy cập vào các trận đấu đã lưu nhưng bạn không thể lưu thêm các trận đấu mới.',
    savedMatches: 'Trận đấu Đã lưu',
    rename: 'Đổi tên',
    delete: 'Xóa',
    renameFile: 'Đổi tên Tập tin',
    newFileName: 'Tên mới của Tập tin',
    fileDelete: 'Xóa Tập tin',
    fileDeleteWarning: 'Bạn có chắc muốn xóa tập tin này?',
    matchAnalysis: 'Phân tích Trận đấu',
    modalRenameTitle: 'Đổi tên Trận đấu',
    modalRenamePlaceholder: 'Tên mới của Trận đấu',
    modalDeleteTitle: 'Xóa Trận đấu',
    modalDeletePlaceholder: 'Bạn có chắc muốn xóa trận đấu này?',
  },
  connect: {
    title: 'Cập nhật với Chúng Tôi!',
    discordConnectText: 'Cùng Kết nối thông qua Discord!',
    connectMe: 'Kết nối với tôi!',
  },
  features: {
    title: 'Ở đây có gì cho bạn?',
    feature1Title: 'Bảng Xếp hạng Giải đấu',
    feature1Text: 'Cập nhật với các giải đấu trên khắp thế giới',
    feature2Title: 'Thông số Tuyển thủ',
    feature2Text: 'So sánh các tuyển thủ và kĩ năng của họ ở những hạng mục khác nhau',
    feature3Title: 'Phân tích Trận đấu',
    feature3Text: 'Phân tích các trận đấu của bạn và cùng nhau tăng cường khả năng thi đấu',
    feature4Title: 'Phân tích Di chuyển',
    feature4Text: 'Tìm hiểu hướng di chuyển của các đối thủ và chuẩn bị chiến thuật tấn công của bạn',
  },
  games: {
    title: 'Bạn chơi những trò chơi nào?',
    availableNow: 'Đã có sẵn',
    comingSoon: 'Sắp có',
  },
  partners: {
    title: 'Đối tác',
  },
  pricing: {
    title: 'Giá cả',
    advertisement: 'Quảng cáo',
    matchAnalyzer: 'Phân tích Trận đấu',
    rotationAnalyzer: 'Phân tích Di chuyển',
    drawing: 'Vẽ',
    collaborativeDrawing: 'Cùng Vẽ',
    savingMatches: 'Lưu trữ Trận đấu',
    premium: 'Premium',
    freePeriod: '14 Ngày Dùng Thử',
    paidMonthly: 'Thanh toán theo tháng',
    paidQuarterly: 'Thanh toán theo quý',
    noCardRequired: 'Không yêu cầu Thẻ',
    planContact1: 'Nếu bạn hoặc đội của bạn cần gói đăng ký riêng liên hệ với chúng tôi ở ',
    planContact2: 'hoặc thông qua',
    planContact3: '',
    free: 'Miễn phí',
  },
  payment: {
    title: 'Giá cả',
    saved: 'Đã lưu',
    ads: 'Loại bỏ các quảng cáo',
    analyzerMapReplay: 'Phân tích bản đồ replays',
    seeTeamsRotations: 'Xem di chuyển của các đội',
    workCollaboratively: 'Làm việc cùng nhau',
    shareDrawingInRealTime: 'Chia sẻ bản vẽ trong thời gian thực',
    drawing: 'Vẽ',
    markImportantMoments: 'Đánh dấu những thời điểm quan trọng',
    saveitForLater: 'Lưu cho những lần sau',
    recommended: 'Khuyên dùng',
    billedQuarterly: 'Thanh toán theo quý',
    billedMonthly: 'Thanh toán theo tháng',
    selectPlan: 'Lựa chọn Gói cước',
    billedNow: 'Thanh toán Bây giờ',
    alreadyPremium: 'Bạn đã là thành viên Premium',
    expiresInDays: 'Dùng thử Miễn phí hết hạn trong 14 ngày',
    learnMore: 'Tìm hiểu thêm',
  },
  welcome: {
    title1: 'Bước đầu',
    title2: 'SỰ NGHIỆP ESPORTS',
    subtitle: 'Công cụ phân tích cho người Chiến thắng',
    register: 'Đăng ký miễn phí',
    learnMore: 'Tôi muốn tìm hiểu thêm',
  },
  forgotPassword: {
    emailSent: 'Email Đã Gửi!',
    emailSentMessage: 'Email với đường dẫn để đặt lại mật khẩu đã được gửi cho bạn.',
    forgotPassword: 'Quên Mật khẩu',
    inputUsername: 'Nhập tên tài khoản của bạn',
    sentResetLink: 'Gửi đường dẫn đặt lại mật khẩu',
  },
  login: {
    signUp: 'Đăng ký',
    dontHaveAccountLong: 'Chưa có tài khoản? Bạn còn chờ gì nữa! Đăng ký và bắt đầu cải thiện kỹ năng của bạn với những công cụ tuyệt vời của chúng tôi!',
    dontHaveAccount: 'Chưa có tài khoản?',
    registerNow: 'Đăng ký Bây giờ!',
    login: 'Đăng nhập',
    resetPassword: 'Đặt lại Mật khẩu',
    reset: 'Cài lại',
    signInAccount: 'Đăng Nhập vào tài khoản của bạn',
    passwordResetNeeded: 'Bạn cần phải đặt lại mật khẩu của bạn',
    username: 'Tên Tài khoản',
    password: 'Mật khẩu',
    passwordConfirmation: 'Xác nhận Mật khẩu',
    forgotPassword: 'Quên Mật khẩu?',
    resetPasswordRequired: 'Bạn cần phải đặt lại mật khẩu của bạn',
    wrongCredentials: 'Tên đăng nhập hoặc mật khẩu không hợp lệ',
    passwordsDontMatch: 'Mật khẩu không trùng khớp',
    passwordToShort: 'Mật khẩu cần phải có tối thiểu 8 ký tự, ít nhật một chữ cái và một chữ số',
  },
  register: {
    register: 'Đăng ký',
    thankYou: 'Cám ơn bạn!',
    thankYouLong: 'Cám ơn bạn đã tạo tài khoản! Hãy kiểm tra email để hoàn tất đăng ký.',
    username: 'Tên tài khoản',
    email: 'Email',
    password: 'Mật khẩu',
    repeatPassword: 'Lặp lại mật khẩu',
    subscribeToNewsletter: 'Theo dõi bản tin của chúng tôi',
    readAndUnderstand: 'Tôi đã đọc và hiểu',
    termsOfUse: 'Điều khoản Sử dụng',
    createAccount: 'Tạo Tài khoản',
    alreadyRegistered: 'Đã Đăng ký?',
    invalidUsername: 'Tên Tài khoản không hợp lệ',
    invalidEmail: 'Email không hợp lệ',
    invalidPassword: 'Mật khẩu cần phải có tối thiểu 8 ký tự, ít nhật một chữ cái và một chữ số',
    passwordsDontMatch: 'Mật khẩu không trùng khớp',
    termsRequired: 'Bạn phải đồng ý Điều khoản Sử dụng',
  },
  resetPassword: {
    resetPassword: 'Đặt lại Mật khẩu',
    selectNewPassword: 'Chọn Mật khẩu mới',
    password: 'Mật khẩu',
    passwordConfirmation: 'Xác nhận Mật khẩu',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: 'Xác nhận thất bại! Đường dẫn của bạn bị hỏng, hết hạn hoặc đã xác nhận xong.',
    resendEmail: 'Gửi lại email',
    verifyingEmail: 'Đang xác nhận email của bạn...',
    verificationSuccess: 'Xác nhận hoàn tất! Bạn có thể đăng nhập lại!',
  }
}