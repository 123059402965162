import styles from '../scss/style.scss';
import { MATCH_ANALYZER_TOOL, ROTATION_ANALYZER_TOOL_V2 } from './TrackUtils';

// URL to Twire assets bucket
export const TWIRE_ASSETS_URL = 'https://twire-assets.s3-eu-west-1.amazonaws.com/';

// Premium cookie
export const PREMIUM_COOKIE = "PREMIUM_USER";

// Attack pre and post fire (to be visible longer)
export const ATTACK_OFFSET = 10;

// Max tournaments shown
export const MAX_TOURNAMENTS_SHOWN = 12;

// Constants for getting tournaments by type
export const TOURNAMENT_EVENT_TYPE = 'tournament';
export const SCRIM_EVENT_TYPE = 'scrim';

// Match list types
export const TOURNAMENT_RECENT_DAYS = 14;
export const TOURNAMENT_MATCH_LIST_TYPE = 'TOURNAMENT_MATCH_LIST_TYPE';
export const SCRIM_MATCH_LIST_TYPE = 'SCRIM_MATCH_LIST_TYPE';
export const USER_MATCH_LIST_TYPE = 'USER_MATCH_LIST_TYPE';

// File types
export const FILE_TYPE_FOLDER = 'folder';
export const FILE_TYPE_MATCH_ANALYSIS = 'analysis';
export const FILE_TYPE_ROTATION_ANALYSIS = 'rotation';

// Css breakpoint constants
export const SCREEN_SIZE_XS = 0;
export const SCREEN_SIZE_SM = 576;
export const SCREEN_SIZE_MD = 768;
export const SCREEN_SIZE_LG = 922;
export const SCREEN_SIZE_XL = 1200;

// Drawing tools colors
export const DRAWING_TOOLS_COLORS = ['#FFFFFF', '#FEC33E', '#FA7558', '#4DBD9E', '#7289DA'];

// Player states
export const PLAYER_ALIVE = 0;
export const PLAYER_KNOCKED = 1;
export const PLAYER_DEAD = 2;

// Zones constants
export const SAFE_ZONE = 'safezone';
export const BLUE_ZONE = 'bluezone';
export const RED_ZONE = 'redzone';
export const BLACK_ZONE = 'blackzone';

// Color of player's circle border
export const playerStatusColor = {
  [PLAYER_ALIVE]: styles.alive,
  [PLAYER_KNOCKED]: styles.knocked,
  [PLAYER_DEAD]: styles.dead
}

// Saved file action types
// Match analysis
export const MATCH_ANALYSIS = 'analysis';
// Rotation analysis
export const ROTATION_ANALYSIS_V2 = 'rotation-v2';
// Circle analysis
export const CIRCLE_ANALYSIS = 'circle';

export const ANALYSIS_TOOLS = {
  [MATCH_ANALYSIS]: MATCH_ANALYZER_TOOL,
  [ROTATION_ANALYSIS_V2]: ROTATION_ANALYZER_TOOL_V2,
}

/** Represents zone object */
export const MAP_OBJECT_ZONE = 'ZONE';
/** Represents landing object */
export const MAP_OBJECT_LANDING = 'LANDING';
/** Represents plane path */
export const MAP_OBJECT_PLANE_PATH = 'PLANE_PATH';
/** Represents walking object */
export const MOVEMENT_WALKING = 'walking';
/** Represents walking driving */
export const MOVEMENT_DRIVING = 'driving';

// Match stats columns
export const MATCH_STATS_COLUMNS = [
  {
    name: 'Rank',
    property: 'rank'
  },
  {
    name: 'Player',
    property: 'username'
  },
  {
    name: 'Kills',
    property: 'kills'
  },
  {
    name: 'Damage',
    property: 'damageDealt'
  },
  {
    name: 'Damage Taken',
    property: 'damageTaken'
  },
  {
    name: 'Assists',
    property: 'assists'
  },
  {
    name: 'Stolen Kills',
    sup: '1',
    property: 'killSteals'
  },
  {
    name: 'Stolen Kills From',
    sup: '2',
    property: 'killsStolenFrom'
  },
  {
    name: 'Time Survived',
    property: 'timeSurvived'
  },
  {
    name: 'Death Type',
    property: 'deathType'
  },
  {
    name: 'Heals',
    property: 'heals'
  },
  {
    name: 'Boosts',
    property: 'boosts'
  },
  {
    name: 'Vehicle Destroys',
    property: 'vehicleDestroys'
  }
];

// Inventory items
export const THROWABLES = ['Item_Weapon_FlashBang_C', 'Item_Weapon_Grenade_C', 'Item_Weapon_Molotov_C', 'Item_Weapon_SmokeBomb_C'];
export const HEALERS = ['Item_Heal_MedKit_C', 'Item_Heal_FirstAid_C', 'Item_Heal_Bandage_C'];
export const BOOSTERS = ['Item_Boost_AdrenalineSyringe_C', 'Item_Boost_PainKiller_C', 'Item_Boost_EnergyDrink_C'];

// User groups
export const USER_GROUP_TESTER = 'TESTER';
export const USER_GROUP_TOURNAMENT_ORGANIZER = 'TOURNAMENT_ORGANIZER';

// Region for showing the right currency
export const SUBSCRIPTION_EU_REGION = 'EU';
export const SUBSCRIPTION_EU_CURRENCY = '€';
export const SUBSCRIPTION_EU_CURRENCY_TEXT = 'EUR';
export const SUBSCRIPTION_OTHER_REGION = 'other';
export const SUBSCRIPTION_OTHER_CURRENCY = '$';
export const SUBSCRIPTION_OTHER_CURRENCY_TEXT = 'USD';
export const SUBSCRIPTION_CURRENCIES = {
  [SUBSCRIPTION_EU_REGION]: SUBSCRIPTION_EU_CURRENCY,
  [SUBSCRIPTION_OTHER_REGION]: SUBSCRIPTION_OTHER_CURRENCY
};
export const SUBSCRIPTION_CURRENCIES_TEXT = {
  [SUBSCRIPTION_EU_REGION]: SUBSCRIPTION_EU_CURRENCY_TEXT,
  [SUBSCRIPTION_OTHER_REGION]: SUBSCRIPTION_OTHER_CURRENCY_TEXT
};

// Players statistics
export const PLAYER_STATISTICS = {
  twr: 'TWR',
  numOfMatches: 'Number of Matches',
  kills: 'Kills',
  assists: 'Assists',
  kda: 'KDA',
  kd: 'KD',
  kas: 'KAS',
  dbnos: 'Knocks',
  killsKnocks: 'Kills/Knocks',
  damageDealt: 'Damage Dealt',
  arDamage: 'AR Damage',
  dmrDamage: 'DMR Damage',
  avgDamageDealt: 'Avg Damage Dealt',
  damageTaken: 'Damage Taken',
  avgDamageTaken: 'Avg Damage Taken',
  damageTakenFromBlueZone: 'Damage Taken (Zone)',
  damageTakenFromEnemy: 'Damage Taken (Enemy)',
  damageDealtDamageTaken: 'Damage Dealt/Damage Taken',
  revives: 'Revives',
  diedFirst: 'Died 1st',
  diedSecond: 'Died 2nd',
  diedThird: 'Died 3rd',
  diedForth: 'Died 4th',
  knocked: 'Knocked',
  revived: 'Revived',
  headshotKills: 'Headshot Kills',
  longestKill: 'Longest Kill (m)',
  killSteals: 'Stolen Kills',
  killsStolenFrom: 'Kills stolen from player',
  grenadePickup: 'Grenades Picked',
  grenadeDrop: 'Grenades Dropped',
  grenadeThrow: 'Grenades Thrown',
  grenadeDamage: 'Grenade Damage',
  molotovPickup: 'Molotovs Picked',
  molotovDrop: 'Molotovs Dropped',
  molotovThrow: 'Molotovs Thrown',
  molotovDamage: 'Molotovs Damage',
  smokebombPickup: 'Smokes Picked',
  smokebombDrop: 'Smokes Dropped',
  smokebombThrow: 'Smokes Thrown',
  flashbangPickup: 'Flashes Picked',
  flashbangDrop: 'Flashes Dropped',
  flashbangThrow: 'Flashes Thrown',
  swimDistance: 'Swim Distance (km)',
  walkDistance: 'Walk Distance (km)',
  rideDistance: 'Ride Distance (km)',
  timeSurvived: 'Time Survived',
  avgTimeSurvived: 'Avg Time Survived',
  heals: 'Heals',
  healthRecovered: 'Health Recovered',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}

export const ROUND_STAT_NUMBER = {
  twr: Math.round,
  damageDealt: Math.round,
  avgDamageDealt: Math.round,
  arDamage: Math.round,
  dmrDamage: Math.round,
  damageTaken: Math.round,
  damageTakenFromBlueZone: Math.round,
  damageTakenFromEnemy: Math.round,
  longestKill: Math.round,
  swimDistance: Math.round,
  walkDistance: Math.round,
  rideDistance: Math.round,
  healthRecovered: Math.round,
  grenadeDamage: Math.round,
  molotovDamage: Math.round,
}

// TWR statistics
export const TWR_STATISTICS = [
  { name: 'kills', moreBest: true },
  { name: 'numOfMatches', moreBest: true },
  { name: 'assists', moreBest: true },
  { name: 'damageDealt', moreBest: true },
  { name: 'damageTaken', moreBest: false },
  { name: 'dbnos', moreBest: true },
  { name: 'revives', moreBest: true },
  { name: 'headshotKills', moreBest: true },
  { name: 'longestKill', moreBest: true },
  { name: 'killSteals', moreBest: true },
  { name: 'killsStolenFrom', moreBest: false },
  { name: 'grenadeDamage', moreBest: true },
  { name: 'molotovDamage', moreBest: true },
  { name: 'timeSurvived', moreBest: true },
  { name: 'finisher', moreBest: false },
  { name: 'utility', moreBest: true },
  { name: 'assistsToTeamKills', moreBest: true },
  { name: 'grenadeUtility', moreBest: true },
  { name: 'molotovUtility', moreBest: true },
  { name: 'smokebombUtility', moreBest: true },
  { name: 'flashbangUtility', moreBest: true },
  { name: 'stealerRatio', moreBest: true },
  { name: 'teammate', moreBest: true },
  { name: 'attacker', moreBest: true },
  { name: 'stealer', moreBest: true },
]

export const NUMBER_OF_COMPARE_PLAYERS = 4;

export const ATTACKER_STATISTICS = [
  'kills', 'assists', 'damageDealt', 'dbnos', 'grenadeDamage', 'molotovDamage',
]

export const UTILITY_STATISTICS = [
  'grenadeUtility', 'molotovUtility', 'smokebombUtility', 'flashbangUtility',
]

export const STEALER_STATISTICS = [
  'killSteals', 'stealerRatio',
]

export const ATTACKER_WEIGHTS = {
  kills: 100,
  assists: 50,
  damageDealt: 1,
  dbnos: 50,
  grenadeDamage: 1,
  molotovDamage: 1,
}

export const TWR_WEIGHTS = {
  attacker: 4,
  finisher: 4,
  utility: 3,
  teammate: 3,
  survivor: 2,
  stealer: 1,
}

export const NUMBER_OF_COMPARE_TEAMS = 4;

// Team statistics
export const TEAM_STATISTICS = {
  numOfMatches: 'Number of Matches',
  totalPoints: 'Total Points',
  avgTotalPoints: 'Avg Total Points',
  wins: 'Wins',
  avgRank: 'Avg Rank',
  points: 'Placement Points',
  avgPoints: 'Avg Placement Points',
  kills: 'Kills',
  avgKills: 'Avg Kills',
  assists: 'Assists',
  damageDealt: 'Damage Dealt',
  avgDamageDealt: 'Avg Damage Dealt',
  damageTaken: 'Damage Taken',
  avgDamageTaken: 'Avg Damage Taken',
  dbnos: 'Knocks',
  revives: 'Revives',
  headshotKills: 'Headshot Kills',
  killSteals: 'Stolen Kills',
  killsStolenFrom: 'Kills stolen from player',
  grenadePickup: 'Grenades Picked',
  grenadeDrop: 'Grenades Dropped',
  grenadeThrow: 'Grenades Thrown',
  grenadeDamage: 'Grenade Damage',
  molotovPickup: 'Molotovs Picked',
  molotovDrop: 'Molotovs Dropped',
  molotovThrow: 'Molotovs Thrown',
  molotovDamage: 'Molotovs Damage',
  smokebombPickup: 'Smokes Picked',
  smokebombDrop: 'Smokes Dropped',
  smokebombThrow: 'Smokes Thrown',
  flashbangPickup: 'Flashes Picked',
  flashbangDrop: 'Flashes Dropped',
  flashbangThrow: 'Flashes Thrown',
  swimDistance: 'Swim Distance (km)',
  walkDistance: 'Walk Distance (km)',
  rideDistance: 'Ride Distance (km)',
  timeSurvived: 'Time Survived',
  avgTimeSurvived: 'Avg Time Survived',
  heals: 'Heals',
  healthRecovered: 'Health Recovered',
  boosts: 'Boosts',
  vehicleDestroys: 'Vehicle Destroys',
}