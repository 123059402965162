import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { CIRCLE_ANALYSIS } from '../../util/Constants';
import { 
  SET_MAP_CANVAS_STATE, 
  MAP_CANVAS_CLEAR_STATE,
  ON_SELECT_ZONE_TO_DRAW,
  ADD_ZONE_TO_DRAW,
  DRAG_ZONE_TO_DRAW,
  SELECT_ZONE_TO_DRAW,
  DELETE_SELECTED_ZONE_TO_DRAW,
} from './types';

const COOKIE_SETTINGS_PREFIX = 'map-';
const COOKIE_SETTINGS = [
  'mapShowGrid',
];

const INITIAL_STATE = {
  mapWidth: 0,
  mapHeight: 0,
  mapPositionX: 0,
  mapPositionY: 0,
  map: null,
  heatMap: null,
  mapName: null,
  mapScale: 1,
  mapShowGrid: parseCookieSetting(Cookies.get(COOKIE_SETTINGS_PREFIX + 'mapShowGrid'), true),
  mapIsDrawing: false,
  mapType: null,
  displayZoneToDrawOptions: false,
  zonesToDraw: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On map canvas size change
    case SET_MAP_CANVAS_STATE:
      updateCookieSettings(state, action.payload);
      return {
        ...state,
        ...action.payload
      };

    // On select zone to draw
    case ON_SELECT_ZONE_TO_DRAW:
      return {
        ...state,
        displayZoneToDrawOptions: !state.displayZoneToDrawOptions,
      };

    // On add new zone to draw
    case ADD_ZONE_TO_DRAW:
      const zones = [
        ...(state.mapType !== CIRCLE_ANALYSIS ? state.zonesToDraw : []),
        action.payload,
      ].sort((a, b) => a.radius > b.radius ? -1 : 1);
      return {
        ...state,
        zonesToDraw: zones,
        displayZoneToDrawOptions: false,
      };

    // On drag zone to draw
    case DRAG_ZONE_TO_DRAW:
      state.zonesToDraw[action.payload.zoneIndex].x = action.payload.newPositionX;
      state.zonesToDraw[action.payload.zoneIndex].y = action.payload.newPositionY;
      state.zonesToDraw[action.payload.zoneIndex].id = uuidv4();
      return {
        ...state,
        zonesToDraw: [...state.zonesToDraw],
      };

    // On select zone to draw
    case SELECT_ZONE_TO_DRAW:
      state.zonesToDraw.forEach((z, i) => {
        if (i === action.payload) {
          z.selected = !z.selected;
        } else {
          z.selected = i === action.payload;
        }
      });
      return {
        ...state,
        zonesToDraw: [...state.zonesToDraw],
      };

    // On delete selected zone to draw
    case DELETE_SELECTED_ZONE_TO_DRAW:
      if (state.mapType === CIRCLE_ANALYSIS) {
        state.zonesToDraw.forEach(z => z.selected = true);
      }
      return {
        ...state,
        zonesToDraw: state.zonesToDraw.filter(z => !z.selected),
      };
    
    // On clear state
    case MAP_CANVAS_CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

function updateCookieSettings(oldState, state) {
  COOKIE_SETTINGS.forEach(s => {
    if (oldState && oldState[s] !== state[s] && state[s] !== undefined) {
      Cookies.set(COOKIE_SETTINGS_PREFIX + s, state[s]);
    }
  });
}

function parseCookieSetting(settingValue, defaultValue) {
  if (settingValue === 'undefined' || settingValue === '' || settingValue === undefined) {
    return defaultValue;
  }
  return settingValue === 'true';
}

export default reducer;