import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { publicQuery } from '../../graphql/requestHelper';
import { rotationByTeams } from '../../graphql/public/queries';
import {  
  INITIALIZE_ROTATION_ANALYZER_V2,
  REQUEST_TEAM_ROTATIONS,
  REQUEST_TEAM_ROTATIONS_PENDING,
  REQUEST_TEAM_ROTATIONS_SUCCESS,
  REQUEST_TEAM_ROTATIONS_ERROR,
} from './types';
import { SET_MAP_CANVAS_STATE, REQUEST_LOAD_MAP_CANVAS_MAP } from '../mapCanvasState/types';
import { ERROR_ROTATION_ANALYZER_LOADING_ERROR } from '../../util/ErrorMessages';
import { ROTATION_ANALYSIS_V2 } from '../../util/Constants';
import { OPEN_ASIDE } from '../asideState/types';
import { groupBy } from '../../util/CommonUtils';

/**
 * Initializes roation analyzer
 */
function* initializeRotationAnalyzer(action) {
  // Setting map canvas state
  yield put({ type: SET_MAP_CANVAS_STATE, payload: { mapType: ROTATION_ANALYSIS_V2 } });
  yield put({ type: REQUEST_LOAD_MAP_CANVAS_MAP, payload: action.payload });
  yield put({ type: OPEN_ASIDE });
}

/**
 * Makes a request to server for rotations
 * 
 * @param {*} action 
 */
function* requestTeamRotations(action) {
  yield put({ type: REQUEST_TEAM_ROTATIONS_PENDING });

  try {
    const map = yield select(s => s.rotationAnalyzerV2State.selectedMap);
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const result = yield publicQuery(rotationByTeams, {
      game: selectedGame,
      rotationRequest: {
        zoneArea: action.payload.zoneArea,
        teams: action.payload.teams,
        map: map,
      },
    });
    const rotations = result.data.rotationByTeams;
    const groupedRotations = groupBy(rotations, 'id');
    const allRotations = [];
    const shownRotationPhases = {};

    Object.keys(groupedRotations).forEach(matchId => {
      const rotation = {
        id: matchId + '' + groupedRotations[matchId][0].team.id,
        matchId: matchId,
        gameCreatedAt: groupedRotations[matchId][0].gameCreatedAt,
        map: groupedRotations[matchId][0].map,
        zones: groupedRotations[matchId][0].zones,
        zoneProjection: groupedRotations[matchId][0].zoneProjection,
        planePath: groupedRotations[matchId][0].planePath,
        rank: groupedRotations[matchId][0].rank,
        team: groupedRotations[matchId][0].team,
        matchName: groupedRotations[matchId][0].matchName,
        tournamentName: groupedRotations[matchId][0].tournamentName,
        kills: 0,
        damageDealt: 0,
        damageTaken: 0,
        players: [],
      };
      groupedRotations[matchId].forEach(matchPlayer => {
        rotation.kills += matchPlayer.kills;
        rotation.damageDealt += matchPlayer.damageDealt;
        rotation.damageTaken += matchPlayer.damageTaken;
        rotation.players.push({
          movement: matchPlayer.movement,
          landingSpot: matchPlayer.landingSpot,
        });
      });
      shownRotationPhases[matchId + '' + groupedRotations[matchId][0].team.id] = {
        from: 1,
        to: 9,
      };
      allRotations.unshift(rotation);
    });
    
    // Triggering success action
    yield put({ type: REQUEST_TEAM_ROTATIONS_SUCCESS, payload: { allRotations: allRotations, shownRotationPhases: shownRotationPhases } });
  } catch (err) {
    yield call(toast.error, ERROR_ROTATION_ANALYZER_LOADING_ERROR);
    yield put({ type: REQUEST_TEAM_ROTATIONS_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(INITIALIZE_ROTATION_ANALYZER_V2, initializeRotationAnalyzer),
    takeLatest(REQUEST_TEAM_ROTATIONS, requestTeamRotations),
  ]);
}