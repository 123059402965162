import { all, takeLatest, put, select } from 'redux-saga/effects';
import { privateQuery, privateMutation } from '../../graphql/requestHelper';
import { settings } from '../../graphql/private/queries';
import { saveEmailNotifications, savePubgUsername, usePromoCode } from '../../graphql/private/mutations';
import {  
  REQUEST_SETTINGS,
  REQUEST_SETTINGS_PENDING,
  REQUEST_SETTINGS_SUCCESS,
  REQUEST_SETTINGS_ERROR,
  REQUEST_SAVE_PUBG_USERNAME,
  REQUEST_SAVE_PUBG_USERNAME_PENDING,
  REQUEST_SAVE_PUBG_USERNAME_SUCCESS,
  REQUEST_SAVE_PUBG_USERNAME_ERROR,
  REQUEST_SAVE_NOTIFICATIONS,
  REQUEST_SAVE_NOTIFICATIONS_PENDING,
  REQUEST_SAVE_NOTIFICATIONS_SUCCESS,
  REQUEST_SAVE_NOTIFICATIONS_ERROR,
  REQUEST_PROMO_CODE_USE,
  REQUEST_PROMO_CODE_USE_PENDING,
  REQUEST_PROMO_CODE_USE_SUCCESS,
  REQUEST_PROMO_CODE_USE_ERROR,
} from './types';
import { DISPLAY_ERROR_NOTIFICATION, DISPLAY_SUCCESS_NOTIFICATION } from '../notification/types';
import { ERROR_SETTINGS_FAILED_TO_LOAD, ERROR_SETTINGS_FAILED_TO_SAVE } from '../../util/ErrorMessages';
import { SETTINGS_SAVE_SUCCESS, SETTINGS_PUBG_USERNAME_SAVE_SUCCESS, SETTINGS_PUBG_USERNAME_DELETE_SUCCESS } from '../../util/Messages';

/**
 * Makes a request to server for PUBG username
 */
function* requestSettings() {
  yield put({ type: REQUEST_SETTINGS_PENDING });

  try {
    // Calling the server
    const settingsResult = yield privateQuery(settings);
    // Triggering success action
    yield put({ type: REQUEST_SETTINGS_SUCCESS, payload: settingsResult.data.settings });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SETTINGS_FAILED_TO_LOAD });
    yield put({ type: REQUEST_SETTINGS_ERROR });
  }
}

/**
 * Makes a request to server to save PUBG username
 * 
 * @param {*} action 
 */
function* requestSavePubgUsername(action) {
  yield put({ type: REQUEST_SAVE_PUBG_USERNAME_PENDING });

  try {
    // Calling the server
    const pubgUsernames = yield privateMutation(savePubgUsername, { pubgUsername: action.payload.username, pubgUsernameId: action.payload.id });
    // Triggering success action
    yield put({ type: REQUEST_SAVE_PUBG_USERNAME_SUCCESS, payload: pubgUsernames.data.savePubgUsername });
    const successMessage = action.payload.username ? SETTINGS_PUBG_USERNAME_SAVE_SUCCESS : SETTINGS_PUBG_USERNAME_DELETE_SUCCESS;
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: successMessage });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SETTINGS_FAILED_TO_SAVE });
    yield put({ type: REQUEST_SAVE_PUBG_USERNAME_ERROR });
  }
}

/**
 * Makes a request to server to save PUBG username
 * 
 * @param {*} action 
 */
function* requestSaveNotifications(action) {
  yield put({ type: REQUEST_SAVE_NOTIFICATIONS_PENDING });

  try {
    // Calling the server
    yield privateMutation(saveEmailNotifications, { emailNotifications: action.payload });
    // Triggering success action
    yield put({ type: REQUEST_SAVE_NOTIFICATIONS_SUCCESS, payload: action.payload });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: SETTINGS_SAVE_SUCCESS });
  } catch (err) {
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: ERROR_SETTINGS_FAILED_TO_SAVE });
    yield put({ type: REQUEST_SAVE_NOTIFICATIONS_ERROR });
  }
}

/**
 * Makes a request to server to use promo code
 * 
 * @param {*} action 
 */
function* requestPromoCodeUse(action) {
  yield put({ type: REQUEST_PROMO_CODE_USE_PENDING });
  const translations = yield select(state => state.lang.translations);

  try {
    // Calling the server
    yield privateMutation(usePromoCode, { code: action.payload });
    // Triggering success action
    yield put({ type: REQUEST_PROMO_CODE_USE_SUCCESS });
    yield put({ type: DISPLAY_SUCCESS_NOTIFICATION, payload: translations.settings.promoCodeSuccess });
    setTimeout(() => window.location.reload(), 5000);
  } catch (err) {
    yield put({ type: REQUEST_PROMO_CODE_USE_ERROR });
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload: translations.settings.wrongPromoCode });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_SETTINGS, requestSettings),
      takeLatest(REQUEST_SAVE_PUBG_USERNAME, requestSavePubgUsername),
      takeLatest(REQUEST_SAVE_NOTIFICATIONS, requestSaveNotifications),
      takeLatest(REQUEST_PROMO_CODE_USE, requestPromoCodeUse)
  ]);
}