import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { publicQuery } from '../../graphql/requestHelper';
import { matchStats } from '../../graphql/customQueries';
import {  
  REQUEST_MATCH_STATS,
  REQUEST_MATCH_STATS_PENDING,
  REQUEST_MATCH_STATS_SUCCESS,
  REQUEST_MATCH_STATS_ERROR,
  SORT_MATCH_STATS,
  SORT_MATCH_STATS_SUCCESS,
} from './types';
import { groupBy } from '../../util/CommonUtils';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestMatchStats(action) {
  yield put({ type: REQUEST_MATCH_STATS_PENDING });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const result = yield publicQuery(matchStats(selectedGame, action.payload));
    const matchDetails = result.data.matchById;
    const matchPlayers = result.data.searchMatchPlayer;

    const matchPlayersGroupedByRank = groupBy(matchPlayers, 'rank');
    const matchPlayersSortedByRank = matchPlayers.sort((a, b) => a.rank > b.rank ? 1 : -1);

    yield put({ 
      type: REQUEST_MATCH_STATS_SUCCESS, 
      payload: {
        matchDetails: matchDetails,
        matchPlayersGroupedByRank: matchPlayersGroupedByRank,
        matchPlayersSorted: matchPlayersSortedByRank,
      } 
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_MATCH_STATS_ERROR });
  }
}

/**
 * Sorts match stats by given column
 * 
 * @param {*} action 
 */
function* sortMatchStats(action) {
  const matchStats = yield select(s => s.matchStats);
  const column = action.payload;
  const matchPlayersSorted = matchStats.matchPlayersSorted;
  const orderAsc = matchStats.orderAsc;

  const sortedMatchPlayers = matchPlayersSorted.sort((a, b) => a[column.property] > b[column.property] ? 1 : -1);

  if (orderAsc) {
    sortedMatchPlayers.reverse();
  }

  yield put({ 
    type: SORT_MATCH_STATS_SUCCESS, 
    payload: {
      matchPlayersSorted: sortedMatchPlayers,
      orderColumn: column,
      orderAsc: !orderAsc,
    } 
  });
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_MATCH_STATS, requestMatchStats),
      takeLatest(SORT_MATCH_STATS, sortMatchStats),
  ]);
}