export default {
  menu: {
    matchAnalyzer: 'Analize tekm',
    tournaments: 'Turnirji',
    news: 'Novice',
    pgcPoints: 'PGC Točke',
    rankings: 'Lestvica', // TODO missing
    scrims: 'Scrimi',
    userMatches: 'Tekme uporabnikov',
    rotationAnalyzer: 'Analize rotacij',
    teamsRotations: 'Rotacije ekip',
    saved: 'Shranjeno',
    savedMatches: 'Shranjene tekme',
    productionOverlays: 'Production overlays',
    reportProblem: 'Sporoči problem',
    discord: 'Pridruži se Discord-u',
    twitter: 'Sledi nam na Twitter-ju',
  },
  headerMenu: {
    hi: 'Živjo',
    account: 'Račun',
    settings: 'Nastavitve',
    contact: 'Kontaktiraj nas',
    logout: 'Odjava',
    logoutError: 'Napaka med odjavo. Poskusi ponovno!',
    goPremium: 'Premium',
  },
  common: {
    save: 'Shrani',
    edit: 'Uredi',
    delete: 'Izbriši',
    cancel: 'Prekiči',
    search: 'Išči',
    ok: 'OK',
    showMore: 'Prikaži več',
    close: 'zapri',
    loading: 'Nalaganje...',
    learnMore: 'Izvedi več',
    beta: 'Beta',
    warning: 'Opozorilo',
    goTo: 'Pojdi na',
    goToApp: 'Vstopi',
    login: 'Prijava',
    pleaseWait: 'Prosimo počakaj...',
    success: 'OK!',
    signUp: 'Registracija',
  },
  settings: {
    basicSettings: 'Osnovne nastavitve',
    username: 'Uporabniško ime',
    email: 'E-naslov',
    subscription: 'Naročnina',
    plan: 'Plan',
    validTo: 'Veljavno do',
    freePlan: 'Brezplačno',
    trialPlan: 'Preizkus',
    premiumPlan: 'Premium',
    manageSubscription: 'Svojo naročnino lahko urejaš na svojem PayPal računu.',
    pubgUsernames: 'PUBG Uporabniško ime (samo za PUBG PC)',
    pubgUsername: 'PUBG Uporabniško ime',
    enterPubgUsername: 'Vnesite PUBG uporabniško ime (občutljivo na velike in male črke)',
    status: 'Status',
    pendingConfirmation: 'Čaka potrditev',
    invlid: 'Napačno',
    active: 'Aktivno',
    password: 'Geslo',
    notifications: 'Obvestila',
    emailNotifications: 'Email obvestila',
    currentPassword: 'Trenutno geslo',
    enterCurrentPassword: 'Vnesite trenutno geslo',
    newPassword: 'Novo geslo',
    enterNewPassword: 'Vnesite novo geslo',
    passwordConfirmation: 'Potrdi geslo',
    enterPasswordConfirmation: 'Ponovno vnesite novo geslo',
    changePassword: 'Zamenjaj geslo',
    promoCode: 'Promocijska koda',
    use: 'uporabni',
    wrongPromoCode: 'Napačna promocijska koda',
    promoCodeSuccess: 'Promocijska koda pravila. Stran se bo ponovno naložila',
  },
  regions: {
    allRegions: 'Vse regije',
    global: 'Globalno',
    asia: 'Azija',
    europe: 'Evropa',
    southAmerica: 'Južna Amerika',
    northAmerica: 'Severna Amerika',
    oceania: 'Oceanija',
    apac: 'APAC',
  },
  tournamentSearch: {
    tournamentFilter: 'Filtri turnirjev',
    tournamentName: 'Ime turnirja',
    clearFilter: 'Počisti filtre',
    missingTournament: 'Manjkajoč turnir?',
    recentTournaments: 'Nedavni turnirji',
    allTournaments: 'Vsi turnirji',
    tournament: 'Turnir',
    createTournament: 'Create a Tournament',
    noTournaments: 'Ni turnirjev',
  },
  content: {
    title: 'Novice',
  },
  teamRanking: {
    title: 'Lestvica', // TODO missing
  },
  comment: {
    newCommentTitle: 'Komentiraj',
    newCommentPlaceholder: 'Tvoj komentar',
  },
  tournament: {
    tournament: 'Turnir',
    tournamentInfo: 'Informacije turnirjev',
    region: 'Regija',
    matches: 'Tekme',
    leaderboards: 'Lestvica',
    prizeLeaderboards: 'Lestvica Denarnih Nagrad',
    playerStats: 'Statistike igralcev',
    teamStats: 'Statistike ekip',
    selectTournament: 'Izberi turnir',
    noMatchesFound: 'Tekem ni mogoče najti',
    noLeaderboards: 'Lestvica za ta turnir ni na voljo',
    leaderboardsNotFound: 'Lestvica ne obstaja ali pa ni bilo odigranih še nobenih tekm. Poskusi pozneje',
    noPlayerStats: 'Statistika za ta turnir ni na voljo',
    playerStatsNotFound: 'Statistike igralcev ne obstajajo ali pa ni bilo odigranih še nobenih tekm. Poskusi pozneje',
    tournamentPhase: 'Faza turnirja',
    selectTournamentPhase: 'Izberi fazo turnirja',
    loadingMatches: 'Nalaganje tekm...',
  },
  scrimSearch: {
    scrims: 'Scrimi',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'Išči po uporabniškem imenu',
    pubgUsername: 'PUBG uporabniško ime (občutljivo na velike in male črke)',
    matchesOf: 'Tekme igralca',
    enterPubgUsername: 'Vnesi PUBG uporabniško ime',
    usernameNotTracked: 'Iskanega uporabnika ne sledimo, zato vse tekme morda niso prikazane. Igralca lahko dodaš za sledenje v',
    settings: 'nastavitvah',
    favoriteUsers: 'Priljubljeni',
  },
  match: {
    rank: 'Mesto',
    date: 'Datum',
    match: 'Tekma',
    map: 'Mapa',
    type: 'Tip',
    zone: 'Zone',
    analyzeStats: 'Analyziraj/Statistika',
    mode: 'Mode',
    duration: 'Dolžina',
  },
  matchDetails: {
    matchDetails: 'Podrobnosti tekme',
    matchStats: 'Statistika tekme',
    match: 'Tekma',
    map: 'Mapa',
    mode: 'Mode',
    duration: 'Dolžina',
  },
  matchStats: {
    rank: 'Mesto',
    wins: 'WWCD',
    totalPoints: 'Točne',
    avgTotalPoints: 'Povprečne Točke',
    avgRank: 'Povprečno mesto',
    points: 'Uvrstitvene točke',
    avgPoints: 'Povprečne uvrstitvene točke',
    twr: 'TWR',
    numOfMatches: 'Število igr',
    team: 'Ekipa',
    player: 'Igralec',
    username: 'Player',
    kills: 'Kills',
    assists: 'Assists',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Damage Dealt',
    avgDamageDealt: 'Avg Damage Dealt',
    damageTaken: 'Damage Taken',
    avgDamageTaken: 'Avg Damage Taken',
    dbnos: 'Knocks',
    revives: 'Revives',
    headshotKills: 'Headshot Kills',
    longestKill: 'Longest Kill (m)',
    killSteals: 'Stolen Kills',
    killsStolenFrom: 'Stolen Kills From Player',
    grenadePickup: 'Grenades Picked',
    grenadeDrop: 'Grenades Dropped',
    grenadeThrow: 'Grenades Thrown',
    grenadeDamage: 'Grenade Damage',
    molotovPickup: 'Molotovs Picked',
    molotovDrop: 'Molotovs Dropped',
    molotovThrow: 'Molotovs Thrown',
    molotovDamage: 'Molotovs Damage',
    smokebombPickup: 'Smokes Picked',
    smokebombDrop: 'Smokes Dropped',
    smokebombThrow: 'Smokes Thrown',
    flashbangPickup: 'Flashes Picked',
    flashbangDrop: 'Flashes Dropped',
    flashbangThrow: 'Flashes Thrown',
    swimDistance: 'Swim Distance (km)',
    walkDistance: 'Walk Distance (km)',
    rideDistance: 'Ride Distance (km)',
    timeSurvived: 'Time Survived',
    avgTimeSurvived: 'Avg Time Survived',
    killStreaks: 'Kill Streaks',
    deathType: 'Death Type',
    heals: 'Heals',
    healthRecovered: 'Health Recovered',
    boosts: 'Boosts',
    vehicleDestroys: 'Vehicle Destroys',
    kd: 'KD',
    killsKnocks: 'Kills/Knocks',
    arDamage: 'Damage Dealt (AR)', // TODO missing
    dmrDamage: 'Damage Dealt (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'Damage Dealt/Damage Taken',
    damageTakenFromBlueZone: 'Damage Taken (Zone)',
    damageTakenFromEnemy: 'Damage Taken (Enemy)',
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'Kills stolen - ko igralec ubije nekoka, ki je že na tleh in ga ni sam oz. nekdo iz njegove ekipe spravil na tla',
    stolenKillsFromExplaines: 'Kills stolen from - ko igralec spravi na tla nekoga in ga ubije član druge ekipe',
    kasExplaines: 'KAS (Konsistenca) - število tekem kjer je igralec naredil uboj, asistenco ali preživel',
  },
  tools: {
    save: 'Shrani',
    undo: 'Razveljavi',
    clearAll: 'Pobriši vse',
    zonesToDraw: 'Krogi',
    pen: 'Svinčnik',
    playerDetails: 'Podrobnosti igralcev',
    rotationDetails: 'Podrobnosti rotacij',
    playerNames: 'Imena igralcev',
    teamNames: 'Imena ekip',
    teamLogos: 'Logotipi ekip', // TODO missing
    showDeadPlayers: 'Prikaži mrtve igralce',
    grid: 'Grid',
    planePath: 'Pot letala',
    showPlanePaths: 'Prikaži pot letala',
    showRotations: 'Prikaži rotacije',
    showCircles: 'Prikaži kroge',
    showLandingSpots: 'Prikaži pristajalna mesta',
    heatmap: 'Heatmap', // TODO missing
    help: 'Pomoč',
  },
  help: {
    help: 'Pomoč',
    tutorial: 'Tutorial',
    runTutorial: 'Zasženi tutorial',
    shortcuts: 'Bljižnice',
    keyBinding: 'Bljižnica',
    description: 'Opic',
    playPause: 'play/pause',
    stepBackward: 'korak nazaj (2 sec.)',
    stepForward: 'korak naprej (2 sec.)',
    previousSpeed: 'prejšnja hitrost predvajanja',
    nextSpeed: 'naslednja hitrost predvajanja',
    gird: 'prikaži/skrij grid',
    teamNames: 'prikaži/skrij imena ekip',
    playerNames: 'prikaži/skrij imena igralcev',
    deadPlayers: 'prikaži/skrij mrtve igralce',
    planePath: 'prikaži/skrij pot letala',
    playerDetails: 'odpri/zapri podrobnosti igralcev',
    rotationDetails: 'odpri/zapri podrobnosti rotacij',
    fullscreen: 'vklopi/izklopi fullscreen',
  },
  matchAnalyzer: {
    names: 'Imena',
    trace: 'Pot',
    emptyInventory: 'Prazen Inventory',
  },
  teamsSearch: {
    searchByTeam: 'Išči po ekipi',
    teamNameOrInitial: 'Ime ekipe',
    teams: 'Ekipe',
    team: 'Ekipa',
    noResults: 'Ni rezultatov',
    oldRotationAnalyzer: 'Stara analiza rotacij',
    tryNow: 'Poskusi zdaj',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Analiza rotacij',
    selectMap: 'Izberi mapo',
    match: 'Tekma',
    hideAll: 'Skrij vse',
    show: 'Prikaži',
    rank: 'Mesto',
    kills: 'Kills',
    damageDealt: 'Damage Dealt',
    damageTaken: 'Damage Taken',
    zoneDirection: 'Zone Direction',
    phases: 'Phases',
    walking: 'Walking',
    driving: 'Driving',
    endingZoneArea: 'ENDING CIRCLE AREA',
    selectZoneArea: 'Izberi območje kroga',
    selectTeam: 'Dodaj vsaj eno ekipo',
    zone: 'Zone',
    reset: 'Reset',
    resetWarning: 'Ta akcija bo odstranila vse rotacije in ponastavila izbrano območje kroga?',
    teams: 'Ekipe',
    add: 'Dodaj',
    noTeams: 'Ni izbrane ekipe',
  },
  saved: {
    noPremium: 'Funkcija je na voljo samo premium uporabnikom. Še vedno lahko dostopaš do shranjenih tekm, vendar ne moreš shranjevati novih.',
    savedMatches: 'Shranjene tekme',
    rename: 'Preimenuj',
    delete: 'Izbriši',
    renameFile: 'Preimenuj',
    newFileName: 'Novo ime',
    fileDelete: 'Izbriši',
    fileDeleteWarning: 'Si siguren, da želiš izbrisati tekmo?',
    matchAnalysis: 'Analiza tekme',
    modalRenameTitle: 'Preimenuj tekmo',
    modalRenamePlaceholder: 'Novo ime tekme',
    modalDeleteTitle: 'Izbris tekme',
    modalDeletePlaceholder: 'Si siguren, da želiš izbrisati tekmo?',
  },
  connect: {
    title: 'Sledi nam!',
    discordConnectText: 'Povežimo se preko Discord aplikacije!',
    connectMe: 'Poveži me!',
  },
  features: {
    title: 'Kaj imaš od tega ti?',
    feature1Title: 'Rezultati tekmovanj',
    feature1Text: 'Bodi na tekočem s tekmovanji s celotnega sveta',
    feature2Title: 'Statistika igralcev',
    feature2Text: 'Primerjaj igralce med seboj glede na različne kategorije',
    feature3Title: 'Analiza tekm',
    feature3Text: 'Analiziraj tekme in povečaj učinkovitost istočasne analize s soigralci',
    feature4Title: 'Analiza rotacij',
    feature4Text: 'Bodi pripravljen na rotacijo nasprotnika in pripravi popoln napad',
  },
  games: {
    title: 'Katere igre igraš?',
    availableNow: 'na voljo zdaj',
    comingSoon: 'prihaja kmalu',
  },
  partners: {
    title: 'Partnerji',
  },
  pricing: {
    title: 'Cenik',
    advertisement: 'Oglasi',
    matchAnalyzer: 'Analiza tekm',
    rotationAnalyzer: 'Analiza rotacij',
    drawing: 'Risanje',
    collaborativeDrawing: 'Sočasno risanje z ekipo',
    savingMatches: 'Shranjevanje tekm',
    premium: 'Premium',
    freePeriod: '14 Preiskusna doba',
    paidMonthly: 'Mesečno plačilo',
    paidQuarterly: 'Kvartalno plačilo',
    noCardRequired: 'Brez kartice',
    planContact1: 'Če tvoja ekipa potrebuje plan po meri nas kontaktirajte na ',
    planContact2: 'ali preko',
    planContact3: '',
    free: 'Brezplačno',
  },
  payment: {
    title: 'Cenik',
    saved: 'Shranjevanje',
    ads: 'Odstranitev oglasov',
    analyzerMapReplay: 'Analiziraj tekme',
    seeTeamsRotations: 'Analiziraj rotacije ekip',
    workCollaboratively: 'Analiziraj z ekipo na daljavo',
    shareDrawingInRealTime: 'Deli svoje analize v realnem času',
    drawing: 'Risanje',
    markImportantMoments: 'Označi pomembne dogodke',
    saveitForLater: 'Shrani za pozneje',
    recommended: 'Priporočeno',
    billedQuarterly: 'Kvartalno plačilo',
    billedMonthly: 'Mesečno plačilo',
    selectPlan: 'Izberi plan',
    billedNow: 'Za plačilo zdaj',
    alreadyPremium: 'Si že premium član',
    expiresInDays: 'Poskusna doba preteče čez 14 dni',
    learnMore: 'Izvedi več',
  },
  welcome: {
    title1: 'Kickstart your',
    title2: 'ESPORTS CAREER',
    subtitle: 'Analitična orodja za zmagovalce',
    register: 'Registriraj se brezplačno',
    learnMore: 'Izvedeti želim več',
  },
  forgotPassword: {
    emailSent: 'Email poslan!',
    emailSentMessage: 'Email z povezavo za ponastavitev gesla je bil poslan.',
    forgotPassword: 'Pozabljeno geslo',
    inputUsername: 'Vpiši uporabniško ime',
    sentResetLink: 'Pošlji email',
  },
  login: {
    signUp: 'Registracija',
    dontHaveAccountLong: 'Še nimaš računa? Kaj še čakaš! Registriraj se in začni z uporabo naših super orodij!',
    dontHaveAccount: 'Še nimaš računa?',
    registerNow: 'Registriraj me!',
    login: 'Prijava',
    resetPassword: 'Ponastavi geslo',
    reset: 'Ponastavi',
    signInAccount: 'Prijavi se v račun',
    passwordResetNeeded: 'Potrebno je resetirati geslo',
    username: 'Uporabniško ime',
    password: 'Geslo',
    passwordConfirmation: 'Ponovi geslo',
    forgotPassword: 'Pozabljeno geslo?',
    resetPasswordRequired: 'Potrebno je resetirati geslo',
    wrongCredentials: 'Napačno uporabniško ime ali geslo',
    passwordsDontMatch: 'Gesli se ne ujemata',
    passwordToShort: 'Geslo mora vsebovati vsaj 8 znakov, vsaj eno črko in eno številko',
  },
  register: {
    register: 'Registracija',
    thankYou: 'Hvala!',
    thankYouLong: 'Hvala da si ustvaril račun! Poglej v soj e-poštni nabiralnik za zaključek registracije.',
    username: 'Uporabniško ime',
    email: 'Email',
    password: 'Geslo',
    repeatPassword: 'Ponovi geslo',
    subscribeToNewsletter: 'Prijavi se na elektronska obvestila',
    readAndUnderstand: 'Prebral sem in razumem',
    termsOfUse: 'pogoje uporabe',
    createAccount: 'Ustvari račun',
    alreadyRegistered: 'Si že registriran?',
    invalidUsername: 'Napačno uporabniško ime',
    invalidEmail: 'Napačen email',
    invalidPassword: 'Geslo mora vsebovati vsaj 8 znakov, vsaj eno črko in eno številko',
    passwordsDontMatch: 'Gesli se ne ujemata',
    termsRequired: 'Strinjati se moraš s pogoji uporabe',
  },
  resetPassword: {
    resetPassword: 'Ponastavi geslo',
    selectNewPassword: 'Izberi novo geslo',
    password: 'Geslo',
    passwordConfirmation: 'Ponovi geslo',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: 'Preverjanje neuspešno! Tvoja povezava je napačna, pretečena ali pa je bila že preverjena.',
    resendEmail: 'Ponovno pošlji potrditveno povezavo',
    verifyingEmail: 'Preverjanje email-a...',
    verificationSuccess: 'Preverjanje zaključeno! Lahko se prijaviš!',
  }
}