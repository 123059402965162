import { 
  REQUEST_FILE_HIGHLIGHTS_LOADED,
  FILE_HIGHLIGHTS_CLEAR_STATE,
  SET_FILE_STATE,
  REQUEST_FILE_SAVE_TEMP_SUCCESS
} from './types';

const INITIAL_STATE = {
  fileId: null,
  fileHighlights: null,
  isPublic: false,
  fileName: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On file loaded
    case REQUEST_FILE_HIGHLIGHTS_LOADED:
      return {
        ...state,
        fileId: action.payload.fileId,
        isPublic: action.payload.isPublic,
        fileName: action.payload.filename,
        fileHighlights: action.payload.highlights,
      };

    // On temp file save success
    case REQUEST_FILE_SAVE_TEMP_SUCCESS:
      return {
        ...state,
        fileName: action.payload.fileName,
      }

    // On set file state
    case SET_FILE_STATE:
      return {
        ...state,
        ...action.payload,
      };

    // On clear state
    case FILE_HIGHLIGHTS_CLEAR_STATE:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}

export default reducer;