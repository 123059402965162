import { 
  SET_MAP_TOOLS_STATE, MAP_TOOLS_CLEAR_STATE
} from './types';

const INITIAL_STATE = {
  drawingToolPenEnabled: false,
  drawingToolColor: null,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On map canvas size change
    case SET_MAP_TOOLS_STATE:
      return {
        ...state,
        ...action.payload
      };

    // On clear state
    case MAP_TOOLS_CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default reducer;