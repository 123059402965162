import { combineReducers } from 'redux';

import circleAnalyzer from './circleAnalyzer/reducer';
import general from './general/reducer';
import tournamentSearch from './tournamentSearch/reducer';
import tournament from './tournament/reducer';
import teamRotationSearch from './teamRotationSearch/reducer';
import fileSystem from './fileSystem/reducer';
import register from './register/reducer';
import verification from './verification/reducer';
import forgotPassword from './forgotPassword/reducer';
import resetPassword from './resetPassword/reducer';
import changePassword from './changePassword/reducer';
import settings from './settings/reducer';
import matchAnalyzer from './matchAnalyzer/reducer';
import matchAnalyzerState from './matchAnalyzerState/reducer';
import mapCanvasState from './mapCanvasState/reducer';
import asideState from './asideState/reducer';
import mapToolsState from './mapToolsState/reducer';
import mapModalState from './mapModalState/reducer';
import fileState from './fileState/reducer';
import rotationAnalyzerV2State from './rotationAnalyzerV2State/reducer';
import matchStats from './matchStats/reducer';
import favoriteUsers from './favoriteUsers/reducer';
import fullScreen from './fullScreen/reducer';
import payment from './payment/reducer';
import lang from './lang/reducer';

export default combineReducers({
  circleAnalyzer: circleAnalyzer,
  general: general,
  tournamentSearch: tournamentSearch,
  tournament: tournament,
  teamRotationSearch: teamRotationSearch,
  fileSystem: fileSystem,
  register: register,
  verification: verification,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  changePassword: changePassword,
  settings: settings,
  matchAnalyzer: matchAnalyzer,
  matchAnalyzerState: matchAnalyzerState,
  mapCanvasState: mapCanvasState,
  asideState: asideState,
  mapToolsState: mapToolsState,
  mapModalState: mapModalState,
  fileState: fileState,
  rotationAnalyzerV2State: rotationAnalyzerV2State,
  matchStats: matchStats,
  favoriteUsers: favoriteUsers,
  fullScreen: fullScreen,
  payment: payment,
  lang: lang,
});