// eslint-disable
// this is an auto generated file. This will be overwritten

export const fileByParentFileId = `query FileByParentFileId($parentFileId: Int) {
  fileByParentFileId(parentFileId: $parentFileId) {
    id
    actionType
    fileType
    filename
    createdAt
    parentFileId
    matchId
  }
}
`;
export const highlightByFileId = `query HighlightByFileId($fileId: Int!) {
  highlightByFileId(fileId: $fileId) {
    fileId
    isPublic
    filename
    highlights {
      time
      comment
      drawings
      sub
      username
    }
  }
}
`;
export const settings = `query Settings {
  settings {
    pubgUsernames {
      id
      pubgUsername
      pubgUsernameDone
      pubgUsernameError
    }
    emailNotifications
  }
}
`;
export const tutorial = `query Tutorial($gameId: Int!) {
  tutorial(gameId: $gameId) {
    name
    done
  }
}
`;
export const userSubscription = `query UserSubscription {
  userSubscription {
    status
    trial
    validFrom
    validTo
  }
}
`;
export const tournamentOrganizerToken = `query TournamentOrganizerToken {
  tournamentOrganizerToken
}
`;
export const liveLeaderboardToken = `query LiveLeaderboardToken($shardInfo: String!) {
  liveLeaderboardToken(shardInfo: $shardInfo)
}
`;
export const customTournamentsGetAll = `query CustomTournamentsGetAll {
  customTournamentsGetAll {
    id
    friendlyName
    shard
    imageName
    tournamentFilters
    overlayDesign
    overlayColor
    matches {
      id
      matchId
      name
      gameMode
      shard
      shardInfo
      map
      gameCreatedAt
      valid
    }
    phases {
      id
      dateFrom
      dateTo
    }
    leaderboard {
      team
      initial
      kills
      damageDealt
      points
      totalPoints
      wins
      bestMatchPoints
      lastKills
      lastPoints
      players
    }
    trackedPlayers {
      ign
      error
    }
    teams {
      slot
      teamName
    }
  }
}
`;
export const customTournament = `query CustomTournament($id: Int!) {
  customTournament(id: $id) {
    id
    friendlyName
    shard
    imageName
    tournamentFilters
    overlayDesign
    overlayColor
    matches {
      id
      matchId
      name
      gameMode
      shard
      shardInfo
      map
      gameCreatedAt
      valid
    }
    phases {
      id
      dateFrom
      dateTo
    }
    leaderboard {
      team
      initial
      kills
      damageDealt
      points
      totalPoints
      wins
      bestMatchPoints
      lastKills
      lastPoints
      players
    }
    trackedPlayers {
      ign
      error
    }
    teams {
      slot
      teamName
    }
  }
}
`;
