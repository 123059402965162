import { 
  MAP_SHOW_MODAL,
  MAP_HIDE_MODAL,
} from './types';

const INITIAL_STATE = {
  shareDialog: false,
  sharableLinkDialog: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On show modal
    case MAP_SHOW_MODAL:
      return {
        ...state,
        [action.payload]: true
      };
    
    // On hide modal
    case MAP_HIDE_MODAL:
      return {
        ...state,
        [action.payload]: false
      };

    default:
      return state;
  }
}

export default reducer;