import { all, takeLatest, call, put, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { publicQuery, privateMutation } from '../../graphql/requestHelper';
import {  
  SAVE_PAYMENT,
  REQUEST_SUBSCRIPTION_PLANS,
  REQUEST_SUBSCRIPTION_PLANS_PENDING,
  REQUEST_SUBSCRIPTION_PLANS_SUCCESS,
  REQUEST_SUBSCRIPTION_PLANS_ERROR,
  SHOW_SUBSCRIPTION_PLANS,
  HIDE_SUBSCRIPTION_PLANS,
} from './types';
import { ERROR_SAVE_PAYMENT_FAILED, ERROR_LOADING_DATA } from '../../util/ErrorMessages';
import { PAYMENT_SUCCESSFULLY_SAVED } from '../../util/Messages';
import { savePayment } from '../../graphql/private/mutations';
import { REQUEST_USER_SUBSCRIPTION } from '../general/types';
import { subscriptionPlans } from '../../graphql/public/queries';
import { subscriptionDialogOpen, subscriptionDialogClose } from '../../util/TrackUtils';
import { REQUEST_TRACK } from '../track/types';

/**
 * Displays success notification
 */
function* savePaymentAction(action) {
  try {
    // Calling the server
    yield privateMutation(savePayment, { subscriptionId: action.payload });
    yield call(toast.success, PAYMENT_SUCCESSFULLY_SAVED, { autoClose: 60000 });
    yield delay(90000);
    yield put({ type: REQUEST_USER_SUBSCRIPTION });
  } catch (err) {
    yield call(toast.error, ERROR_SAVE_PAYMENT_FAILED);
  }
}

/**
 * Gets all subscription plans
 */
function* requestSubscriptionPlans(action) {
  try {
    yield put({ type: REQUEST_SUBSCRIPTION_PLANS_PENDING });
    // Calling the server
    const result = yield publicQuery(subscriptionPlans);
    yield put({ type: REQUEST_SUBSCRIPTION_PLANS_SUCCESS, payload: result.data.subscriptionPlans });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_SUBSCRIPTION_PLANS_ERROR });
  }
}

/**
 * Show subscription dialog
 */
function* showSubscriptionPlans(action) {
  yield put({ type: REQUEST_TRACK, payload: subscriptionDialogOpen() });
}

/**
 * Show subscription dialog
 */
function* hideSubscriptionPlans(action) {
  yield put({ type: REQUEST_TRACK, payload: subscriptionDialogClose() });
}

// The exported watcher
export default function* rootSaga() {
  yield all([
    takeLatest(SAVE_PAYMENT, savePaymentAction),
    takeLatest(REQUEST_SUBSCRIPTION_PLANS, requestSubscriptionPlans),
    takeLatest(SHOW_SUBSCRIPTION_PLANS, showSubscriptionPlans),
    takeLatest(HIDE_SUBSCRIPTION_PLANS, hideSubscriptionPlans),
  ]);
}