import { 
  GET_FAVORITE_USERS_SUCCESS,
  SET_FAVORITE_USER_SUCCESS,
  REMOVE_FAVORITE_USER_SUCCESS,
} from './types';

const INITIAL_STATE = {
  favoriteUsers: [],
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On set favorite users
    case GET_FAVORITE_USERS_SUCCESS:
    case SET_FAVORITE_USER_SUCCESS:
    case REMOVE_FAVORITE_USER_SUCCESS:
      return {
        favoriteUsers: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;