import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import history from './history';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { GAMES } from './util/GamesConstants';

const loading = () => {
  return (
    <div className="app-loading fadeIn pt-3 text-center">
      <img src="/assets/img/loading/loading.svg" alt="Loading" style={{ maxWidth: '100px' }}/>
      <br />
      Loading...
    </div>
  );
};

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Verification = React.lazy(() => import('./views/Pages/Verification/Verification'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ResetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Terms = React.lazy(() => import('./views/Pages/Terms'));
const Privacy = React.lazy(() => import('./views/Pages/Privacy'));
//const Home = React.lazy(() => import('./views/Pages/Home'));
const Pricing = React.lazy(() => import('./views/Pages/Pricing'));
const Jobs = React.lazy(() => import('./views/Pages/Jobs'));
class App extends Component {

  render() {
    const { selectedGame, selectedLanguage } = this.props;
    // Adding app to not kill old links - remove in the future
    const rootUrls = [...GAMES, 'app'];

    // Here because of AWS S3 redirect rules to not get 404 on every page.
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }

    return (
      <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch> 
              <Route exact path="/:lang?/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/:lang?/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/:lang?/verification/:username/:code" name="Verification Page" render={props => <Verification {...props}/>} />
              <Route exact path="/:lang?/forgot-password" name="Forgot Password Page" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/:lang?/reset-password/:username/:code" name="Reset Password Page" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/:lang?/terms" name="Terms of use" render={props => <Terms {...props}/>} />
              <Route exact path="/:lang?/privacy" name="Privacy Policy" render={props => <Privacy {...props}/>} />
              <Route exact path="/:lang?/pricing" name="Pricing" render={props => <Pricing {...props}/>} />
              <Route exact path="/:lang?/jobs" name="Jobs" render={props => <Jobs {...props}/>} />
              <Route exact path="/:lang?/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/:lang?/500" name="Page 500" render={props => <Page500 {...props}/>} />
              {
                rootUrls.map(g => {
                  return (
                    <Route key={g} path={`/:lang?/${g}`} name="App" component={DefaultLayout} />
                  );
                })
              }
              <Route exact path="/"><Redirect to={`/${selectedLanguage || 'en'}/${selectedGame}/tournaments`}/></Route>
              <Redirect to={`/${selectedLanguage}/404`} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedGame: state.general.selectedGame,
    selectedLanguage: state.lang.lang,
  }
};

export default connect(mapStateToProps)(App);
