import en from './en';
import jp from './jp';
import kr from './kr';
import sl from './sl';
import ptbr from './pt-br';
import es from './es';
import tr from './tr';
import chi from './chi';
import vi from './vi';
import th from './th';

export default {
  en: en,
  jp: jp,
  kr: kr,
  "pt-br": ptbr,
  es: es,
  tr: tr,
  chi: chi,
  vi: vi,
  th: th,
  sl: sl,
}