import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { publicQuery } from '../../graphql/requestHelper';
import { teamByName } from '../../graphql/public/queries';
import {  
  REQUEST_TEAMS,
  REQUEST_TEAMS_PENDING,
  REQUEST_TEAMS_SUCCESS,
  REQUEST_TEAMS_ERROR
} from './types';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestTeams(action) {
  yield put({ type: REQUEST_TEAMS_PENDING });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const teams = yield publicQuery(teamByName, {
      game: selectedGame,
      name: action.payload,
    });
    // Triggering success action
    yield put({ 
      type: REQUEST_TEAMS_SUCCESS, 
      payload: teams.data.teamByName
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_TEAMS_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_TEAMS, requestTeams)
  ]);
}