// Action for initializing rotation analyzer state
export const INITIALIZE_ROTATION_ANALYZER_V2 = 'INITIALIZE_ROTATION_ANALYZER_V2';
// Action for setting rotation analyzer zone area
export const SET_ROTATION_ANALYZER_V2_ZONE_AREA = 'SET_ROTATION_ANALYZER_V2_ZONE_AREA';
export const RESET_ROTATION_ANALYZER_V2_ZONE_AREA = 'RESET_ROTATION_ANALYZER_V2_ZONE_AREA';
export const SKIP_ROTATION_ANALYZER_V2_ZONE_AREA = 'SKIP_ROTATION_ANALYZER_V2_ZONE_AREA';
// Types of action for retrieving match from server
export const REQUEST_TEAM_ROTATIONS = 'REQUEST_TEAM_ROTATIONS';
export const REQUEST_TEAM_ROTATIONS_PENDING = 'REQUEST_TEAM_ROTATIONS_PENDING';
export const REQUEST_TEAM_ROTATIONS_SUCCESS = 'REQUEST_TEAM_ROTATIONS_SUCCESS';
export const REQUEST_TEAM_ROTATIONS_ERROR = 'REQUEST_TEAM_ROTATIONS_ERROR';
// Action for setting rotation analyzer state
export const SET_ROTATION_ANALYSER_V2_STATE = 'SET_ROTATION_ANALYSIS_V2_STATE';
// Action for setting rotation analyzer state
export const SET_ROTATOIN_ANALYZER_V2_SELECTED_PHASES = 'SET_ROTATOIN_ANALYZER_V2_SELECTED_PHASES';
// Action for toggling rotation
export const TOGGLE_ROTATION_V2 = 'TOGGLE_ROTATION_V2';
// Action for showing rotation
export const SHOW_ROTATION_V2 = 'SHOW_ROTATION_V2';
// Action for hiding all rotation
export const HIDE_ALL_ROTATIONS_V2 = 'HIDE_ALL_ROTATIONS_V2';
// Action for adding team
export const ROTATION_ANALYZER_V_2_ADD_TEAM = 'ROTATION_ANALYZER_V_2_ADD_TEAM';
// Action for remove team
export const ROTATION_ANALYZER_V_2_REMOVE_TEAM = 'ROTATION_ANALYZER_V_2_REMOVE_TEAM';
// Action for clearing state
export const ROTATION_ANALYZER_V_2_CLEAR_STATE = 'ROTATION_ANALYZER_V_2_CLEAR_STATE';