// Query for getting match stats
export const matchStats = (game, id) => {
  return `query {
    matchById(game: "${game}", id: "${id}") {
      id
      matchId
      duration
      gameMode
      shardInfo
      map
      name
      gameCreatedAt
    }
    searchMatchPlayer(game: "${game}", matchId: ${id}) {
      id
      username
      rank
      dbnos
      assists
      damageDealt
      damageTaken
      revives
      kills
      killSteals
      killsStolenFrom
      rideDistance
      walkDistance
      timeSurvived
      killStreaks
      deathType
      heals
      boosts
      vehicleDestroys
      rosterId
      rosterId
    }
  }`;
}