// Actions for map canvas properties
export const SET_MAP_CANVAS_STATE = 'SET_MAP_CANVAS_STATE';
// Action for loading map image
export const REQUEST_LOAD_MAP_CANVAS_MAP = 'REQUEST_LOAD_MAP_CANVAS_MAP';
// Action for loading heatmap image
export const REQUEST_LOAD_MAP_CANVAS_HEATMAP = 'REQUEST_LOAD_MAP_CANVAS_HEATMAP';
// Action for loading steering wheel image
export const REQUEST_LOAD_STEERING_WHEEL = 'REQUEST_LOAD_STEERING_WHEEL';
// Action for loading team logo
export const REQUEST_LOAD_TEAM_LOGO = 'REQUEST_LOAD_TEAM_LOGO';
// Action for resizing map
export const ADJUST_MAP_CANVAS_SIZE = 'ADJUST_MAP_CANVAS_SIZE';
// Action for clearing canvas state
export const MAP_CANVAS_CLEAR_STATE = 'MAP_CANVAS_CLEAR_STATE';
// Prepare canvas for new analysis
export const PREPARE_NEW_CANVAS_STATE = 'PREPARE_NEW_CANVAS_STATE';
// On select zone to draw
export const ON_SELECT_ZONE_TO_DRAW = 'ON_SELECT_ZONE_TO_DRAW';
// On add new zone to draw
export const ADD_ZONE_TO_DRAW = 'ADD_ZONE_TO_DRAW';
// On dragging zone to draw
export const DRAG_ZONE_TO_DRAW = 'DRAG_ZONE_TO_DRAW';
// On select zone to draw
export const SELECT_ZONE_TO_DRAW = 'SELECT_ZONE_TO_DRAW';
// On delete selected zone to draw
export const DELETE_SELECTED_ZONE_TO_DRAW = 'DELETE_SELECTED_ZONE_TO_DRAW';