import { PLAYER_ALIVE, PLAYER_KNOCKED, TWIRE_ASSETS_URL } from "./Constants";

/**
 * Sets page title
 *
 * @param {*} title
 */
export const setPageTitle = (title) => {
  document.title = title;
}

/**
 * Validates email
 * 
 * @param {*} email 
 */
export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Validates password. Minimum eight characters, at least one letter and one number.
 * 
 * @param {*} password 
 */
export const validatePassword = (password) => {
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d](.){7,}$/;
  return re.test(password);
}

/**
 * Groups list by given attribute
 * 
 * Example:
 * list = [ 'one', 'two', 'three' ]
 * key = 'length'
 * result = { 3: [ 'one', 'two' ], 5: [ 'three' ] }
 */
export const groupBy = (list, key) => {
  return list.reduce((acc, currVal) => {
    (acc[currVal[key]] = acc[currVal[key]] || []).push(currVal);
    return acc;
  }, {});
}

/**
 * Converts number into placement string.
 * For example: 1 -> 1st
 * 
 * @param {*} n number to convert
 */
export const numberToPlacement = (n) => {
  if (n >= 10 && n <= 20) {
    return n + 'th';
  } else if (n % 10 === 1) {
    return n + 'st';
  } else if (n % 10 === 2) {
    return n + 'nd';
  } else if (n % 10 === 3) {
    return n + 'rd';
  } else {
    return n + 'th';
  }
}

/**
 * Returns parameter value from URL if exists, else null
 * 
 * @param {*} param parameter name
 */
export const getQueryParamFromUrl = (param) => {
  return decodeURIComponent(new URL(window.location.href).searchParams.get(param) || '');
}

/**
 * Returns true if number is int
 */
export const isInt = (n) => {
  return n % 1 === 0;
}

/**
 * Converts number into displayable format
 * 
 * @param {*} n 
 */
export const numberForDisplay = (n) => {
  return isInt(n) ? n : parseFloat(n).toFixed(2);
}

/**
 * Compares teams for team leaderboard
 */
export const compareTeams = (teamA, teamB, shardInfo) => {
  // TODO: ugly code to satisfy the need of Quarantine league format
  if (shardInfo.startsWith('qlf-q') || shardInfo === 'e8e0795a-bfb0-11eb-952f-064f26ad4164-qualifier') {
    const lastMatchPlayedAtA = parseInt(new Date(teamA.lastMatchPlayedAt).getTime() / (60 * 1000));
    const lastMatchPlayedAtB = parseInt(new Date(teamB.lastMatchPlayedAt).getTime() / (60 * 1000));
    if (lastMatchPlayedAtA !== lastMatchPlayedAtB) {
      if (!lastMatchPlayedAtA) {
        return 1;
      }
      if (!lastMatchPlayedAtB) {
        return -1;
      }
      return lastMatchPlayedAtA < lastMatchPlayedAtB ? -1 : 1;
    } else if (teamA.lastKills + teamA.lastPoints !== teamB.lastKills + teamB.lastPoints) {
      return teamA.lastKills + teamA.lastPoints > teamB.lastKills + teamB.lastPoints ? -1 : 1;
    } else {
      if (teamA.totalPoints !== teamB.totalPoints) {
        return teamA.totalPoints > teamB.totalPoints ? -1 : 1;
      } else if (teamA.kills !== teamB.kills) {
        return teamA.kills > teamB.kills ? -1 : 1;
      } else if (teamA.bestMatchPoints !== teamB.bestMatchPoints) {
        return teamA.bestMatchPoints > teamB.bestMatchPoints ? -1 : 1;
      } else if (teamA.bestKillPoints !== teamB.bestKillPoints) {
        return teamA.bestKillPoints > teamB.bestKillPoints ? -1 : 1;
      } else if (teamA.lastKills !== teamB.lastKills) {
        return teamA.lastKills > teamB.lastKills ? -1 : 1;
      } else {
        return teamA.lastPoints > teamB.lastPoints ? -1 : 1;
      }
    }
  } else {
    return 0;
  }
}

/**
 * Compares teams for team leaderboard
 */
export const compareTeamsEmeans = (teamA, teamB, shardInfo) => {
  if (shardInfo === 'e8e0795a-bfb0-11eb-952f-064f26ad4164-qualifier') {
    if (teamA.advance !== teamB.advance) {
      return teamA.advance ? -1 : 1;
    } else {
      if (teamA.totalPoints !== teamB.totalPoints) {
        return teamA.totalPoints > teamB.totalPoints ? -1 : 1;
      } else if (teamA.kills !== teamB.kills) {
        return teamA.kills > teamB.kills ? -1 : 1;
      } else if (teamA.bestMatchPoints !== teamB.bestMatchPoints) {
        return teamA.bestMatchPoints > teamB.bestMatchPoints ? -1 : 1;
      } else if (teamA.bestKillPoints !== teamB.bestKillPoints) {
        return teamA.bestKillPoints > teamB.bestKillPoints ? -1 : 1;
      } else if (teamA.lastKills !== teamB.lastKills) {
        return teamA.lastKills > teamB.lastKills ? -1 : 1;
      } else {
        return teamA.lastPoints > teamB.lastPoints ? -1 : 1;
      }
    }
  } else {
    return 0;
  }
}

/**
 * Returns URL address to team's image
 * 
 * @param {*} team 
 */
export const getTeamImage = (team) => {
  const game = window.location.pathname.includes('pubgc') ? 'pubgc' : 'pubg';
  if (team) {
    return `${TWIRE_ASSETS_URL}${game}/team-logos/${team.replaceAll(" ", "-").replace(/[^A-Za-z0-9\-_.]+/g, '').toLowerCase()}.png`;
  } else {
    return `${TWIRE_ASSETS_URL}/team-logos/default.png`;
  }
}

/**
 * Returns URL address to team's image
 * 
 * @param {*} team 
 */
export const getTeamLogo = (logoName) => {
  const game = window.location.pathname.includes('pubgc') ? 'pubgc' : 'pubg';
  if (logoName) {
    return `${TWIRE_ASSETS_URL}${game}/team-logos/${logoName}`;
  } else {
    return `${TWIRE_ASSETS_URL}${game}/team-logos/default.png`;
  }
}

/**
 * Returns URL to the tournament
 * 
 * @param {*} id 
 * @param {*} name 
 * @param {*} tab 
 * @param {*} filter 
 * @param {*} token 
 */
export const getTournamentURL = (selectedLanguage, selectedGame, id, name, tab, filter, token) => {
  return `/${selectedLanguage}/${selectedGame}/tournament/${id}/${urlFriendlyString(name)}${tab ? `/${tab}` : ''}${filter ? `?filter=${filter}` : ''}${token ? `${filter ? '&' : '?'}token=${encodeURIComponent(token)}` : ''}`;
}

/**
 * Returns URL to the article
 * 
 * @param {*} id 
 * @param {*} title
 */
export const getArticleURL = (selectedLanguage, selectedGame, id, title) => {
  return `/${selectedLanguage}/${selectedGame}/article/${id}/${urlFriendlyString(title)}`;
}

/**
 * Converts string to URL friendly string
 * 
 * @param {*} text 
 * @returns 
 */
export const urlFriendlyString = (text) => {
  return text.replaceAll('-', '').replaceAll(' ', '-').replace(/[^A-Za-z0-9\-_.]+/g, '').toLowerCase();
}

/**
 * Converts string to selector friendly string
 * 
 * @param {*} text 
 * @returns 
 */
export const selectorFriendlyString = (text) => {
  return text.replaceAll('-', '').replaceAll(' ', '-').replace(/[^A-Za-z0-9\-_]+/g, '').toLowerCase();
}

/**
 * Returns URL address to content image
 * 
 * @param {*} image 
 */
export const getContentImage = (image) => {
  if (image) {
    return TWIRE_ASSETS_URL + "pubg/content/" + image.replaceAll(" ", "-").replace(/[^A-Za-z0-9\-_./]+/g, '').toLowerCase() + ".png";
  } else {
    return TWIRE_ASSETS_URL + "pubg/content/default.png";
  }
}

export const teamNameToId = (teamName) => {
  return 'team-' + teamName.replaceAll(' ', '-').replaceAll(/\W/g, '').toLowerCase();
}

/**
 * Determines the name of the team according to player names
 * 
 * @param {*} playerNames 
 * @returns 
 */
export const determineTeamNameFromPlayerNames = (playerNames) => {
  const teamInitials = playerNames.map(x => x.replaceAll(' ', '').split('_')[0]);
  if (teamInitials.every(ti => ti === teamInitials[0])) {
    return teamInitials[0];
  }
  return playerNames.join(', ');
}

/**
 * Determines if team is a pro team.
 * Team is a pro team if all player's names start
 * with team name (example: TL_).
 * 
 * @param {*} team 
 */
export const isProTeam = (team) => {
  const teamName = team[0].split('_')[0];
  return team.every(player => player.split('_')[0] === teamName);
}

/**
 * Returns true if player is alive or knocked
 * 
 * @param {*} state 
 * @param {*} playerName 
 * @returns 
 */
export const isPlayerAlive = (state, playerName) => {
  return state.players[playerName].status === PLAYER_ALIVE || state.players[playerName].status === PLAYER_KNOCKED;
}

/**
 * Loads Google AdSense script
 */
export const loadGoogleAdSense = () => {
  const script = document.createElement("script");
  script.id = "google-adsense-script";
  script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2715134644484734";
  script.async = true;
  script.crossOrigin = "anonymous";

  document.body.appendChild(script);
}

/**
 * Unloads Google AdSense script
 */
export const unloadGoogleAdSense = () => {
  const script = document.getElementById("google-adsense-script");
  if (script) {
    script.remove();
  }
}