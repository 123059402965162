import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import { toast } from 'react-toastify';
import { publicQuery, privateQuery } from '../../graphql/requestHelper';
import { matchById } from '../../graphql/public/queries';
import {  
  REQUEST_MATCH,
  REQUEST_MATCH_PENDING,
  REQUEST_MATCH_SUCCESS,
  REQUEST_MATCH_ERROR
} from './types';
import { REQUEST_LOAD_MAP_CANVAS_MAP, PREPARE_NEW_CANVAS_STATE, SET_MAP_CANVAS_STATE, REQUEST_LOAD_STEERING_WHEEL, REQUEST_LOAD_TEAM_LOGO } from '../mapCanvasState/types';
import { ERROR_MATCH_ANALYZER_PARSING_ERROR } from '../../util/ErrorMessages';
import parseTelemetry from '../../telemetry/telemetryParser';
import { SET_MATCH_ANALYZER_MATCH } from '../matchAnalyzerState/types';
import { MATCH_ANALYSIS } from '../../util/Constants';
import { REQUEST_FILE_HIGHLIGHTS_LOADED } from '../fileState/types';
import { OPEN_ASIDE } from '../asideState/types';
import { highlightByFileId } from '../../graphql/private/queries';
import { requestTrack } from '../track/actions';
import { matchAnalyzerEvent } from '../../util/TrackUtils';
import { convertImgToBase64URL } from '../../util/ExportAsImageUtil';
import { getTeamImage } from '../../util/CommonUtils';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestMatch(action) {
  yield put({ type: REQUEST_MATCH_PENDING });
  yield put({ type: PREPARE_NEW_CANVAS_STATE });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    const id = action.payload.id;
    const fileId = action.payload.fileId;
    // Calling the server
    const match = (yield publicQuery(matchById, {
      game: selectedGame,
      id: id,
    })).data.matchById;
    const file = fileId ? (yield privateQuery(highlightByFileId, {
      game: selectedGame,
      fileId: fileId,
    })).data.highlightByFileId : null;

    // Downloading telemetry
    const response = yield fetch(match.telemetry + '.gz');
    if (response.ok) {
      const telemetryJson = yield response.text();
      const telemetry = parseTelemetry(JSON.parse(telemetryJson), match.gameCreatedAt, match.duration);

      // Setting map canvas state
      yield put({ type: REQUEST_LOAD_MAP_CANVAS_MAP, payload: match.map });
      yield put({ type: REQUEST_LOAD_STEERING_WHEEL });
      // Load team logo
      for (let i = 0; i < (match.teams || []).length; i++) {
        if (match.teams[i].name) {
          yield put({
            type: REQUEST_LOAD_TEAM_LOGO,
            payload: {
              image: yield convertImgToBase64URL(getTeamImage(match.teams[i].name)),
              slot: match.teams[i].slot,
            }
          });
        }
      }
      // Setting loaded match
      const matchName = generateMatchName(match);
      yield put({
        type: SET_MATCH_ANALYZER_MATCH,
        payload: {
          matchId: match.id,
          telemetry: telemetry,
          duration: match.duration,
          gameCreatedAt: match.gameCreatedAt,
          map: match.map,
          name: file && file.filename ? file.filename : matchName,
          zoneSizes: match.zones ? JSON.parse(match.zones) : [],
          teamNames: match.teams || [],
        }
      });

      yield put({ type: SET_MAP_CANVAS_STATE, payload: { mapType: MATCH_ANALYSIS } });
      yield put({ type: OPEN_ASIDE });
      if (file) {
        yield put({ type: REQUEST_FILE_HIGHLIGHTS_LOADED, payload: file });
      }
      
      // Triggering success action
      yield put({ 
        type: REQUEST_MATCH_SUCCESS, 
        payload: match
      });

      // Track user
      const matchAgeInDays = moment().diff(moment(match.gameCreatedAt), 'days');
      yield put(requestTrack(matchAnalyzerEvent(id, match.tournamentName, matchAgeInDays)));
    } else {
      console.log('weird')
      yield call(toast.error, ERROR_MATCH_ANALYZER_PARSING_ERROR);  
    }
  } catch (err) {
    console.log('err')
    console.log(err)
    yield call(toast.error, ERROR_MATCH_ANALYZER_PARSING_ERROR);
    yield put({ type: REQUEST_MATCH_ERROR });
  }
}

/**
 * Generates match name
 * 
 * @param {*} match 
 */
function generateMatchName(match) {
  let matchName = '';
  if (match.tournamentName) {
    matchName = match.tournamentName;
  }
  if (match.name) {
    matchName = matchName + ' - ' + match.name;
  }
  return matchName;
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_MATCH, requestMatch),
  ]);
}