import { 
  SET_LANGUAGE,
} from './types';
import lang from '../../assets/lang';
import Cookies from 'js-cookie';

const langaugeCookieName = 'twire-language';
const language = getSelectedLanguage();

const INITIAL_STATE = {
  lang: language ? language : 'en',
  translations: language ? lang[language] : lang.en,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On language set
    case SET_LANGUAGE:
      Cookies.set(langaugeCookieName, action.payload);
      return {
        ...state,
        lang: action.payload,
        translations: lang[action.payload],
      };
      
    default:
      return state;
  }
}

export default reducer;

/**
 * 
 * @returns Returns selected game
 */
function getSelectedLanguage() {
  const langFromUrl = window.location.href.split('/').find(s => lang[s]);
  if (lang[langFromUrl]) {
    Cookies.set(langaugeCookieName, langFromUrl);
    return langFromUrl;
  }
  return 'en';
}