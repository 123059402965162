import { all, takeLatest, put } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import {  
  GET_FAVORITE_USERS,
  GET_FAVORITE_USERS_SUCCESS,
  SET_FAVORITE_USER,
  SET_FAVORITE_USER_SUCCESS,
  REMOVE_FAVORITE_USER,
  REMOVE_FAVORITE_USER_SUCCESS,
} from './types';

/**
 * Gets users from favorites
 * 
 * @param {*} action 
 */
function* getFavoriteUsers(action) {
  const favoriteUsers = Cookies.get('favorite-users');
  yield put({ type: GET_FAVORITE_USERS_SUCCESS, payload: favoriteUsers ? favoriteUsers.split(',') : [] });
}

/**
 * Sets user to favorites
 * 
 * @param {*} action 
 */
function* setFavoriteUser(action) {
  const favoriteUsers = Cookies.get('favorite-users');
  const newFavoriteUsers = (favoriteUsers ? favoriteUsers + ',' : '') + action.payload;
  Cookies.set('favorite-users', newFavoriteUsers);
  yield put({ type: SET_FAVORITE_USER_SUCCESS, payload: newFavoriteUsers.split(',') });
}

/**
 * Removed user from favorites
 * 
 * @param {*} action 
 */
function* removeFavoriteUser(action) {
  const favoriteUsers = Cookies.get('favorite-users');
  const newFavoriteUsers = favoriteUsers.split(',').filter(u => u !== action.payload).join(',');
  Cookies.set('favorite-users', newFavoriteUsers);
  yield put({ type: REMOVE_FAVORITE_USER_SUCCESS, payload: newFavoriteUsers ? newFavoriteUsers.split(',') : [] });
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(GET_FAVORITE_USERS, getFavoriteUsers),
      takeLatest(SET_FAVORITE_USER, setFavoriteUser),
      takeLatest(REMOVE_FAVORITE_USER, removeFavoriteUser),
  ]);
}