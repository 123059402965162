// Error message for errors that occure when loading data from server
export const ERROR_LOADING_DATA = 'An error occurred. Please try again!';

// Errors for operations with files
export const ERROR_FILE_RENAME = 'Error while renaming. Please try again!';
export const ERROR_FILE_DELETE = 'Error while deleting. Please try again!';

// Verification error messages
export const ERROR_VERIFICATION_FAILED = 'Verification failed! Your link is broken, expired or already been verified.';
export const ERROR_VERIFICATION_RESEND_FAILED = 'Error while resending email. Please try again!';

// Forgot password error messages
export const ERROR_FORGOT_PASSWORD_SEND_FAILED = 'Error while sending email. Please try again!';
export const ERROR_FORGOT_PASSWORD_INVALID_USERNAME = 'Username cannot be empty';


// Reset password error messages
export const ERROR_RESET_PASSWORD_FALIED = 'Reseting your password failed. Please try again!';
export const ERROR_RESET_PASSWORD_NOT_MATCH = 'Passwords do not match';

// Change password error messages
export const ERROR_CHANGE_PASSWORD_FALIED = 'Changing your password failed. Please try again!';
export const ERROR_CHANGE_PASSWORD_INVALID_PASSWORD = 'Passwords must include minimum 8 characters, at least one letter and one number';
export const ERROR_CHANGE_PASSWORD_NOT_MATCH = 'Passwords do not match';

// Settings error messages
export const ERROR_SETTINGS_FAILED_TO_LOAD = 'Error while loading your settings. Please refresh page!';
export const ERROR_SETTINGS_FAILED_TO_SAVE = 'Error while saving your settings. Please try again!';
export const ERROR_SETTINGS_INVALID_PUBG_USERNAME = 'Username is invalid or already saved.';

// Match analyzer error messages
export const ERROR_MATCH_ANALYZER_PARSING_ERROR = 'Error while parsing telemetry.';

// Rotations analyzer error messages
export const ERROR_ROTATION_ANALYZER_LOADING_ERROR = 'Error while loading data. Try to refresh the page!';

// Saving error messages
export const ERROR_SAVE_FAILED = 'Error while saving. Please try again!';

// Saving payment messages
export const ERROR_SAVE_PAYMENT_FAILED = 'Error while saving your payment. Please contact us as soon as possible!';

// Error loading data, refresh required
export const ERROR_LOADING_DATA_REFRESH = 'Error loading data! Please refresh the page';

// Error loading data, refresh required
export const ERROR_PAYMENT_NO_PLAN = 'Error processing payment. Make sure that you have selected a plan.';

// Error saving new team
export const ERROR_SAVE_TEAM = 'Error while creating your team. Please try again.';
export const ERROR_SAVE_TEAMMATE = 'Error while adding teammate. Check if the username is correct.';

// Subscription to file error
export const ERROR_SUBSCRIPTION_TO_FILE = 'Error connecting to session. Please refresh the page.';

// User Matches error messages
export const ERROR_EMPTY_USERNAME = 'Username cannot be empty.';

// File system error messages
export const ERROR_EMPTY_FILENAME = 'File name cannot be empty.';

// Comment empty error message
export const ERROR_EMPTY_COMMENT = 'Comment cannot be empty.';