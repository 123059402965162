import { 
  REQUEST_SETTINGS_PENDING,
  REQUEST_SETTINGS_SUCCESS,
  REQUEST_SETTINGS_ERROR,
  REQUEST_SAVE_PUBG_USERNAME_SUCCESS,
  REQUEST_SAVE_PUBG_USERNAME_ERROR,
  REQUEST_SAVE_NOTIFICATIONS_SUCCESS,
  REQUEST_PROMO_CODE_USE_PENDING,
  REQUEST_PROMO_CODE_USE_SUCCESS,
  REQUEST_PROMO_CODE_USE_ERROR,
} from './types';

const INITIAL_STATE = {
  emailNotifications: false,
  pubgUsernames: null,
  settingsLoaded: false,
  settingsError: false,
  promoCodeLoaded: true,
  promoCodeSuccess: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On settings load in progress
    case REQUEST_SETTINGS_PENDING:
      return {
        ...state,
        pubgUsernames: null,
        settingsLoaded: false,
        settingsError: false
      };
      
    // On successfully loaded settings
    case REQUEST_SETTINGS_SUCCESS:
      return {
        ...state,
        pubgUsernames: action.payload.pubgUsernames,
        emailNotifications: action.payload.emailNotifications,
        settingsLoaded: true,
        settingsError: false
      };

    // On pubg username saved
    case REQUEST_SAVE_PUBG_USERNAME_SUCCESS:
      return {
        ...state,
        pubgUsernames: action.payload,
        settingsLoaded: true,
        settingsError: false
      }

    // On notifications saved
    case REQUEST_SAVE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        emailNotifications: action.payload,
        settingsLoaded: true,
        settingsError: false
      }

    // On settings load error
    case REQUEST_SETTINGS_ERROR:
    case REQUEST_SAVE_PUBG_USERNAME_ERROR:
      return {
        ...state,
        pubgUsernames: null,
        settingsLoaded: false,
        settingsError: true
      };

    // On promo code use pending
    case REQUEST_PROMO_CODE_USE_PENDING:
      return {
        ...state,
        promoCodeLoaded: false,
        promoCodeSuccess: false,
      }

    // On promo code use pending
    case REQUEST_PROMO_CODE_USE_SUCCESS:
      return {
        ...state,
        promoCodeLoaded: true,
        promoCodeSuccess: true,
      }

    // On promo code use pending
    case REQUEST_PROMO_CODE_USE_ERROR:
      return {
        ...state,
        promoCodeLoaded: true,
        promoCodeSuccess: false,
      }

    default:
      return state;
  }
}

export default reducer;