export default {
  menu: {
    matchAnalyzer: '매치 분석',
    tournaments: '토너먼트',
    news: '뉴스',
    pgcPoints: 'PGC Points',
    rankings: 'Rankings', // TODO missing
    scrims: '스크림',
    userMatches: '유저 매치',
    rotationAnalyzer: '이동경로 분석',
    teamsRotations: '팀 별 이동경로',
    saved: '저장된',
    savedMatches: '저장된 매치',
    productionOverlays: 'Production overlays',
    reportProblem: '이슈 리포트',
    discord: 'Twire 공식 디스코드에 참여하세요!',
    twitter: 'Twire 공식 트위터를 팔로우 해 주세요!'
  },
  headerMenu: {
    hi: '반가워요!',
    account: '계정',
    settings: '설정',
    contact: 'Contact us',
    logout: '로그아웃',
    logoutError: '로그아웃 과정에서 에러가 발생했습니다. 다시 한번 시도해주세요',
    goPremium: '프리미엄결제',
  },
  common: {
    save: '저장',
    edit: '수정',
    delete: '삭제',
    cancel: '취소',
    search: '검색',
    ok: '확인',
    showMore: '더 보기',
    close: '닫기',
    loading: '불러오는 중...',
    learnMore: '더 확인하기',
    beta: '베타',
    warning: '경고',
    goTo: 'Go to',
    goToApp: '어플리케이션으로 이동',
    login: '로그인',
    pleaseWait: 'Please wait...',
    success: '성공했습니다!',
    signUp: '회원 가입',
  },
  settings: {
    basicSettings: '기본 설정',
    username: '아이디',
    email: '이메일',
    subscription: '구독',
    plan: '플랜',
    validTo: '유효기간',
    freePlan: '무료 플랜',
    trialPlan: 'Trial',
    premiumPlan: '프리미엄',
    manageSubscription: 'PayPal 계정으로 구독 플랜을 관리할 수 있습니다.',
    pubgUsernames: 'PUBG 인게임 아이디 (PUBG PC)',
    pubgUsername: 'PUBG 인게임 아이디',
    enterPubgUsername: '인게임 아이디 (대소문자 구분)',
    status: '상태',
    pendingConfirmation: '결제 확인 대기 중',
    invlid: '유효하지 않음',
    active: '활성화',
    password: '비밀번호',
    notifications: '알림',
    emailNotifications: '이메일 알림',
    currentPassword: '현재 비밀번호',
    enterCurrentPassword: '새로 설정할 비밀번호를 입력하세요.',
    newPassword: '신규 비밀번호',
    enterNewPassword: '새로 설정한 비밀번호를 입력하세요.',
    passwordConfirmation: '비밀번호 확인',
    enterPasswordConfirmation: '새로 설정할 비밀번호를 다시 입력하세요.',
    changePassword: '비밀번호 변경',
    promoCode: '프로모션 코드',
    use: '사용',
    wrongPromoCode: '잘못된 프로모션 코드',
    promoCodeSuccess: '프로모션 코드가 성공적으로 사용되었습니다. 페이지가 새로고침됩니다.'
  },
  regions: {
    allRegions: '전체  지역',
    global: '글로벌',
    asia: '아시아',
    europe: '유럽',
    southAmerica: '남미',
    northAmerica: '북미',
    oceania: '오세아니아',
    apac: '아시아-태평양 지역',
  },
  tournamentSearch: {
    tournamentFilter: '토너먼트 필터',
    tournamentName: '토너먼트명',
    clearFilter: '필터 설정 초기화',
    missingTournament: '누락된 토너먼트가 있나요?',
    recentTournaments: '최근 진행 토너먼트',
    allTournaments: '전체 토너먼트',
    tournament: '토너먼트',
    createTournament: '토너먼트 만들기',
    noTournaments: '토너먼트 없음'
  },
  content: {
    title: '뉴스',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: '댓글 달기',
    newCommentPlaceholder: '당신의 댓글'
  },
  tournament: {
    tournament: '토너먼트',
    tournamentInfo: '토너먼트 정보',
    region: '지역',
    matches: '매치',
    leaderboards: '리더보드',
    prizeLeaderboards: '상금 리더보드',
    playerStats: '플레이어 스텟',
    teamStats: 'Team Stats',
    selectTournament: '토너먼트',
    noMatchesFound: '매치가 존재하지 않습니다.',
    noLeaderboards: '해당 토너먼트는 리더보드를 지원하지 않습니다.',
    leaderboardsNotFound: '리더보드가 존재하지 않거나 아직 경기가 진행되지 않았습니다. 잠시 후 다시 시도하세요.',
    noPlayerStats: '해당 토너먼트에는 플레이어 스텟을 제공하지 않습니다.',
    playerStatsNotFound: '플레이어 스텟이 존재하지 않거나 아직 경기가 진행되지 않았습니다. 잠시 후 다시 시도하세요.',
    tournamentPhase: '토너먼트 페이즈',
    selectTournamentPhase: '토너먼트 페이즈 선택',
    loadingMatches: '경기를 불러오고 있습니다....',
  },
  scrimSearch: {
    scrims: '스크림',
    scrim: '스크림',
  },
  userMatches: {
    searchByUsername: '인게임 아이디로 검색',
    pubgUsername: '인게임 아이디 (대소문자 구분)',
    matchesOf: '매치',
    enterPubgUsername: '인게임 아이디를 입력하세요.',
    usernameNotTracked: '모든 매치를 수집하지 않고 있어 해당 유저에 대한 매치 추적이 불가능합니다.',
    settings: '설정 에서 아이디를 추가하세요',
    favoriteUsers: '즐겨 찾기',
  },
  match: {
    rank: '랭크',
    date: '날짜',
    match: '매치',
    map: '맵',
    type: '종류',
    zone: '안전 지역 방향',
    analyzeStats: '분석/스텟',
    mode: '모드',
    duration: '경기 시간',
  },
  matchDetails: {
    matchDetails: '매치 정보',
    matchStats: '매치 스텟',
    match: '매치',
    map: '맵',
    mode: '모드',
    duration: '경기 시간',
  },
  matchStats: {
    rank: '순위',
    wins: '치킨',
    totalPoints: 'Total Points',
    avgTotalPoints: 'Avg Total Points',
    avgRank: '평균 순위',
    points: '평균 포인트',
    avgPoints: '평균 순위 포인트',
    twr: 'TWR',
    numOfMatches: '매치 수',
    team: '팀',
    player: '플레이어',
    username: '플레이어',
    kills: '킬',
    assists: '어시스트',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: '총 피해량',
    avgDamageDealt: '평균 피해량',
    damageTaken: '받은 피해량',
    avgDamageTaken: '평균 받은 피해량',
    dbnos: '기절 시킨 횟수',
    revives: '부활 시킨 횟수',
    headshotKills: '헤드샷 킬',
    longestKill: '최장거리 킬',
    killSteals: '킬 스킬',
    killsStolenFrom: '빼앗긴 킬',
    grenadePickup: '획득한 수류탄',
    grenadeDrop: '버린 수류탄',
    grenadeThrow: '사용한 수류탄',
    grenadeDamage: '수류탄 데미지',
    molotovPickup: '획득한 화염병',
    molotovDrop: '버린 화염병',
    molotovThrow: '사용한 화염병',
    molotovDamage: '화염병 데미지',
    smokebombPickup: '획득한 연막탄',
    smokebombDrop: '버린 연막탄',
    smokebombThrow: '사용한 연막탄',
    flashbangPickup: '획득한 섬광탄',
    flashbangDrop: '버린 섬광탄',
    flashbangThrow: '사용한 섬광탄',
    swimDistance: '수영 거리',
    walkDistance: '걸어간 거리',
    rideDistance: '탈 것으로 이동한 거리',
    timeSurvived: '생존 시간',
    avgTimeSurvived: '평균 생존 시간',
    killStreaks: '최다 연속 킬',
    deathType: '사인',
    heals: '회복 횟수',
    healthRecovered: '회복량',
    boosts: '부스트 아이템 사용 횟수',
    vehicleDestroys: '차량 파괴 횟수',
    kd: 'KD',
    killsKnocks: '킬 / 기절 시킨 횟수',
    arDamage: '총 피해량 (AR)', // TODO missing
    dmrDamage: '총 피해량 (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: '총 피해량 / 받은 피해량',
    damageTakenFromBlueZone: '받은 피해량 (Zone)', // TODO missing
    damageTakenFromEnemy: '받은 피해량 (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire 점수',
    stolenKillsExplained: '빼앗은 킬 (다른 플레이어가 기절 시킨 플레이어를 사살한 경우)',
    stolenKillsFromExplaines: '빼앗긴 킬 (내가 기절 시킨 플레이어를 다른 플레이어가 사살한 경우)',
    kasExplaines: 'KAS (안정성/지속성) - 플레이어가 킬, 어시스트, 생존에 성공한 라운드 수', // TODO missing
  },
  tools: {
    save: '저장',
    undo: '되돌리기',
    clearAll: '초기화',
    zonesToDraw: '구역 그리기',
    pen: '펜',
    playerDetails: '플레이어 정보',
    rotationDetails: '이동경로 정보',
    playerNames: '플레이어 명',
    teamNames: '팀 명',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: '사망한 플레이어 표시',
    grid: '격자선',
    planePath: '비행기 경로',
    showPlanePaths: '비행기 경로 표시',
    showRotations: '이동거리 표시',
    showCircles: '자기장 표시하기',
    showLandingSpots: '낙하 지점 표시',
    heatmap: 'Heatmap', // TODO missing
    help: '도움말',
  },
  help: {
    help: '도움말',
    tutorial: '튜토리얼',
    runTutorial: '튜토리얼 진행',
    shortcuts: '단축키',
    keyBinding: '키 바인딩',
    description: '설명',
    playPause: '재생/정지',
    stepBackward: '뒤로 감기',
    stepForward: '앞으로 감기',
    previousSpeed: '느리게',
    nextSpeed: '빠르게',
    gird: '격자선 표시하기/감추기',
    teamNames: '팀 명 표시하기/감추기',
    playerNames: '플레이어 아이디 표시하기/감추기',
    deadPlayers: '사망 플레이어 표시하기/감추기',
    planePath: '비행기 경로 표시하기/감추기',
    playerDetails: '플레이어 상세 정보 열기/닫기',
    rotationDetails: '이동경로 상세 정보 열기/닫기',
    fullscreen: '전체 화면 ON/OFF',
  },
  matchAnalyzer: {
    names: '아이디',
    trace: '추적',
    emptyInventory: '인벤토리가 비었습니다.',
  },
  teamsSearch: {
    searchByTeam: '팀으로 검색',
    teamNameOrInitial: '팀 명 / 팀 약자',
    teams: '팀',
    team: '팀',
    noResults: '결과 없음',
    oldRotationAnalyzer: '前のバージョン',
    tryNow: '체험하기',
  },
  rotationAnalyzer: {
    rotationAnalyzer: '이동경로 분석',
    selectMap: '맵 선택',
    match: '매치',
    hideAll: '전부 표시하지 않기',
    show: '표시하기',
    rank: '순위',
    kills: '킬',
    damageDealt: '데미지 량',
    damageTaken: '피해량',
    zoneDirection: '안전 지역 방향',
    phases: '페이즈',
    walking: '걸어서 이동한 거리',
    driving: '차량 이동 거리',
    endingZoneArea: '엔딩 위치',
    selectZoneArea: '안전 지역 위치 선택',
    selectTeam: '최소 한 팀 이상 추가하세요',
    zone: '안전 지역',
    reset: '리셋',
    resetWarning: '추가한 모든 이동경로를 제거하고 선택한 안전 지역 위치를 초기화합니다.',
    teams: '팀',
    add: '추가하기',
    noTeams: '팀이 선택되지 않았습니다',
  },
  saved: {
    noPremium: '해당 기능은 프리미엄 플랜 구독 유저만 사용할 수 있습니다. 기존의 저장한 매치는 그대로 확인할 수 있으나 새로운 매치를 저장할 수 없습니다.',
    savedMatches: '저장된 매치',
    rename: '이름 변경',
    delete: '삭제',
    renameFile: '파일 이름 변경',
    newFileName: '새로운 파일 이름',
    fileDelete: '파일 삭제',
    fileDeleteWarning: '정말 삭제하시겠습니까?',
    matchAnalysis: '매치 분석',
    modalRenameTitle: '매치 이름 변경',
    modalRenamePlaceholder: '새로운 매치 이름',
    modalDeleteTitle: '매치 삭제',
    modalDeletePlaceholder: '정말 이 매치를 삭제하시겠습니까?',
  },
  connect: {
    title: '우리와 함께하세요!',
    discordConnectText: '디스코드로 소통해요!',
    connectMe: '소통해요!',
  },
  features: {
    title: '당신을 도울 매력적인 기능들',
    feature1Title: '토너먼트 리더보드',
    feature1Text: '세계각지의 토너먼트들을 확인해 보세요',
    feature2Title: '선수들\' 스텟',
    feature2Text: '여러가지 방법으로 선수들과 그들의 능력치를 비교해보세요',
    feature3Title: '매치 분석',
    feature3Text: '매치 분석과 원격 협력 작업을 통해 팀의 전략을 한층 강화시켜줍니다',
    feature4Title: '이동경로 분석',
    feature4Text: '상대 팀의 이동경로를 파악하고 그에 맞춰 전략을 준비하세요',
  },
  games: {
    title: '어떤 게임을 플레이하시나요?',
    availableNow: '사용 가능',
    comingSoon: '추가 예정',
  },
  partners: {
    title: '파트너',
  },
  pricing: {
    title: '가격',
    advertisement: '광고',
    matchAnalyzer: '매치 분석',
    rotationAnalyzer: '이동경로 분석',
    drawing: '드로잉 기능',
    collaborativeDrawing: '협동 드로잉 기능',
    savingMatches: '매치 저장',
    premium: '프리미엄',
    freePeriod: '14일 무료 체험판',
    paidMonthly: '월별 결제',
    paidQuarterly: '분기별 결제',
    noCardRequired: '카드를 필요로 하지 않습니다.',
    planContact1: '개별 플랜이 필요한 경우 디스코드 또는 team@twire.gg로 연락 해주십시오.',
    planContact2: 'or via',
    planContact3: '',
    free: '무료',
  },
  payment: {
    title: '가격',
    saved: '저장된',
    ads: '광고를 제거하다',
    analyzerMapReplay: '맵 리플레이 분석',
    seeTeamsRotations: '팀 별 이동경로 보기',
    workCollaboratively: '협동 작업',
    shareDrawingInRealTime: '실시간으로 중요한 내용을 공유하세요',
    drawing: '드로잉',
    markImportantMoments: '중요한 순간 책갈피',
    saveitForLater: '저장하기',
    recommended: '추천',
    billedQuarterly: '분기별 지불',
    billedMonthly: '월별 지불',
    selectPlan: '플랜 선택',
    billedNow: '지금 지불하기',
    alreadyPremium: '이미 프리미엄 멤버로 등록되어있는 유저입니다.',
    expiresInDays: '14일 간 무료로 체험하기',
    learnMore: '더 보기',
  },
  welcome: {
    title1: 'ESPORTS 커리어를',
    title2: 'Twire로 시작하세요',
    subtitle: '승리를 위한 분석',
    register: '무료 회원가입',
    learnMore: '더 많은 정보를 확인하기',
  },
  forgotPassword: {
    emailSent: '이메일 전송 완료!',
    emailSentMessage: '비밀번호 재설정 링크가 당신의 이메일로 전송 되었습니다.',
    forgotPassword: '비밀번호를 잊으셨나요?',
    inputUsername: '아이디를 입력하세요.',
    sentResetLink: '비밀번호 초기화 전송',
  },
  login: {
    signUp: '회원 가입',
    dontHaveAccountLong: '아직 계정이 없으신가요? 지금 바로 회원가입을 통해 저희의 멋진 기능으로 당신의 팀 전략을 강화하세요!',
    dontHaveAccount: '아직 계정이 없으신가요?',
    registerNow: '회원가입!',
    login: '로그인',
    resetPassword: '비밀번호 재설정',
    reset: '재설정',
    signInAccount: '보유중인 계정으로 로그인',
    passwordResetNeeded: '비밀번호 재설정이 필요합니다',
    username: '아이디',
    password: '비밀번호',
    passwordConfirmation: '비밀번호 확인',
    forgotPassword: '비밀번호를 잊으셨나요?',
    resetPasswordRequired: '비밀번호 재설정이 필요합니다',
    wrongCredentials: '아이디 또는 비밀번호가 일치하지 않습니다',
    passwordsDontMatch: '비밀전호 불일치',
    passwordToShort: '비밀번호는 8글자 이상으로, 글자와 문자가 모두 포함되어야 합니다.',
  },
  register: {
    register: '등록',
    thankYou: '감사합니다!',
    thankYouLong: '회원가입을 해주셔서 감사합니다! 이메일 확인을 통해 회원가입을 마치실 수 있습니다.',
    username: '아이디',
    email: '이메일',
    password: '패스워드',
    repeatPassword: '비밀번호 확인',
    subscribeToNewsletter: '정기 뉴스를 구독하기',
    readAndUnderstand: '',
    termsOfUse: '이용 약관을 읽고 이해했습니다.',
    createAccount: '계정 생성',
    alreadyRegistered: '이미 계정이 있으신가요?',
    invalidUsername: '일치하지 않는 아이디',
    invalidPassword: '일치하지 않는 비밀번호',
    passwordsDontMatch: '비밀전호 불일치',
    termsRequired: '사용약관에 동의하셔야 합니다.',
  },
  resetPassword: {
    resetPassword: '비밀번호 변경',
    selectNewPassword: '신규 비밀번호',
    password: '패스워드',
    passwordConfirmation: '비밀번호 확인',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: '인증 실패. 링크가 잘못되었거나, 기한이 지났거나 이미 인증 되었습니다. 다시 한번 확인해주세요',
    resendEmail: '이메일 재전송',
    verifyingEmail: '이메일 확인 중...',
    verificationSuccess: '인증 완료! 지금부터 로그인 할 수 있습니다.',
  }
}