import { TOURNAMENT_EVENT_TYPE, SCRIM_EVENT_TYPE } from './Constants';

// Page visits constants
export const HOME_PAGE_VISIT = 'Home';
export const REGISTER_PAGE_VISIT = 'Register';
export const LOGIN_PAGE_VISIT = 'Login';
export const PRICING_PAGE_VISIT = 'Pricing';
export const MATCH_ANALYZER_VISIT = 'Match Analyzer';
export const ROTATION_ANALYZER_VISIT = 'Rotation Analyzer';
export const ROTATION_ANALYZER_V2_VISIT = 'Rotation Analyzer v2';
export const TOURNAMENTS_VISIT = 'Tournaments';
export const SCRIMS_VISIT = 'Scrims';
export const USER_MATCHES_VISIT = 'User matches';

// Game names
const GAME_PUBG = 'PUBG';

// Types of matches for matches
export const MATCH_TYPE_TOURNAMENT = 'Tournament';
export const MATCH_TYPE_SCRIM = 'Scrim';
export const MATCH_TYPE_USERNAME = 'Username';

// Tool names
export const MATCH_ANALYZER_TOOL = 'Match Analyzer';
export const ROTATION_ANALYZER_TOOL_V2 = 'Rotation Analyzer v2';

// Tournaments by region page view event
const TOURNAMENTS_BY_REGION = 'Tournaments';
// Scrims by region page view event
const SCRIMS_BY_REGION = 'Scrims';
/**
 * Returns an object that represents user's
 * page visit of tournaments/scrims by region event
 * 
 * @param {*} region selected region
 * @param {*} eventType determines if event is tournament of scrim
 */
export const tournamentsByRegionEvent = (region, eventType) => {
  return addGeneralPropertiesToEvent({
    event: eventType === TOURNAMENT_EVENT_TYPE ? TOURNAMENTS_BY_REGION : eventType === SCRIM_EVENT_TYPE ? SCRIMS_BY_REGION : null,
    properties: {
      region: region,
    },
  });
}

// Tournament matches searched
const TOURNAMENT_MATCHES = 'Tournament matches';
// Scrim matches searched
const SCRIM_MATCHES = 'Scrim matches';
/**
 * Returns an object that represents user's
 * click on one of the tournaments/scrims
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} filterUsed filter used 
 * @param {*} shardInfo shard info of the match 
 * @param {*} region region of tournament
 * @param {*} eventType type of the event
 */
export const tournamentMatchesEvent = (tournamentName, filterUsed, shardInfo, region, eventType) => {
  return addGeneralPropertiesToEvent({
    event: eventType === TOURNAMENT_EVENT_TYPE ? TOURNAMENT_MATCHES : eventType === SCRIM_EVENT_TYPE ? SCRIM_MATCHES : null,
    properties: {
      name: tournamentName,
      filterUsed: filterUsed,
      shardInfo: shardInfo,
      region: region,
    },
  });
}

// Tournament leaderboard open
const TOURNAMENT_LEADERBOARDS_OPEN = 'Tournament Leaderboards Open';
/**
 * Returns an object that represents user's
 * click on tournament leaderboards tab
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} region region of tournament
 */
export const tournamentLeaderboardsOpenEvent = (tournamentName, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_LEADERBOARDS_OPEN,
    properties: {
      tournamentName: tournamentName,
      region: region,
    },
  });
}

// Tournament leaderboard show
const TOURNAMENT_LEADERBOARD_SHOW = 'Tournament Leaderboard Show';
/**
 * Returns an object that represents user's
 * click on a specific tournament leaderboard
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} leaderboardName name of the leaderboard 
 * @param {*} shardInfo leaderboard shard info
 * @param {*} region region of tournament
 */
export const tournamentLeaderboardShowEvent = (tournamentName, leaderboardName, shardInfo, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_LEADERBOARD_SHOW,
    properties: {
      tournamentName: tournamentName,
      leaderboardName: leaderboardName,
      shardInfo: shardInfo,
      region: region,
    },
  });
}

// Tournament stats search
const TOURNAMENT_STATS_OPEN = 'Tournament Stats Open';
/**
 * Returns an object that represents user's
 * click on tournament stats tab
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} region region of tournament
 */
export const tournamentStatsOpenEvent = (tournamentName, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_STATS_OPEN,
    properties: {
      tournamentName: tournamentName,
      region: region,
    },
  });
}

// Tournament stats search
const TOURNAMENT_STATS_SHOW = 'Tournament Stats Show';
/**
 * Returns an object that represents user's
 * click on tournament's stats
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} statsName name of the stats 
 * @param {*} shardInfo stats shard info 
 * @param {*} region region of tournament
 */
export const tournamentStatsShowEvent = (tournamentName, statsName, shardInfo, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_STATS_SHOW,
    properties: {
      tournamentName: tournamentName,
      statsName: statsName,
      shardInfo: shardInfo,
      region: region,
    },
  });
}

// Tournament team stats search
const TOURNAMENT_TEAM_STATS_OPEN = 'Tournament Team Stats Open';
/**
 * Returns an object that represents user's
 * click on tournament team stats tab
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} region region of tournament
 */
export const tournamentTeamStatsOpenEvent = (tournamentName, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_TEAM_STATS_OPEN,
    properties: {
      tournamentName: tournamentName,
      region: region,
    },
  });
}

// Tournament team stats search
const TOURNAMENT_TEAM_STATS_SHOW = 'Tournament Team Stats Show';
/**
 * Returns an object that represents user's
 * click on tournament's team stats
 * 
 * @param {*} tournamentName name of the tournament 
 * @param {*} statsName name of the stats 
 * @param {*} shardInfo stats shard info 
 * @param {*} region region of tournament
 */
export const tournamentTeamStatsShowEvent = (tournamentName, statsName, shardInfo, region) => {
  return addGeneralPropertiesToEvent({
    event: TOURNAMENT_TEAM_STATS_SHOW,
    properties: {
      tournamentName: tournamentName,
      statsName: statsName,
      shardInfo: shardInfo,
      region: region,
    },
  });
}

// User matches searched
const USERNAME_MATCHES = 'Username matches';
/**
 * Returns an object that represents user's
 * search for some username matches
 * 
 * @param {*} username username that is being searched 
 * @param {*} usernameFound true if at least one result was found
 */
export const usernameMatchesEvent = (username, usernameFound) => {
  return addGeneralPropertiesToEvent({
    event: USERNAME_MATCHES,
    properties: {
      searchUsername: username,
      usernameFound: usernameFound,
    },
  });
}

// Match Analyzer opened
const MATCH_ANALYZER = 'Match Analyzer';
/**
 * Returns an object that represents opening
 * of the match analyzer tool
 * 
 * @param {*} matchId id of the match
 * @param {*} tournamentName name of the tourament
 * @param {*} matchAgeInDays age of the match in days
 */
export const matchAnalyzerEvent = (matchId, tournamentName, matchAgeInDays) => {
  return addGeneralPropertiesToEvent({
    event: MATCH_ANALYZER,
    properties: {
      matchId: matchId,
      tournamentName: tournamentName,
      matchAgeInDays: matchAgeInDays,
    },
  });
}

// Toggle trace in match analyzer event
const SHOW_TEAM_TRACE = 'Show Team Trace';
/**
 * Returns an object that represents toggling
 * team's trace in match analyzer
 * 
 * @param {*} matchId id of the match
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const showTeamTraceEvent = (matchId, tool) => {
  return addGeneralPropertiesToEvent({
    event: SHOW_TEAM_TRACE,
    properties: {
      matchId: matchId,
      tool: tool,
    },
  });
}

// Toggle trace in match analyzer event
const SHOW_TEAM_PLAYER_NAMES = 'Show Team Player Names';
/**
 * Returns an object that represents toggling
 * team's player names in match analyzer
 * 
 * @param {*} matchId id of the match
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const showTeamPlayerNamesEvent = (matchId, tool) => {
  return addGeneralPropertiesToEvent({
    event: SHOW_TEAM_PLAYER_NAMES,
    properties: {
      matchId: matchId,
      tool: tool,
    },
  });
}

// Open match statistics event
const MATCH_STATISTICS = 'Match Statistics';
/**
 * Returns an object that represents opening
 * match statistics
 * 
 * @param {*} matchId id of the match
 */
export const matchStatisticsEvent = (matchId) => {
  return addGeneralPropertiesToEvent({
    event: MATCH_STATISTICS,
    properties: {
      matchId: matchId,
    },
  });
}

// Open rotation analyzer event
const ROTATION_ANALYZER = 'Rotation Analyzer';
/**
 * Returns an object that represents opening
 * rotation analyzer
 * 
 * @param {*} team represents team id for which rotations are displayed
 */
export const rotationAnalyzerEvent = (team) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER,
    properties: {
      team: team,
    },
  });
}

// Open rotation analyzer V2 event
const ROTATION_ANALYZER_V2 = 'Rotation Analyzer V2';
/**
 * Returns an object that represents opening
 * rotation analyzer V2
 * 
 * @param {*} map represents map that was selected
 */
export const rotationAnalyzerV2Event = (map) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2,
    properties: {
      map: map,
    },
  });
}

// Set rotation analyzer V2 zone
const ROTATION_ANALYZER_V2_ZONE = 'Rotation Analyzer V2 Zone';
/**
 * Returns an object that represents setting
 * rotation analyzer V2 zone
 * 
 * @param {*} set represents if user has set the zone
 */
export const rotationAnalyzerV2ZoneEvent = (set) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2_ZONE,
    properties: {
      set: set,
    },
  });
}

// Set rotation analyzer V2 zone
const ROTATION_ANALYZER_V2_ZONE_RESET = 'Rotation Analyzer V2 Zone Reset';
/**
 * Returns an object that represents resetting
 * rotation analyzer V2 zone
 */
export const rotationAnalyzerV2ZoneResetEvent = () => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2_ZONE_RESET,
    properties: {
    },
  });
}

// Add team to rotation analyzer V2
const ROTATION_ANALYZER_V2_ADD_TEAM = 'Rotation Analyzer V2 Add Team';
/**
 * Returns an object that represents addition
 * of a team to rotation analyzer V2
 * 
 * @param {*} team represents name of the team that was added
 */
export const rotationAnalyzerV2AddTeamEvent = (team) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2_ADD_TEAM,
    properties: {
      team: team,
    },
  });
}

// Remove team from rotation analyzer V2
const ROTATION_ANALYZER_V2_REMOVE_TEAM = 'Rotation Analyzer V2 Remove Team';
/**
 * Returns an object that represents removing
 * of a team to rotation analyzer V2
 * 
 * @param {*} team represents name of the team that was removed
 */
export const rotationAnalyzerV2RemoveTeamEvent = (team) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2_REMOVE_TEAM,
    properties: {
      team: team,
    },
  });
}

// Go to match analyzer from rotation anaylzer V3
const ROTATION_ANALYZER_V2_OPEN_MATCH = 'Rotation Analyzer V2 Open Match';
/**
 * Returns an object that represents opening
 * of a match from rotation analyzer V2
 * 
 * @param {*} match represents name of the match that was opened
 */
export const rotationAnalyzerV2OpenMatchEvent = (match) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_ANALYZER_V2_OPEN_MATCH,
    properties: {
      match: match,
    },
  });
}

// Map switch in rotation analyzer event
const ROTATION_MAP_SWITCH = 'Rotation Map Switch';
/**
 * Returns an object that represents switching
 * between maps in rotation analyzer
 * 
 * @param {*} map map to which user switched
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const rotationMapSwitchEvent = (map, tool) => {
  return addGeneralPropertiesToEvent({
    event: ROTATION_MAP_SWITCH,
    properties: {
      map: map,
      tool: tool,
    },
  });
}

// Show specific match in rotation analyzer event
const SHOW_ROTATION = 'Show Rotation';
/**
 * Returns an object that represents toggling
 * on and off specific match in rotation analyzer
 * 
 * @param {*} matchId id of the match that was displayed
 * @param {*} team team id of which match was toggled
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const showRotationEvent = (matchId, team, tool) => {
  return addGeneralPropertiesToEvent({
    event: SHOW_ROTATION,
    properties: {
      matchId: matchId,
      team: team,
      tool: tool,
    },
  });
}

// Show specific match phase in rotation analyzer event
const SHOW_ROTATION_PHASE = 'Show Rotation Phase';
/**
 * Returns an object that represents toggling
 * on and off specific match in rotation analyzer
 * 
 * @param {*} matchId id of the match that was displayed
 * @param {*} team team id of which match phase was toggled
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const showRotationPhaseEvent = (matchId, team, tool) => {
  return addGeneralPropertiesToEvent({
    event: SHOW_ROTATION_PHASE,
    properties: {
      matchId: matchId,
      team: team,
      tool: tool,
    },
  });
}

// File system opened event
const FILE_SYSTEM = 'File System';
/**
 * Returns an object that represents opening
 * file system feature
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const fileSystemEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: FILE_SYSTEM,
    properties: {
      tool: tool,
    },
  });
}

// Open File event
const OPEN_FILE = 'Open File';
/**
 * Returns an object that represents opening the file
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const openFileEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: OPEN_FILE,
    properties: {
      tool: tool,
    },
  });
}

// Rename File event
const RENAME_FILE = 'Rename File';
/**
 * Returns an object that represents renaming the file
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const renameFileEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: RENAME_FILE,
    properties: {
      tool: tool,
    },
  });
}

// Delete File event
const DELETE_FILE = 'Delete File';
/**
 * Returns an object that represents deleting the file
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const deleteFileEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: DELETE_FILE,
    properties: {
      tool: tool,
    },
  });
}

// Save File event
const SAVE_FILE = 'Save File';
/**
 * Returns an object that represents saving the file
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const saveFileEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: SAVE_FILE,
    properties: {
      tool: tool,
    },
  });
}

// Share File event
const SHARE_FILE = 'Share File';
/**
 * Returns an object that represents sharing the file
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 * @param {*} type how the file was shared (public, private)
 */
export const shareFileEvent = (tool, type) => {
  return addGeneralPropertiesToEvent({
    event: SHARE_FILE,
    properties: {
      tool: tool,
      type: type,
    },
  });
}

// Draw zone event
const DRAW_ZONE = 'Draw Zone';
/**
 * Returns an object that represents drawing
 * 
 * @param {*} premium if user is premium
 */
export const drawZoneEvent = (premium) => {
  return addGeneralPropertiesToEvent({
    event: DRAW_ZONE,
    properties: {
      premium: premium,
    },
  });
}

// Drawing event
const DRAWING = 'Drawing';
/**
 * Returns an object that represents drawing
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 * @param {*} premium if user is premium
 */
export const drawingEvent = (tool, premium) => {
  return addGeneralPropertiesToEvent({
    event: DRAWING,
    properties: {
      tool: tool,
      premium: premium,
    },
  });
}

// Drawing color switch event
const DRAWING_COLOR_SWITCH = 'Drawing Color Switch';
/**
 * Returns an object that represents drawing color switch
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const drawingColorSwitchEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: DRAWING_COLOR_SWITCH,
    properties: {
      tool: tool,
    },
  });
}

// Drawing undo event
const DRAWING_UNDO = 'Drawing Undo';
/**
 * Returns an object that represents drawing undo
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const drawingUndoEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: DRAWING_UNDO,
    properties: {
      tool: tool,
    },
  });
}

// Drawing clear all event
const DRAWING_CLEAR_ALL = 'Drawing Clear All';
/**
 * Returns an object that represents drawing clear all
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const drawingClearAllEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: DRAWING_CLEAR_ALL,
    properties: {
      tool: tool,
    },
  });
}

// Show highlight event
const SHOW_HIGHLIGHT = 'Show Highlight';
/**
 * Returns an object that represents show highlight
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const showHighlightEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: SHOW_HIGHLIGHT,
    properties: {
      tool: tool,
    },
  });
}

// Full screen event
const FULL_SCREEN = 'Full screen';
/**
 * Returns an object that represents full screen toggle
 * 
 * @param {*} tool name of the tool in which event took place (Match Analyzer or Rotation Analyzer)
 */
export const fullScreenEvent = (tool) => {
  return addGeneralPropertiesToEvent({
    event: FULL_SCREEN,
    properties: {
      tool: tool,
    },
  });
}

// Settings open event
const SETTINGS = 'Settings';
/**
 * Returns an object that represents opening settings
 */
export const settingsEvent = () => {
  return addGeneralPropertiesToEvent({
    event: SETTINGS,
    properties: {},
  });
}

// Subscription modal open event
const SUBSCRIPTION_DIALOG_OPEN = 'Subscription Pop-up Open';
/**
 * Returns an object that represents opening subscription dialog
 */
export const subscriptionDialogOpen = () => {
  return addGeneralPropertiesToEvent({
    event: SUBSCRIPTION_DIALOG_OPEN,
    properties: {},
  });
}

// Subscription modal close event
const SUBSCRIPTION_DIALOG_CLOSE = 'Subscription Pop-up Close';
/**
 * Returns an object that represents closing subscription dialog
 */
export const subscriptionDialogClose = () => {
  return addGeneralPropertiesToEvent({
    event: SUBSCRIPTION_DIALOG_CLOSE,
    properties: {},
  });
}

// Subscription plan selected event
const SUBSCRIPTION_PLAN_SELECTED = 'Subscription Plan Selected';
/**
 * Returns an object that represents selection of subscription plan
 */
export const subscriptionPlanSelected = (months) => {
  return addGeneralPropertiesToEvent({
    event: SUBSCRIPTION_PLAN_SELECTED,
    properties: {
      months: months
    },
  });
}

// Subscription created event
const SUBSCRIPTION_CREATED = 'Subscription Created (Frontend)';
/**
 * Returns an object that represents subscription created
 */
export const subscriptionCreated = () => {
  return addGeneralPropertiesToEvent({
    event: SUBSCRIPTION_CREATED,
    properties: {
      provider: 'PayPal',
    },
  });
}

// Premium feature click event
const PREMIUM_FEATURE_CLICK = 'Premium feature click';
/**
 * Returns an object that represents click on premium feature
 */
export const premiumFeatureClick = (properties) => {
  return addGeneralPropertiesToEvent({
    event: PREMIUM_FEATURE_CLICK,
    properties: properties,
  });
}

// Export as image
const LEADERBOARD_AS_IMAGE_CLICK = 'Leaderboard as image clicked';
/**
 * Returns an object that represents click on share button for exporting leaderboard
 */
export const leaderboardAsImageClicked = (properties = {}) => {
  return addGeneralPropertiesToEvent({
    event: LEADERBOARD_AS_IMAGE_CLICK,
    properties: properties,
  });
}

// Export as image
const LEADERBOARD_AS_IMAGE_EXPORT = 'Leaderboard as image export';
/**
 * Returns an object that represents click on export as png leaderboard
 */
export const leaderboardAsImageExport = (properties = {}) => {
  return addGeneralPropertiesToEvent({
    event: LEADERBOARD_AS_IMAGE_EXPORT,
    properties: properties,
  });
}

// PGC Points View
const PGC_POINTS_VIEW = 'PGC Points View';
/**
 * Returns an object that represents user viewing a PGC Ponts page
 */
export const pgcPointsView = (properties = {}) => {
  return addGeneralPropertiesToEvent({
    event: PGC_POINTS_VIEW,
    properties: properties,
  });
}

// Article browse event
const ARTICLE_BROWSE = 'Article Browse';
/**
 * Returns an object that represents opening news page
 */
export const articleBrowse = () => {
  return addGeneralPropertiesToEvent({
    event: ARTICLE_BROWSE,
    properties: {},
  });
}

// Article view event
const ARTICLE_VIEW = 'Article View';
/**
 * Returns an object that represents view of article page
 */
export const articleVisit = (id, title) => {
  return addGeneralPropertiesToEvent({
    event: ARTICLE_VIEW,
    properties: {
      id: id,
      title: title,
    },
  });
}

// Team Ranking view event
const TEAM_RANKING_VIEW = 'Team Ranking View';
/**
 * Returns an object that represents view of team ranking page
 */
export const teamRankingVisit = () => {
  return addGeneralPropertiesToEvent({
    event: TEAM_RANKING_VIEW,
    properties: {
    },
  });
}

// Language change event
const LANGUAGE_CHANGE = 'Language Change';
/**
 * Returns an object that represents language change event
 */
export const languageChange = (language) => {
  return addGeneralPropertiesToEvent({
    event: LANGUAGE_CHANGE,
    properties: {
      language: language,
    },
  });
}

/**
 * Adds current timestamp to event.
 * 
 * @param {*} event 
 */
const addGeneralPropertiesToEvent = (event) => {
  event.timestamp = new Date();
  event.properties.game = GAME_PUBG;
  return event;
}