import { all, takeLatest, call, put } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import {  
  REQUEST_REGISTER,
  REQUEST_REGISTER_PENDING,
  REQUEST_REGISTER_SUCCESS,
  REQUEST_REGISTER_ERROR
} from './types';
import { DISPLAY_ERROR_NOTIFICATION } from '../notification/types';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestRegister(action) {
  yield put({ type: REQUEST_REGISTER_PENDING });

  try {
    // Calling the server
    yield call([Auth, 'signUp'], { 
      username: action.payload.username,
      password: action.payload.password,
      attributes: {
        email: action.payload.email,
        'custom:newsletter': String(action.payload.newsletter),
      }
    });
    // Triggering success action
    yield put({ type: REQUEST_REGISTER_SUCCESS });
  } catch (err) {
    const errorMessaeg = err.message.replace('PreSignUp failed with error ', '').replace('User ', 'Username ');
    yield put({ type: DISPLAY_ERROR_NOTIFICATION, payload:  errorMessaeg });
    yield put({ type: REQUEST_REGISTER_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_REGISTER, requestRegister)
  ]);
}