import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
import { publicQuery } from '../../graphql/requestHelper';
import { 
  tournamentsByEventType,
} from '../../graphql/public/queries';
import {  
  REQUEST_TOURNAMENTS,
  REQUEST_TOURNAMENTS_PENDING,
  REQUEST_TOURNAMENTS_SUCCESS,
  REQUEST_TOURNAMENTS_ERROR,
} from './types';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';
import { TOURNAMENT_RECENT_DAYS } from '../../util/Constants';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestTournaments(action) {
  yield put({ type: REQUEST_TOURNAMENTS_PENDING });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const tournaments = yield publicQuery(tournamentsByEventType, {
        game: selectedGame,
        eventType: action.payload,
    });

    const recentBound = new Date(new Date().getTime() - TOURNAMENT_RECENT_DAYS * 1000*60*60*24);
    const tournamentsRecent = [];
    const tournamentsAll = [];
    tournaments.data.tournamentsByEventType.forEach(t => {
      if (t.lastPlayedOn && moment(t.lastPlayedOn).isAfter(moment(recentBound))) {
        tournamentsRecent.push(t);
      } else {
        tournamentsAll.push(t);
      }
    });

    // Triggering success action
    yield put({ 
      type: REQUEST_TOURNAMENTS_SUCCESS, 
      payload: {
        tournamentsRecent: tournamentsRecent,
        tournamentsAll: tournamentsAll,
      }
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_TOURNAMENTS_ERROR });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_TOURNAMENTS, requestTournaments),
  ]);
}