import { 
  REQUEST_MATCH_STATS_PENDING,
  REQUEST_MATCH_STATS_SUCCESS,
  REQUEST_MATCH_STATS_ERROR,
  SORT_MATCH_STATS_SUCCESS,
} from './types';
import { MATCH_STATS_COLUMNS } from '../../util/Constants';

const INITIAL_STATE = {
  matchStatsLoaded: false,
  matchStatsError: false,
  matchDetails: {},
  matchPlayersGroupedByRank: {},
  matchPlayersSorted: [],
  orderColumn: MATCH_STATS_COLUMNS[0],
  orderAsc: true
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On match stats loading
    case REQUEST_MATCH_STATS_PENDING:
      return {
        ...state,
        matchStatsLoaded: false,
        matchStatsError: false
      };
      
    // On successfully loaded match stats
    case REQUEST_MATCH_STATS_SUCCESS:
      return {
        ...state,
        matchStatsLoaded: true,
        matchStatsError: false,
        matchDetails: action.payload.matchDetails,
        matchPlayersGroupedByRank: action.payload.matchPlayersGroupedByRank,
        matchPlayersSorted: action.payload.matchPlayersSorted,
      };

    // On match stats fetch error
    case REQUEST_MATCH_STATS_ERROR:
      return {
        ...state,
        matchStatsLoaded: true,
        matchStatsError: true
      };

    // On match stats sorted success
    case SORT_MATCH_STATS_SUCCESS:
      return {
        ...state,
        matchPlayersSorted: action.payload.matchPlayersSorted,
        orderColumn: action.payload.orderColumn,
        orderAsc: action.payload.orderAsc
      };

    default:
      return state;
  }
}

export default reducer;