export default {
  menu: {
    matchAnalyzer: '比赛分析',
    tournaments: '锦标赛',
    news: '消息',
    pgcPoints: 'PGC Points',
    rankings: 'Rankings', // TODO missing
    scrims: '训练赛',
    userMatches: '查战绩',
    rotationAnalyzer: '转移路线分析',
    teamsRotations: '战队转移路径',
    saved: '保存',
    savedMatches: '保存比赛',
    productionOverlays: 'Production overlays',
    reportProblem: '报告问题',
    discord: '加入我们的Discord',
    twitter: '关注我们的Twitter',
  },
  headerMenu: {
    hi: '您好',
    account: '账户',
    settings: '设置',
    contact: '联系我们',
    logout: '登出',
    logoutError: '登陆时出错，请重试~',
    goPremium: '成为高级会员',
  },
  common: {
    save: '保存',
    edit: '编辑',
    delete: '删除',
    cancel: '取消',
    search: '搜索',
    ok: 'OK',
    showMore: '展示更多',
    close: '关闭',
    loading: '加载中...',
    learnMore: '了解更多',
    beta: '测试版',
    warning: '注意',
    goTo: '转到',
    goToApp: '转到App',
    login: '登陆',
    pleaseWait: '请等待',
    success: '完成!',
    signUp: '注册',
  },
  settings: {
    basicSettings: '基本设置',
    username: '用户名',
    email: '邮箱',
    subscription: '订阅',
    plan: '计划',
    validTo: '截止到',
    freePlan: '免费',
    trialPlan: '试用',
    premiumPlan: '高级会员',
    manageSubscription: '您可以在您的PayPal账户上管理您的订阅计划。.',
    pubgUsernames: 'PUBG 用户名 (端游)',
    pubgUsername: 'PUBG 用户名',
    enterPubgUsername: '请输入PUBG用户名 (区分大小写)',
    status: '状态',
    pendingConfirmation: '待确认',
    invlid: '无效的',
    active: '在线',
    password: '密码',
    notifications: '通知',
    emailNotifications: '邮箱通知',
    currentPassword: '当前密码',
    enterCurrentPassword: '请输入您当前的密码',
    newPassword: '新密码',
    enterNewPassword: '请输入新密码',
    passwordConfirmation: '确认新密码',
    enterPasswordConfirmation: '请重新输入一遍新密码',
    changePassword: '修改密码',
    promoCode: '优惠码',
    use: '使用',
    wrongPromoCode: '错误的优惠码',
    promoCodeSuccess: '成功使用优惠码，页面将重新加载',
  },
  regions: {
    allRegions: '所有地区',
    global: '全球',
    asia: '亚洲',
    europe: '欧洲',
    southAmerica: '南美',
    northAmerica: '北美',
    oceania: '大洋洲',
    apac: '亚太',
  },
  tournamentSearch: {
    tournamentFilter: '赛事过滤',
    tournamentName: '赛事昵称',
    clearFilter: '清除过滤',
    missingTournament: '错过了赛事？',
    recentTournaments: '最近的赛事',
    allTournaments: '所有赛事',
    tournament: '比赛',
    createTournament: '创建一个赛事',
    noTournaments: '没有赛事',
  },
  content: {
    title: '新闻',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: '写评论',
    newCommentPlaceholder: '您的评论',
  },
  tournament: {
    tournament: '赛事',
    tournamentInfo: '赛事信息',
    region: '地区',
    matches: '比赛',
    leaderboards: '排行榜',
    prizeLeaderboards: '奖金排行榜',
    playerStats: '队员资料',
    teamStats: '队伍资料',
    selectTournament: '选择一项赛事',
    noMatchesFound: '没有找到比赛的信息',
    noLeaderboards: '本次比赛没有排行榜',
    leaderboardsNotFound: '排行榜不存在，或者还没有进行比赛，请稍后再试',
    noPlayerStats: '本次比赛没有统计资料',
    playerStatsNotFound: '数据不存在或还没有进行比赛请稍后再试',
    tournamentPhase: '赛事阶段',
    selectTournamentPhase: '选择赛事阶段',
    loadingMatches: '加载比赛...',
  },
  scrimSearch: {
    scrims: '训练赛',
    scrim: '训练赛',
  },
  userMatches: {
    searchByUsername: '按用户名搜索',
    pubgUsername: 'PUBG用户名（区分大小写）。',
    matchesOf: '排位',
    enterPubgUsername: '确认PUBG用户名',
    usernameNotTracked: '没有搜集到相关用户的排位，请确认用户名重新输入',
    settings: '设置',
    favoriteUsers: '最常用的设置',
  },
  match: {
    rank: '排名',
    date: '日期',
    match: '比赛',
    map: '地图',
    type: '类型',
    zone: '篮圈',
    analyzeStats: '分析/统计',
    mode: '模式',
    duration: 'Duration',
  },
  matchDetails: {
    matchDetails: '比赛详情',
    matchStats: '比赛统计',
    match: '比赛',
    map: '地图',
    mode: '模式',
    duration: 'Duration',
  },
  matchStats: {
    rank: '排名',
    wins: '吃鸡',
    totalPoints: '总分',
    avgTotalPoints: '平均分',
    avgRank: '平均排名',
    points: 'Placement Points',
    avgPoints: 'Avg Placement Points',
    twr: 'TWR',
    numOfMatches: '比赛场次',
    team: '队伍',
    player: '选手',
    username: '选手',
    kills: '击杀',
    avgKills: '平均击杀',
    assists: '助攻',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: '造成伤害',
    avgDamageDealt: '平均场伤',
    damageTaken: '受到伤害',
    avgDamageTaken: '平均受到伤害',
    dbnos: '击倒',
    revives: '救援',
    headshotKills: '爆头击杀',
    longestKill: '最远击杀 (m)',
    killSteals: '被抢击杀',
    killsStolenFrom: '抢了别人的击杀',
    grenadePickup: '捡起的手榴弹',
    grenadeDrop: '丢弃的手榴弹',
    grenadeThrow: '投掷的手榴弹',
    grenadeDamage: '手榴弹造成的伤害',
    molotovPickup: '捡起的燃烧瓶',
    molotovDrop: '丢弃的燃烧瓶',
    molotovThrow: '投掷的燃烧瓶',
    molotovDamage: '燃烧瓶造成的伤害',
    smokebombPickup: '捡起的烟雾弹',
    smokebombDrop: '丢弃的烟雾弹',
    smokebombThrow: '投掷出的烟雾弹',
    flashbangPickup: '捡起的闪光弹',
    flashbangDrop: '丢弃的闪光弹',
    flashbangThrow: '投掷出的闪光弹',
    swimDistance: '游泳距离 (km)',
    walkDistance: '步行距离 (km)',
    rideDistance: '开车距离 (km)',
    timeSurvived: '生存时长',
    avgTimeSurvived: '平均生存时长',
    deathType: '死亡类型',
    heals: '治疗',
    healthRecovered: '恢复总血量',
    boosts: 'boosts',
    vehicleDestroys: '车辆毁坏',
    kd: 'KD',
    killsKnocks: '击杀 / 击倒',
    arDamage: '造成伤害 (AR)', // TODO missing
    dmrDamage: '造成伤害 (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: '造成伤害 / 受到伤害',
    damageTakenFromBlueZone: '受到伤害 (Zone)', // TODO missing
    damageTakenFromEnemy: '受到伤害 (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: '抢击杀--当玩家杀死被其他队伍击倒的人时。',
    stolenKillsFromExplaines: '被抢击杀--当玩家击倒某人，他们被敌人杀死。',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: '保存',
    undo: '撤销',
    clearAll: '清除全部',
    zonesToDraw: '画篮圈',
    pen: '笔',
    playerDetails: '玩家详情',
    rotationDetails: '行进路线',
    playerNames: '选手昵称',
    teamNames: '队伍昵称',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: '显示死亡信息',
    grid: '网格',
    planePath: '圈型',
    showPlanePaths: '显示圈型',
    showRotations: '显示行进路线',
    showCircles: '显示圈型',
    showLandingSpots: '显示着陆点',
    heatmap: 'Heatmap', // TODO missing
    help: '帮助',
  },
  help: {
    help: '帮助',
    tutorial: '指南',
    runTutorial: '运行指南',
    shortcuts: '快捷键',
    keyBinding: '绑定快捷键',
    description: '描述',
    playPause: '播放/暂停',
    stepBackward: '倒退 (2 秒.)',
    stepForward: '快进 (2 秒.)',
    previousSpeed: '之前播放的速度',
    nextSpeed: '下一个播放速度',
    gird: '显示/隐藏网格',
    teamNames: '显示/隐藏队伍昵称',
    playerNames: '显示/隐藏选手昵称',
    deadPlayers: '显示/隐藏死亡信息',
    planePath: '显示/隐藏圈型',
    playerDetails: '打开/关闭播放器的详细信息',
    rotationDetails: '打开/关闭行进路线',
    fullscreen: '打开/关闭全屏',
  },
  matchAnalyzer: {
    names: '名字',
    trace: '追踪',
    emptyInventory: '空的库存',
  },
  teamsSearch: {
    searchByTeam: '搜索队伍',
    teamNameOrInitial: '团队名称或缩写',
    teams: '队伍',
    team: '队伍',
    noResults: '没找到',
    oldRotationAnalyzer: 'Old Rotation Analyzer',
    tryNow: '现在试试',
  },
  rotationAnalyzer: {
    rotationAnalyzer: '转移路线分析',
    selectMap: '选择要分析的地图',
    match: '比赛',
    hideAll: '隐藏所有',
    show: '展示',
    rank: '排名',
    kills: '击杀',
    damageDealt: '造成伤害',
    damageTaken: '受到伤害',
    zoneDirection: '篮圈方向',
    phases: '阶段',
    walking: '步行',
    driving: '开车',
    endingZoneArea: '蓝圈',
    selectZoneArea: '选择决赛圈',
    selectTeam: '至少增加一个团队',
    zone: '篮圈',
    reset: '重置',
    resetWarning: '这个操作将删除所有已添加的选项，并重置所选选项？',
    teams: '队伍',
    add: '添加',
    noTeams: '没有入选的团队',
  },
  saved: {
    noPremium: '这个功能只针对高级用户。你仍然能够访问你以前保存的比赛，但你不能保存新的比赛.',
    savedMatches: '保存比赛',
    rename: '重命名',
    delete: '删除',
    renameFile: '重新命名文件',
    newFileName: '新文件名',
    fileDelete: '文件删除',
    fileDeleteWarning: '你确定要删除这个文件吗？',
    matchAnalysis: '比赛分析',
    modalRenameTitle: '比赛重命名',
    modalRenamePlaceholder: '新的比赛昵称',
    modalDeleteTitle: '比赛删除',
    modalDeletePlaceholder: '你确定要删除这个比赛吗？',
  },
  connect: {
    title: '跟上我们的步伐!',
    discordConnectText: '让我们通过Discord联系起来!',
    connectMe: '联系我！',
  },
  features: {
    title: '对你有什么好处',
    feature1Title: '赛事排行榜',
    feature1Text: '了解世界各地的赛事情况',
    feature2Title: '选手的统计数据',
    feature2Text: '比较选手他们在不同类别的能力',
    feature3Title: '赛事分析',
    feature3Text: '分析你的赛事情况，为你的远程工作提供协作动力。',
    feature4Title: '路线分析',
    feature4Text: '了解你的对手的能力，准备你的攻击策略',
  },
  games: {
    title: '你玩什么游戏？',
    availableNow: '现在可用',
    comingSoon: '即将上线',
  },
  partners: {
    title: '合作伙伴',
  },
  pricing: {
    title: '定价',
    advertisement: '广告',
    matchAnalyzer: '赛事分析',
    rotationAnalyzer: '路线分析',
    drawing: '绘画',
    collaborativeDrawing: '协作绘画',
    savingMatches: '保存比赛',
    premium: '高级会员',
    freePeriod: '14天试用期',
    paidMonthly: '按月支付',
    paidQuarterly: '按季度支付',
    noCardRequired: '不需要用卡',
    planContact1: '如果你或你的团队需要一个定制计划，请联系我们~',
    planContact2: '或通过',
    planContact3: '',
    free: '免费',
  },
  payment: {
    title: '定价',
    saved: '保存',
    ads: '移除广告',
    analyzerMapReplay: '分析地图重播',
    seeTeamsRotations: '查看队伍转移',
    workCollaboratively: '协同工作',
    shareDrawingInRealTime: '实时分享绘图',
    drawing: '绘画',
    markImportantMoments: '标记重要时刻',
    saveitForLater: '保存以后使用',
    recommended: '建议',
    billedQuarterly: '每季度结算一次',
    billedMonthly: '按月计费',
    selectPlan: '选择计划',
    billedNow: '现在开始计费',
    alreadyPremium: '您已经是高级会员',
    expiresInDays: '免费试用14天后到期',
    learnMore: '查看更多',
  },
  welcome: {
    title1: '激活你的',
    title2: '电竞生涯',
    subtitle: '胜者的分析工具',
    register: '免费注册',
    learnMore: '查看更多',
  },
  forgotPassword: {
    emailSent: '邮件发送',
    emailSentMessage: '带有重置密码链接的电子邮件已发送给您。',
    forgotPassword: '忘记密码',
    inputUsername: '输入你的用户名',
    sentResetLink: '发送重置链接',
  },
  login: {
    signUp: '注册',
    dontHaveAccountLong: '还没有账户吗？你还在等什么! 注册并开始使用我们的分析工具来提高你的能力吧!',
    dontHaveAccount: '还没有账户吗？',
    registerNow: '现在就注册!',
    login: '登陆',
    resetPassword: '重置密码',
    reset: '重置',
    signInAccount: '登录到您的账户',
    passwordResetNeeded: '你需要重新设置你的密码',
    username: '用户名',
    password: '密码',
    passwordConfirmation: '密码确认',
    forgotPassword: '忘记密码？',
    resetPasswordRequired: '你需要重置你的密码',
    wrongCredentials: '无效的用户名或密码',
    passwordsDontMatch: '密码不匹配',
    passwordToShort: '密码必须包括至少8个字符，至少有一个字母和一个数字。',
  },
  register: {
    register: '注册',
    thankYou: '谢谢您！',
    thankYouLong: '谢谢你创建账户! 请检查您的电子邮件以完成注册。',
    username: '用户名',
    email: '邮箱',
    password: '密码',
    repeatPassword: '重复密码',
    subscribeToNewsletter: '订阅我们的新闻',
    readAndUnderstand: '我已阅读并理解',
    termsOfUse: '使用条款',
    createAccount: '创建账户',
    alreadyRegistered: '已经注册了吗？',
    invalidUsername: '无效的用户名',
    invalidEmail: '无效的电子邮件',
    invalidPassword: '密码必须包括至少8个字符，至少有一个字母和一个数字',
    passwordsDontMatch: '密码不匹配',
    termsRequired: '你必须同意使用条款',
  },
  resetPassword: {
    resetPassword: '重置密码',
    selectNewPassword: '选择新密码',
    password: '密码',
    passwordConfirmation: '密码确认',
  },
  verification: {
    oops: '哎呀。。。',
    verificationFailed: '验证失败! 您的链接已过期或已被验证。',
    resendEmail: '重新发送电子邮件',
    verifyingEmail: '验证您的电子邮件...',
    verificationSuccess: '验证完成! 您现在可以登录了!',
  }
}