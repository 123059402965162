import { all, takeLatest, select } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import {  
  REQUEST_TRACK,
  FIX_SESSION,
} from './types';

/**
 * Makes a request to server for tracking user action
 * 
 * @param {*} action 
 */
function* requestTrack(action) {
  // pass
}

/**
 * Fixes session of the user because of the beta
 * 
 * @param {*} action 
 */
function* fixSession(action) {
  // TODO: remove this after a while
  const general = yield select(s => s.general);
  let username = general.username ? general.username : Cookies.get('session');
  if (!username) {
    username = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & (0x3 | 0x8));
      return v.toString(16);
    });
    const cookie = document.cookie ? document.cookie + ';' : '';
    document.cookie = cookie + 'expires=Fri, 31 Dec 9999 23:59:59 GMT;'
    Cookies.set('session', username, { path: '', domain: '.twire.gg' });
  } else if (!general.username) {
    Cookies.remove('session');
    Cookies.set('session', username, { path: '', domain: '.twire.gg' });
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_TRACK, requestTrack),
      takeLatest(FIX_SESSION, fixSession),
  ]);
}