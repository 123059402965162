import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { privateMutation, privateQuery } from '../../graphql/requestHelper';
import { fileByParentFileId } from '../../graphql/private/queries';
import { renameFile, deleteFile } from '../../graphql/private/mutations';
import {  
  REQUEST_FILES,
  REQUEST_FILES_PENDING,
  REQUEST_FILES_SUCCESS,
  REQUEST_FILES_ERROR,
  REQUEST_RENAME_FILE,
  REQUEST_RENAME_FILE_SUCCESS,
  REQUEST_DELETE_FILE,
  REQUEST_DELETE_FILE_SUCCESS
} from './types';
import { 
  ERROR_LOADING_DATA, 
  ERROR_FILE_RENAME, 
  ERROR_FILE_DELETE 
} from '../../util/ErrorMessages';

/**
 * Makes a request to server for tournaments
 * 
 * @param {*} action 
 */
function* requestFiles(action) {
  yield put({ type: REQUEST_FILES_PENDING });

  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    const files = yield privateQuery(fileByParentFileId, {
      game: selectedGame,
      name: action.payload 
    });
    // Triggering success action
    yield put({ 
      type: REQUEST_FILES_SUCCESS, 
      payload: files.data.fileByParentFileId
    });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
    yield put({ type: REQUEST_FILES_ERROR });
  }
}

/**
 * Makes a request to server to rename a file
 * 
 * @param {*} action 
 */
function* requestFileRename(action) {
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    yield privateMutation(renameFile, {
      game: selectedGame,
      fileId: action.payload.file.id,
      filename: action.payload.filename 
    });
    // Triggering success action
    yield put({ 
      type: REQUEST_RENAME_FILE_SUCCESS, 
      payload: {
        file: action.payload.file,
        filename: action.payload.filename
      }
    });
  } catch (err) {
    yield call(toast.error, ERROR_FILE_RENAME);
  }
}

/**
 * Makes a request to server to delete a file
 * 
 * @param {*} action 
 */
function* requestFileDelete(action) {
  try {
    const selectedGame = yield select(state => state.general.selectedGame);

    // Calling the server
    yield privateMutation(deleteFile, {
      game: selectedGame,
      fileId: action.payload.id
    });
    // Triggering success action
    yield put({ 
      type: REQUEST_DELETE_FILE_SUCCESS, 
      payload: action.payload
    });
  } catch (err) {
    yield call(toast.error, ERROR_FILE_DELETE);
  }
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_FILES, requestFiles),
      takeLatest(REQUEST_RENAME_FILE, requestFileRename),
      takeLatest(REQUEST_DELETE_FILE, requestFileDelete)
  ]);
}