export default {
  menu: {
    matchAnalyzer: 'Maç Analizcisi',
    tournaments: 'Turnuvalar',
    news: 'Haberler',
    pgcPoints: 'PGC Puan',
    rankings: 'Rankings', // TODO missing
    scrims: 'Antrenman Maçları',
    userMatches: 'Kullanıcı Maçları',
    rotationAnalyzer: 'Rotasyon Analizcisi',
    teamsRotations: 'Takımın Rotasyonları',
    saved: 'Kayıtlı',
    savedMatches: 'Kayıtlı Maçlar',
    productionOverlays: 'Prodüksiyon Arayüzleri',
    reportProblem: 'Bir Sorun Bildir',
    discord: 'Discord\'umuza Katıl',
    twitter: 'Bizi Twitter\'da Takip Et',
  },
  headerMenu: {
    hi: 'Merhaba',
    account: 'Hesap',
    settings: 'Ayarlar',
    contact: 'Bize Ulaşın',
    logout: 'Çıkış',
    logoutError: 'Çıkışta hata oldu. Lütfen tekrar deneyin!',
    goPremium: 'Premium Üye Ol',
  },
  common: {
    save: 'Kaydet',
    edit: 'Düzenle',
    delete: 'Sil',
    cancel: 'İptal',
    search: 'Ara',
    ok: 'Tamam',
    showMore: 'Daha Fazla Göster',
    close: 'Kapat',
    loading: 'Yükleniyor...',
    learnMore: 'Daha Fazla Bilgi',
    beta: 'Beta',
    warning: 'Uyarı',
    goTo: 'Şuraya Git',
    goToApp: 'Uygulamaya Git',
    login: 'Giriş',
    pleaseWait: 'Lütfen Bekleyin...',
    success: 'Başarılı!',
    signUp: 'Kayıt Ol',
  },
  settings: {
    basicSettings: 'Temel Ayarlar',
    username: 'Kullanıcı Adı',
    email: 'Email',
    subscription: 'Üyelik',
    plan: 'Plan',
    validTo: 'Şu Tarihe Kadar Geçerli',
    freePlan: 'Ücretsiz',
    trialPlan: 'Deneme',
    premiumPlan: 'Premium',
    manageSubscription: 'Üyelik planınızı PayPal hesabınızdan yönetebilirsiniz.',
    pubgUsernames: 'PUBG Kullanıcı Adları (sadece PUBG PC)',
    pubgUsername: 'PUBG Kullanıcı Adı',
    enterPubgUsername: 'Lütfen PUBG kullanıcı adınızı girin (büyük-küçük harfe duyarlı)',
    status: 'Durum',
    pendingConfirmation: 'Onay Bekleniyor',
    invlid: 'Geçersiz',
    active: 'Aktif',
    password: 'Şifre',
    notifications: 'Bildirimler',
    emailNotifications: 'Email Bildirimleri',
    currentPassword: 'Şu Anki Şifre',
    enterCurrentPassword: 'Lütfen Şu Anki Şifrenizi Girin',
    newPassword: 'Yeni Şifre',
    enterNewPassword: 'Lütfen yeni şifrenizi girin',
    passwordConfirmation: 'Şifre Onayı',
    enterPasswordConfirmation: 'Lütfen yeni şifrenizi tekrar girin',
    changePassword: 'Şifre Değiştir',
    promoCode: 'Promosyon Kodu',
    use: 'kullan',
    wrongPromoCode: 'Hatalı Promosyon Kodu',
    promoCodeSuccess: 'Promosyon kodu başarıyla kullanıldı. Sayfa yeniden yüklenecek',
  },
  regions: {
    allRegions: 'Tüm Bölgeler',
    global: 'Global',
    asia: 'Asya',
    europe: 'Avrupa',
    southAmerica: 'Güney Amerika',
    northAmerica: 'Kuzey Amerika',
    oceania: 'Okyanusya',
    apac: 'APAC',
  },
  tournamentSearch: {
    tournamentFilter: 'Turnuva Filtresi',
    tournamentName: 'Turnuva İsmi',
    clearFilter: 'Filtreleri Temizle',
    missingTournament: 'Turnuvayı Bulamadınız Mı?',
    recentTournaments: 'Yakın Zamandaki Turnuvalar',
    allTournaments: 'Bütün Turnuvalar',
    tournament: 'Turnuva',
    createTournament: 'Bir Turnuva Oluştur',
    noTournaments: 'Turnuva Yok',
  },
  content: {
    title: 'Haberler',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'Bir yorum yazın',
    newCommentPlaceholder: 'Yorumunuz',
  },
  tournament: {
    tournament: 'Turnuva',
    tournamentInfo: 'Turnuva Bilgisi',
    region: 'Bölge',
    matches: 'Maçlar',
    leaderboards: 'Sıralamalar',
    prizeLeaderboards: 'Ödül Parası Sıralama Tablosu',
    playerStats: 'Oyuncu İstatistikleri',
    teamStats: 'Takım İstatistikleri',
    selectTournament: 'Bir Turnuva Seçin',
    noMatchesFound: 'Maç Bulunamadı',
    noLeaderboards: 'Bu Turnuva İçin Sıralama Bulunamadı',
    leaderboardsNotFound: 'Sıralama bulunmuyor ya da henüz bir maç oynanmadı. Daha sonra tekrar deneyin.',
    noPlayerStats: 'Bu turnuva için istatistik bulunmuyor',
    playerStatsNotFound: 'İstatistik bulunmuyor ya da henüz bir maç oynanmadı. Daha sonra tekrar deneyin.',
    tournamentPhase: 'Turnuva Aşaması',
    selectTournamentPhase: 'Turnuva Aşaması Seçin',
    loadingMatches: 'Maçlar yükleniyor...',
  },
  scrimSearch: {
    scrims: 'Scrim Maçları',
    scrim: 'Scrim maçı',
  },
  userMatches: {
    searchByUsername: 'Kullanıcı Adıyla Ara',
    pubgUsername: 'PUBG Kullanıcı Adı (büyük-küçük harf önemli)',
    matchesOf: 'Maçları',
    enterPubgUsername: 'PUBG Kullanıcı Adı Girin',
    usernameNotTracked: 'Bu kullanıcı adını takip etmediğimiz için tüm maçları burada olmayabilir. Kullanıcı adı ekleyebilirsiniz.',
    settings: 'ayarlar',
    favoriteUsers: 'Favoriler',
  },
  match: {
    rank: 'Sıra',
    date: 'Tarih',
    match: 'Maç',
    map: 'Harita',
    type: 'Tür',
    zone: 'Bölge',
    analyzeStats: 'Analiz/İstatistikler',
    mode: 'Mod',
    duration: 'Süre',
  },
  matchDetails: {
    matchDetails: 'Maç Detayları',
    matchStats: 'Maç İstatistikleri',
    match: 'Maç',
    map: 'Harita',
    mode: 'Mod',
    duration: 'Süre',
  },
  matchStats: {
    rank: 'Sıra',
    wins: 'WWCD',
    totalPoints: 'Toplam Puan',
    avgTotalPoints: 'Ortalama Toplam Puan',
    avgRank: 'Ortalama Sıra',
    points: 'Sıralama Puanları',
    avgPoints: 'Ortalama Sıralama Puanı',
    twr: 'TWR',
    numOfMatches: 'Maç Sayısı',
    team: 'Takım',
    player: 'Oyuncu',
    username: 'Oyuncu',
    kills: 'Öldürme Sayısı',
    avgKills: 'Ortalama Öldürme Sayısı',
    assists: 'Asistler',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'Verilen Hasar',
    avgDamageDealt: 'Ortalama Verilen Hasar',
    damageTaken: 'Alınan Hasar',
    avgDamageTaken: 'Ortalama Alınan Hasar',
    dbnos: 'Düşürmeler',
    revives: 'Canlandırmalar',
    headshotKills: 'Kafadan vuruş ile öldürme sayısı',
    longestKill: 'En Uzak Öldürme (m)',
    killSteals: 'Çalınan Öldürmeler',
    killsStolenFrom: 'Oyuncudan Çalınan Öldürmeler',
    grenadePickup: 'Alınan El Bombaları',
    grenadeDrop: 'Düşürülen El Bombaları',
    grenadeThrow: 'Atılan El Bombaları',
    grenadeDamage: 'El Bombası Hasarı',
    molotovPickup: 'Alınan Molotoflar',
    molotovDrop: 'Düşürülen Molotoflar',
    molotovThrow: 'Atılan Molotoflar',
    molotovDamage: 'Molotof Hasarı',
    smokebombPickup: 'Alınan Duman Bombaları',
    smokebombDrop: 'Düşürülen Duman Bombaları',
    smokebombThrow: 'Atılan Duman Bombaları',
    flashbangPickup: 'Alınan Flaş Bombaları',
    flashbangDrop: 'Düşürülen Flaş Bombaları',
    flashbangThrow: 'Atılan Flaş Bombaları',
    swimDistance: 'Yüzme Mesafesi (km)',
    walkDistance: 'Yürüme Mesafesi (km)',
    rideDistance: 'Araçla Gidilen Mesafe (km)',
    timeSurvived: 'Hayatta Kalma Süresi',
    avgTimeSurvived: 'Ortalama Hayatta Kalma Süresi',
    deathType: 'Ölüm Şekli',
    heals: 'İyileştirmeler',
    healthRecovered: 'Geri Kazanılan Sağlık',
    boosts: 'Boost\'lar',
    vehicleDestroys: 'Araç Yok Etme Sayısı',
    kd: 'KD',
    killsKnocks: 'Öldürme Sayısı/Düşürmeler',
    arDamage: 'Verilen Hasar (AR)', // TODO missing
    dmrDamage: 'Verilen Hasar (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'Verilen Hasar/Alınan Hasar',
    damageTakenFromBlueZone: 'Alınan Hasar (Zone)', // TODO missing
    damageTakenFromEnemy: 'Alınan Hasar (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Ağırlık Ortalaması',
    stolenKillsExplained: 'Çalınan Öldürmeler - bir oyuncunun başka bir takım tarafından düşürülen birini öldürmesi',
    stolenKillsFromExplaines: 'Çaldırılan Öldürmeler - oyuncular birini düşürdüğünde düşen oyuncunun başka bir takım tarafından öldürülmesi',
    kasExplaines: 'KAS (consistency) - number of matches where player made a kill, assist, or survived', // TODO missing
  },
  tools: {
    save: 'Kaydet',
    undo: 'Geri Al',
    clearAll: 'Hepsini Temizle',
    zonesToDraw: 'Bölgeleri Çiz',
    pen: 'Kalem',
    playerDetails: 'Oyuncu Detayları',
    rotationDetails: 'Rotasyon Detayları',
    playerNames: 'Oyuncu İsimleri',
    teamNames: 'Takım İsimleri',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: 'Ölü Oyuncuları Göster',
    grid: 'Kılavuz',
    planePath: 'Uçak Rotası',
    showPlanePaths: 'Uçak Rotalarını Göster',
    showRotations: 'Rotasyonları Göster',
    showCircles: 'Çemberleri Göster',
    showLandingSpots: 'İniş Noktalarını Göster',
    heatmap: 'Heatmap', // TODO missing
    help: 'Yardım',
  },
  help: {
    help: 'Yardım',
    tutorial: 'Öğretici',
    runTutorial: 'Öğreticiyi Başlat',
    shortcuts: 'Kısayollar',
    keyBinding: 'Tuş Atamaları',
    description: 'Tanım',
    playPause: 'oynat/durdur',
    stepBackward: 'geri al (2 sn)',
    stepForward: 'ileri al (2 sn)',
    previousSpeed: 'önceki oynatma hızı',
    nextSpeed: 'sonraki oynatma hızı',
    gird: 'kılavuzu göster/gizle',
    teamNames: 'takım adlarını göster/gizle',
    playerNames: 'oyuncu adlarını göster/gizle',
    deadPlayers: 'ölü oyuncuları göster/gizle',
    planePath: 'uçak rotasını göster/gizle',
    playerDetails: 'oyuncu detaylarını aç/kapa',
    rotationDetails: 'rotasyon detaylarını aç/kapa',
    fullscreen: 'tam ekranı aç/kapa',
  },
  matchAnalyzer: {
    names: 'İsimler',
    trace: 'Takip',
    emptyInventory: 'Envanteri Boşalt',
  },
  teamsSearch: {
    searchByTeam: 'Takım İle Ara',
    teamNameOrInitial: 'Takım adı ya da baş harfi',
    teams: 'Takımlar',
    team: 'Takım',
    noResults: 'Sonuç yok',
    oldRotationAnalyzer: 'Eski Rotasyon Analizcisi',
    tryNow: 'Şimdi Dene',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'Rotasyon Analizcisi',
    selectMap: 'Analiz Edilecek Haritayı Seçin',
    match: 'Maç',
    hideAll: 'Hepsini Gizle',
    show: 'Göster',
    rank: 'Sıra',
    kills: 'Öldürmeler',
    damageDealt: 'Verilen Hasar',
    damageTaken: 'Alınan Hasar',
    zoneDirection: 'Alan yönü',
    phases: 'Aşamalar',
    walking: 'Yürüme',
    driving: 'Sürüş',
    endingZoneArea: 'BİTİŞ ÇEMBER ALANI',
    selectZoneArea: 'Bölge alanını seçin',
    selectTeam: 'En az bir takım seçin',
    zone: 'Bölge',
    reset: 'Sıfırla',
    resetWarning: 'Bu eylem eklenen tüm rotasyonları kaldıracak ve seçilen bölgeyi sıfırlayacaktır?',
    teams: 'Takımlar',
    add: 'Ekle',
    noTeams: 'Bir takım seçilmedi',
  },
  saved: {
    noPremium: 'Bu özellik sadece premium üyeler içindir. Eski kayıtlı maçlarınıza hala ulaşabilirsiniz ancak yeni kayıt yapamazsınız.',
    savedMatches: 'Kayıtlı Maçlar',
    rename: 'Yeniden Adlandır',
    delete: 'Sil',
    renameFile: 'Dosyayı Yeniden Adlandır',
    newFileName: 'Yeni Dosya Adı',
    fileDelete: 'Dosya Sil',
    fileDeleteWarning: 'Bu dosyayı silmek istediğinizden emin misiniz?',
    matchAnalysis: 'Maç Analizi',
    modalRenameTitle: 'Maçı Yeniden Adlandır',
    modalRenamePlaceholder: 'Yeni Maç Adı',
    modalDeleteTitle: 'Maçı Sil',
    modalDeletePlaceholder: 'Bu maçı silmek istediğinize emin misiniz?',
  },
  connect: {
    title: 'Bizi takipte kalın!',
    discordConnectText: 'Discord\'dan Bağlanalım!',
    connectMe: 'Bana Bağlan!',
  },
  features: {
    title: 'Size ne kazandırır?',
    feature1Title: 'Turnuva Sıralama Tabloları',
    feature1Text: 'Tüm dünyadan turnuvaları takip edin',
    feature2Title: 'Oyuncuların İstatistikleri',
    feature2Text: 'Oyuncuları ve yeteneklerini farklı kategorilerde kıyaslayın',
    feature3Title: 'Maç Analizcisi',
    feature3Text: 'Maçlarınızı analiz edin ve ortak çalışmanızı birlikte güçlendirin',
    feature4Title: 'Rotasyon Analizcisi',
    feature4Text: 'Rakiplerinizin rotasyonlarını bilin ve saldırı stratejinizi hazırlayın',
  },
  games: {
    title: 'Hangi oyunları oynuyorsunuz?',
    availableNow: 'çıktı',
    comingSoon: 'yakında',
  },
  partners: {
    title: 'Ortaklar',
  },
  pricing: {
    title: 'Fiyatlandırma',
    advertisement: 'İlan',
    matchAnalyzer: 'Maç Analizcisi',
    rotationAnalyzer: 'Rotasyon Analizcisi',
    drawing: 'Çizim',
    collaborativeDrawing: 'Ortak Çizim',
    savingMatches: 'Maç Kaydetme',
    premium: 'Premium',
    freePeriod: '14 Gün Deneme',
    paidMonthly: 'Aylık ödeme',
    paidQuarterly: 'Üç ayda bir ödeme',
    noCardRequired: 'Kart gerekmiyor',
    planContact1: 'Eğer siz ya da takımınız özel bir plana ihtiyaç duyuyorsa bize şuradan ulaşın',
    planContact2: 'ya da şuradan',
    planContact3: '',
    free: 'Ücretsiz',
  },
  payment: {
    title: 'Fiyatlandırma',
    saved: 'Kayıtlı',
    ads: 'Reklamları kaldırmak',
    analyzerMapReplay: 'Harita kayıtlarını analiz et',
    seeTeamsRotations: 'Takımların rotasyonlarını gör',
    workCollaboratively: 'Birlikte çalışın',
    shareDrawingInRealTime: 'Çizimlerinizi gerçek zamanlı paylaşın',
    drawing: 'Çizim',
    markImportantMoments: 'Önemli anları işaretleyin',
    saveitForLater: 'Daha sonra kullanmak üzere kaydedin',
    recommended: 'Tavsiye edilen',
    billedQuarterly: 'Üç aylık faturalandırma',
    billedMonthly: 'Aylık faturalandırma',
    selectPlan: 'Plan seçin',
    billedNow: 'Şimdi faturalandırılır',
    alreadyPremium: 'Zaten premium üyesiniz',
    expiresInDays: 'Ücretsiz deneme süreci 14 gün içinde sona erecektir',
    learnMore: 'Daha fazla bilgi',
  },
  welcome: {
    title1: 'Başlatın',
    title2: 'ESPOR KARİYERİNİZİ',
    subtitle: 'Kazandıran analiz araçları',
    register: 'Ücretsiz kayıt olun',
    learnMore: 'Daha fazla bilgi almak istiyorum',
  },
  forgotPassword: {
    emailSent: 'Email Gönderildi!',
    emailSentMessage: 'Şifre sıfırlama linki içeren bir email adresinize gönderildi',
    forgotPassword: 'Şifremi Unuttum',
    inputUsername: 'Kullanıcı adınızı girin',
    sentResetLink: 'Sıfırlama Linki Gönder',
  },
  login: {
    signUp: 'Kayıt Ol',
    dontHaveAccountLong: 'Bir hesabınız yok mu? Daha ne bekliyorsunuz! Kayıt olun ve becerilerinizi harika araçlarımızla geliştirmeye başlayın!',
    dontHaveAccount: 'Bir hesabınız yok mu?',
    registerNow: 'Şimdi Kayıt Ol!',
    login: 'Giriş',
    resetPassword: 'Şifre Sıfırla',
    reset: 'Sıfırla',
    signInAccount: 'Hesabınıza giriş yapın',
    passwordResetNeeded: 'Şifrenizi sıfırlamanız gerekiyor',
    username: 'Kullanıcı Adı',
    password: 'Şifre',
    passwordConfirmation: 'Şifre Onayı',
    forgotPassword: 'Şifrenizi Mi Unuttunuz?',
    resetPasswordRequired: 'Şifrenizi sıfırlamanız gerekiyor',
    wrongCredentials: 'Geçersiz kullanıcı adı ya da şifre',
    passwordsDontMatch: 'Şifreler eşleşmiyor',
    passwordToShort: 'Şifreler en az 8 karakter, bir harf ve bir rakam içermelidir',
  },
  register: {
    register: 'Kayıt Ol',
    thankYou: 'Teşekkürler!',
    thankYouLong: 'Bir hesap oluşturduğunuz için teşekkürler! Kayıt işlemini tamamlamak için emailinizi kontrol edin.',
    username: 'Kullanıcı Adı',
    email: 'Email',
    password: 'Şifre',
    repeatPassword: 'Şifreyi Tekrar Girin',
    subscribeToNewsletter: 'Güncellemelerimizi takip edin',
    readAndUnderstand: 'Okudum ve anladım',
    termsOfUse: 'Kullanım Koşulları',
    createAccount: 'Hesap Oluştur',
    alreadyRegistered: 'Zaten Kayıt Oldunuz Mu?',
    invalidUsername: 'Geçersiz Kullanıcı Adı',
    invalidEmail: 'Geçersiz email',
    invalidPassword: 'Şifreler en az 8 karakter, bir harf ve bir rakam içermelidir',
    passwordsDontMatch: 'Şifreler eşleşmiyor',
    termsRequired: 'Kullanım koşullarını kabul etmeniz gerekmektedir',
  },
  resetPassword: {
    resetPassword: 'Şifre Sıfırla',
    selectNewPassword: 'Yeni şifre seç',
    password: 'Şifre',
    passwordConfirmation: 'Şifre Onaylama',
  },
  verification: {
    oops: 'Ups..',
    verificationFailed: 'Doğrulama başarısız! Kullandığınız link hatalı, süresi dolmuş ya da daha önce doğrulanmış.',
    resendEmail: 'Tekrar email gönder',
    verifyingEmail: 'Emailiniz doğrulanıyor...',
    verificationSuccess: 'Doğrulama tamamlandı! Şimdi giriş yapabilirsiniz!',
  }
}