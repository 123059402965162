import { all, takeLatest, call, put, take, select } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { toast } from 'react-toastify';
import { 
  SET_MAP_CANVAS_STATE,
  REQUEST_LOAD_MAP_CANVAS_MAP,
  ADJUST_MAP_CANVAS_SIZE,
  PREPARE_NEW_CANVAS_STATE,
  MAP_CANVAS_CLEAR_STATE,
  REQUEST_LOAD_STEERING_WHEEL,
  REQUEST_LOAD_TEAM_LOGO,
  REQUEST_LOAD_MAP_CANVAS_HEATMAP,
} from './types';
import { ERROR_LOADING_DATA } from '../../util/ErrorMessages';
import { calculateHeight, calculateWidth } from '../../util/MapCanvasUtils';
import { MAP_TOOLS_CLEAR_STATE } from '../mapToolsState/types';
import { MATCH_ANALYZER_MATCH_CLEAR_STATE } from '../matchAnalyzerState/types';
import { FILE_HIGHLIGHTS_CLEAR_STATE } from '../fileState/types';

const getMap = mapName => require(`../../assets/pubg/Maps/${mapName}.jpg`);
const getHeatmap = mapName => require(`../../assets/pubg/Heatmaps/${mapName}_C.png`);
const getSteeringWheel = () => require(`../../assets/pubg/Vehicle/steering-wheel.png`);

/**
 * Makes a request to load map image
 * 
 * @param {*} action 
 */
function* requestLoadMapImage(action) {
  try {
    const isFullScreen = (yield select(s => s.fullScreen)).isFullScreen;

    const mapName = action.payload;
    const chan = yield call(loadImage, getMap(mapName));
    const map = yield take(chan);
    const mapHeight = calculateHeight(isFullScreen);
    const mapWidth = calculateWidth(isFullScreen);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { map: map, mapHeight: mapHeight, mapWidth: mapWidth, mapName: mapName } });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Makes a request to load heatmap image
 * 
 * @param {*} action 
 */
function* requestLoadHeatmapImage(action) {
  try {
    const chan = yield call(loadImage, getHeatmap(action.payload));
    const image = yield take(chan);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { heatMap: image } });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Makes a request to load steerig wheel image
 * 
 * @param {*} action 
 */
function* requestSteeringWheelImage(action) {
  try {
    const chan = yield call(loadImage, getSteeringWheel());
    const image = yield take(chan);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { steeringWheel: image } });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Makes a request to load team logo
 * 
 * @param {*} action 
 */
function* requestTeamLogo(action) {
  try {
    const chan = yield call(loadImage, action.payload.image);
    const image = yield take(chan);
    yield put({ type: SET_MAP_CANVAS_STATE, payload: { [`teamLogo${action.payload.slot}`]: image } });
  } catch (err) {
    yield call(toast.error, ERROR_LOADING_DATA);
  }
}

/**
 * Lazy loads map image
 * 
 * @param {*} mapName
 */
function loadImage(image) {
  return eventChannel(emitter => {
    const map = new window.Image()
    map.src = image
    map.onload = () => {
      emitter(map);
      emitter(END);
    }
    return () => {};
  });
}

/**
 * Resizes map canvas according to screen size
 * 
 * @param {*} action 
 */
function* adjustMapCanvasSize(action) {
  const isFullScreen = (yield select(s => s.fullScreen)).isFullScreen;

  const mapHeight = calculateHeight(isFullScreen);
  const mapWidth = calculateWidth(isFullScreen);
  yield put({ type: SET_MAP_CANVAS_STATE, payload: { mapHeight: mapHeight, mapWidth: mapWidth } });
}

/**
 * Clears all states related to canvas
 * 
 * @param {*} action 
 */
function* prepareNewCanvasState(action) {
  yield put({ type: MAP_CANVAS_CLEAR_STATE });
  yield put({ type: MAP_TOOLS_CLEAR_STATE });
  yield put({ type: MATCH_ANALYZER_MATCH_CLEAR_STATE });
  yield put({ type: FILE_HIGHLIGHTS_CLEAR_STATE });
}

// The exported watcher
export default function* rootSaga() {
  yield all([
      takeLatest(REQUEST_LOAD_MAP_CANVAS_MAP, requestLoadMapImage),
      takeLatest(REQUEST_LOAD_MAP_CANVAS_HEATMAP, requestLoadHeatmapImage),
      takeLatest(REQUEST_LOAD_STEERING_WHEEL, requestSteeringWheelImage),
      takeLatest(REQUEST_LOAD_TEAM_LOGO, requestTeamLogo),
      takeLatest(ADJUST_MAP_CANVAS_SIZE, adjustMapCanvasSize),
      takeLatest(PREPARE_NEW_CANVAS_STATE, prepareNewCanvasState),
  ]);
}