import { 
  REQUEST_REGISTER_PENDING,
  REQUEST_REGISTER_SUCCESS,
  REQUEST_REGISTER_ERROR,
  REGISTER_FORM_CLEAN
} from './types';

const INITIAL_STATE = {
  registerFinished: false,
  registerError: false,
  loading: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // On register in progress
    case REQUEST_REGISTER_PENDING:
      return {
        ...state,
        registerFinished: false,
        registerError: false,
        loading: true,
      };
    
    // On register form clean
    case REGISTER_FORM_CLEAN:
      return {
        ...state,
        registerFinished: false,
        registerError: false,
        loading: false,
      };
      
    // On successful register
    case REQUEST_REGISTER_SUCCESS:
      return {
        ...state,
        registerFinished: true,
        registerError: false,
        loading: false,
      };

    // On register error
    case REQUEST_REGISTER_ERROR:
      return {
        ...state,
        registerFinished: false,
        registerError: true,
        loading: false,
      };

    default:
      return state;
  }
}

export default reducer;