import Cookies from 'js-cookie';
import { 
  SET_AUTH_USER,
  UNSET_AUTH_USER,
  REQUEST_USER_SUBSCRIPTION,
  REQUEST_LOGGED_IN_USER,
  SET_SELECTED_GAME,
} from './types';

/**
 * Action for setting authenticated user
 */
export const setAuthUser = (user) => ({
    type: SET_AUTH_USER,
    payload: {
      username: user.username,
      sub: user.attributes.sub,
      userGroups: user.signInUserSession.idToken.payload['cognito:groups'],
    }
});

/**
 * Action for setting authenticated user
 */
export const unsetAuthUser = () => ({
    type: UNSET_AUTH_USER,
});

/**
 * Action for getting user subscription
 */
export const requestUserSubscription = () => ({
  type: REQUEST_USER_SUBSCRIPTION,
});

/**
 * Requests logged in user
 */
export const requestLoggedInUser = () => ({
  type: REQUEST_LOGGED_IN_USER,
});

/**
 * Action to set select game
 */
export const setSelectedGame = (game) => {
  Cookies.set('selected-game', game);
  return {
    type: SET_SELECTED_GAME,
    payload: game,
  }
};