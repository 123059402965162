export default {
  menu: {
    matchAnalyzer:'การวิเคราะห์แมตช์',
    tournaments: 'ทัวร์นาเม้นต์',
    news: 'ข่าว',
    pgcPoints: 'PGC Points', // TODO: missing
    scrims: 'ห้องซ้อม',
    userMatches: 'ผู้เล่นในแมตช์',
    rotationAnalyzer: 'การวิเคราะห์การเคลื่อนไหว',
    teamsRotations: 'การเคลื่อนไหวของทีม',
    saved: 'บันทึกแล้ว',
    savedMatches: 'แมตช์ที่บันทึก',
    productionOverlays: 'Production overlays',
    reportProblem: 'แจ้งปัญหา',
    discord: 'เข้า Discord ของพวกเรา',
    twitter: 'ติดตามพวกเราบน Twitter ',
  },
  headerMenu: {
    hi: 'สวัสดี',
    account: 'บัญชีผู้ใช้',
    settings: 'การตั้งค่า',
    contact: 'ติดต่อพวกเรา',
    logout: 'ออกจากระบบ',
    logoutError: 'เกิดการผิดพลาดขณะกำลังออกจากระบบ. โปรดลองอีกครั้ง!',
    goPremium: 'ใช้พรีเมี่ยม',
  },
  common: {
    save: 'บันทึก',
    edit: 'แก้ไข',
    delete: 'ลบ',
    cancel: 'ยกเลิก',
    search: 'ค้นหา',
    ok: 'ตกลง',
    showMore: 'ดูเพิ่มเติม',
    close: 'ปิด',
    loading: 'กำลังโหลด...',
    learnMore: 'เรียนรู้เพิ่มเติม',
    beta: 'ทดสอบ',
    warning: 'คำเตือน',
    goTo: 'ไปที่',
    goToApp: 'ไปที่ App',
    login: 'เข้าสู่ระบบ',
    pleaseWait: 'โปรดรอสักครู่...',
    success: 'เสร็จสิ้น!',
    signUp: 'ลงชื่อเข้าใช้',
  },
  settings: {
    basicSettings: 'การตั้งค่าเบื้องต้น',
    username: '',
    email: 'Email',
    subscription: 'สมัครสมาชิก',
    plan: 'แผน',
    validTo: 'ใช้ได้ถึง',
    freePlan: 'ฟรี',
    trialPlan: 'ทดลอง',
    premiumPlan: 'พรีเมี่ยม',
    manageSubscription: 'คุณสามารถจัดการการสมัครสมาชิกได้บนบัญชี PayPal ของคุณ',
    pubgUsernames: 'ชื่อบัญชี PUBG (เฉพาะ PUBG PC)',
    pubgUsername: 'ชื่อบัญชี PUBG',
    enterPubgUsername: 'โปรดใส่ชื่อบัญชี PUBG (กรณีจำเป็น)',
    status: 'สถานะ',
    pendingConfirmation: 'รอการยืนยัน',
    invlid: 'ไม่ถูกต้อง',
    active: 'Active',
    password: 'รหัสผ่าน',
    notifications: 'การแจ้งเตือน',
    emailNotifications: 'การแจ้งเตือนทาง Email',
    currentPassword: 'รหัสผ่านปัจจุบัน',
    enterCurrentPassword: 'โปรดใส่รหัสผ่านปัจจุบันของคุณ',
    newPassword: 'รหัสผ่านใหม่',
    enterNewPassword: 'โปรดใส่รหัสผ่านใหม่ของคุณ',
    passwordConfirmation: 'ยืนยันรหัสผ่าน',
    enterPasswordConfirmation: 'โปรดใส่รหัสผ่านใหม่ของคุณอีกครั้ง',
    changePassword: 'เปลี่ยนรหัสผ่าน',
    promoCode: 'โค้ดโปรโมชั่น',
    use: 'ใช้',
    wrongPromoCode: 'โค้ดโปรโมชั่นไม่ถูกต้อง',
    promoCodeSuccess: 'ใช้โค้ดโค้ดโปรโมชั่นเสร็จสิ้น หน้าจะโหลดอีกครั้ง',
  },
  regions: {
    allRegions: 'ทุกภูมิภาค',
    global: 'ทั่วโลก',
    asia: 'เอเชีย',
    europe: 'ยุโรป',
    southAmerica: 'อเมริกาใต้',
    northAmerica: 'อเมริกาเหนือ',
    oceania: 'โอเชียเนีย',
    apac: 'เอเชีย-แปซิฟิก',
  },
  tournamentSearch: {
    tournamentFilter: 'ตัวกรองทัวร์นาเม้นต์',
    tournamentName: 'ชื่อทัวร์นาเม้นต์',
    clearFilter: 'ล้างตัวกรอง',
    missingTournament: 'ไม่พบทัวร์นาเมนต์?',
    recentTournaments: 'ทัวร์นาเม้นต์ล่าสุด',
    allTournaments: 'ทัวร์นาเม้นต์ทั้งหมด',
    tournament: 'ทัวร์นาเม้นต์',
    createTournament: 'สร้างทัวร์นาเม้นต์',
    noTournaments: 'ไม่มีทัวร์นาเมนต์',
  },
  content: {
    title: 'ข่าวสาร',
  },
  teamRanking: {
    title: 'Team Ranking', // TODO missing
  },
  comment: {
    newCommentTitle: 'เขียนความคิดเห็น',
    newCommentPlaceholder: 'ความคิดเห็นของคุณ',
  },
  tournament: {
    tournament: 'ทัวร์นาเม้นต์',
    tournamentInfo: 'ข้อมูลทัวร์นาเม้นต์',
    region: 'ภูมิภาค',
    matches: 'แมตช์',
    leaderboards: 'กระดานอันดับ',
    prizeLeaderboards: 'เงินรางวัลกระดานอันดับ',
    playerStats: 'สถิติผู้เล่น',
    teamStats: 'สถิติทีม',
    selectTournament: 'เลือกทัวร์นาเม้นต์',
    noMatchesFound: 'ไม่พบการแข่งขัน',
    noLeaderboards: 'ไม่มีกระดานจัดอันดับสำหรับทัวร์นาเมนต์นี้',
    leaderboardsNotFound: 'กระดานจัดอันดับไม่มีหรือยังไม่มีการแข่งขัน โปรดลองอีกครั้งในภายหลัง',
    noPlayerStats: 'ไม่มีสถิติสำหรับทัวร์นาเมนต์นี้',
    playerStatsNotFound: 'ไม่มีสถิติหรือยังไม่มีการแข่งขัน โปรดลองอีกครั้งในภายหลัง',
    tournamentPhase: 'รูปแบบทัวร์นาเม้นต์',
    selectTournamentPhase: 'เลือกรูปแบบทัวร์นาเม้นต์',
    loadingMatches: 'กำลังโหลดแมตช์ ...',
  },
  scrimSearch: {
    scrims: 'Scrims',
    scrim: 'Scrim',
  },
  userMatches: {
    searchByUsername: 'ค้นหาด้วยชื่อผู้ใช้',
    pubgUsername: 'ชื่อบัญชีผู้ใช้ PUBG (กรณีสำคัญ)',
    matchesOf: 'การแข่งขันของ',
    enterPubgUsername: 'ใส่ชื่อบีญชีผู้ใช้ PUBG',
    usernameNotTracked: 'เราไม่ได้ติดตามชื่อผู้ใช้นี้ดังนั้นเราจึงอาจไม่มีข้อมูลที่ตรงกันทั้งหมด คุณสามารถเพิ่มชื่อผู้ใช้เข้ามาได้',
    settings: 'การตั้งค่า',
    favoriteUsers: 'รายการโปรด',
  },
  match: {
    rank: 'อันดับ',
    date: 'วันที่',
    match: 'แมตช์',
    map: 'แผนที่',
    type: 'ประเภท',
    zone: 'โซน',
    analyzeStats: 'การวิเคราะห์ / สถิติ',
    mode: 'โหมด',
    duration: 'ระยะเวลา',
  },
  matchDetails: {
    matchDetails: 'รายละเอียดการแข่งขัน',
    matchStats: 'สถิติการแข่งขัน',
    match: 'แมตช์',
    map: 'แผนที่',
    mode: 'โหมด',
    duration: 'ระยะเวลา',
  },
  matchStats: {
    rank: 'อันดับ',
    wins: 'ชนะกินไก่',
    totalPoints: 'คะแนนรวมทั้งหมด',
    avgTotalPoints: 'คะแนนรวมเฉลี่ย',
    avgRank: 'อันดับเฉลี่ย',
    points: 'คะแนนอันดับ',
    avgPoints: 'คะแนนอันดับเฉลี่ย',
    twr: 'TWR',
    numOfMatches: 'จำนวนการแข่งขัน',
    team: 'ทีม',
    player: 'ผู้เล่น',
    username: 'ชื่อผู้เล่น',
    kills: 'ฆ่า',
    avgKills: 'ฆ่าเฉลี่ย',
    assists: 'ช่วยเหลือ',
    kda: 'KDA',
    kas: 'KAS',
    damageDealt: 'สร้างความเสียหาย',
    avgDamageDealt: 'สร้างความเสียหายเฉลี่ย',
    damageTaken: 'ความเสียหายที่ได้รับ',
    avgDamageTaken: 'ความเสียหายที่ได้รับเฉลี่ย',
    dbnos: 'ล้ม',
    revives: 'ฟื้น',
    headshotKills: 'ฆ่าโดยยิงหัว',
    longestKill: 'ฆ่าที่ไกลที่สุด (ม)',
    killSteals: 'การฆ่าที่ถูกขโมย',
    killsStolenFrom: 'ขโมยการฆ่าจากผู้เล่นคนอื่น',
    grenadePickup: 'หยิบระเบิดมือ',
    grenadeDrop: 'ดรอประเบิด',
    grenadeThrow: 'ขว้างระเบิด',
    grenadeDamage: 'ความเสียหายจากระเบิด',
    molotovPickup: 'หยิบ Molotovs',
    molotovDrop: 'ดรอป Molotovs',
    molotovThrow: 'ขว้าง Molotovs',
    molotovDamage: 'ความเสียหายจาก Molotovs',
    smokebombPickup: 'หยิบระเบิดควัน',
    smokebombDrop: 'ดรอประเบิดควัน',
    smokebombThrow: 'ขว้างระเบิดควัน',
    flashbangPickup: 'หยิบแฟลช',
    flashbangDrop: 'ดรอปแฟลช',
    flashbangThrow: 'ขว้างแฟลช',
    swimDistance: 'ระยะทางการว่ายน้ำ (กม)',
    walkDistance: 'ระทางทางการเดิน (กม)',
    rideDistance: 'ระยะทางการขับขี่ (กม)',
    timeSurvived: 'เวลาการรอดชีวิต',
    avgTimeSurvived: 'เวลาการรอดชีวิตเฉลี่ย',
    deathType: 'สาเหตุการตาย',
    heals: 'รักษา',
    healthRecovered: 'การฟื้นฟูเลือด',
    boosts: 'บูส',
    vehicleDestroys: 'ทำลายยานพาหนะ',
    kd: 'KD',
    killsKnocks: 'ฆ่า / ล้ม',
    arDamage: 'สร้างความเสียหาย (AR)', // TODO missing
    dmrDamage: 'สร้างความเสียหาย (DMR)', // TODO missing
    diedFirst: 'Died 1st', // TODO missing
    diedSecond: 'Died 2nd', // TODO missing
    diedThird: 'Died 3rd', // TODO missing
    diedForth: 'Died 4th', // TODO missing
    knocked: 'Knocked', // TODO missing
    revived: 'Revived', // TODO missing
    damageDealtDamageTaken: 'สร้างความเสียหาย / ความเสียหายที่ได้รับ',
    damageTakenFromBlueZone: 'ความเสียหายที่ได้รับ (Zone)', // TODO missing
    damageTakenFromEnemy: 'ความเสียหายที่ได้รับ (Enemy)', // TODO missing
    twrScoreExplained: 'TWR - Twire Weight Rating',
    stolenKillsExplained: 'การฆ่าที่ถูกขโมย - เมื่อผู้เล่นฆ่าใครบางคนที่ถูกทีมอื่นน็อค',
    stolenKillsFromExplaines: 'การฆ่าที่ขโมยมาจาก - เมื่อผู้เล่นน็อคใครสักคนและพวกเขาก็ถูกทีมศัตรูสังหาร',
  },
  tools: {
    save: 'บันทึก',
    undo: 'เลิกทำ',
    clearAll: 'ล้างทั้งหมด',
    zonesToDraw: 'วาดโซน',
    pen: 'เขียน',
    playerDetails: 'รายละเอียดผู้เล่น',
    rotationDetails: 'รายละเอียดการเคลื่อนที่',
    playerNames: 'ชื่อผู้เล่น',
    teamNames: 'ชื่อทีม',
    teamLogos: 'Team Logos', // TODO missing
    showDeadPlayers: 'แสดงผู้เล่นที่ตายแล้ว',
    grid: 'ตาราง',
    planePath: 'เส้นทางเครื่องบิน',
    showPlanePaths: 'แสดงเส้นทางของเครื่องบิน',
    showRotations: 'แสดงการเคลื่อนที่',
    showCircles: 'แสดงวง',
    showLandingSpots: 'แสดงจุดที่ลง',
    heatmap: 'Heatmap', // TODO missing
    help: 'ช่วยเหลือ',
  },
  help: {
    help: 'ช่วยเหลือ',
    tutorial: 'บทช่วยสอน',
    runTutorial: 'เริ่มบทช่วยสอน',
    shortcuts: 'ทางลัด',
    keyBinding: 'ผูกคีย์',
    description: 'คำอธิบาย',
    playPause: 'เล่น/หยุด',
    stepBackward: 'ถอยหลัง (2 วินาที)',
    stepForward: 'ไปข้างหน้า (2 วินาที)',
    previousSpeed: 'ความเร็วในการเล่นก่อนหน้านี้',
    nextSpeed: 'ความเร็วในการเล่นถัดไป',
    gird: 'แสดง / ซ่อน ตาราง',
    teamNames: 'แสดง / ซ่อน ชื่อทีม',
    playerNames: 'แสดง / ซ่อม ชื่อผู้เล่น',
    deadPlayers: 'แสดง / ซ่อน ผู้เล่นที่ตาย',
    planePath: 'แสดง / ซ่อน เส้นทางเครื่องบิน',
    playerDetails: 'เปิด / ปิด รายละเอียดผู้เล่น',
    rotationDetails: 'เปิด / ปิด รายละเอียดการเคลื่อนที่',
    fullscreen: 'เปิด / ปิด เต็มจอ',
  },
  matchAnalyzer: {
    names: 'ชื่อ',
    trace: 'ติดตาม',
    emptyInventory: 'ช่องเก็บของว่างเปล่า',
  },
  teamsSearch: {
    searchByTeam: 'ค้นหาด้วยทีม',
    teamNameOrInitial: 'ชื่อทีมหรือชื่อย่อ',
    teams: 'ทีม',
    team: 'ทีม',
    noResults: 'ไม่มีผลลัพธ์',
    oldRotationAnalyzer: 'การวิเคราะห์การเคลื่อนที่เก่า',
    tryNow: 'ลองเลย',
  },
  rotationAnalyzer: {
    rotationAnalyzer: 'การวิเคราะห์การเคลื่อนที่',
    selectMap: 'เลือกแผนที่เพื่อวิเคราะห์',
    match: 'แมตช์',
    hideAll: 'ซ่อนทั้งหมด',
    show: 'แสดง',
    rank: 'อับดับ',
    kills: 'ฆ่า',
    damageDealt: 'ดาเมจที่ทำได้',
    damageTaken: 'ดาเมจที่ได้รับ',
    zoneDirection: 'ทิศทางของโซน',
    phases: 'ระยะ',
    walking: 'เดิน',
    driving: 'ขับขี่',
    endingZoneArea: 'จุดที่วงจบ',
    selectZoneArea: 'เลือกพื้นที่โซน',
    selectTeam: 'เพิ่มทีมอย่างน้อยหนึ่งทีม',
    zone: 'โซน',
    reset: 'รีเซ็ต',
    resetWarning: 'การดำเนินการนี้จะลบการเคลื่อนที่ทั้งหมดที่เพิ่มเข้ามาและรีเซ็ตโซนที่เลือก?',
    teams: 'ทีม',
    add: 'เพิ่ม',
    noTeams: 'ไม่มีทีมที่เลือก',
  },
  saved: {
    noPremium: 'คุณลักษณะนี้มีไว้สำหรับผู้ใช้ระดับพรีเมียมเท่านั้น คุณยังสามารถเข้าถึงแมตช์เก่าที่บันทึกไว้ได้ แต่ไม่สามารถบันทึกการแข่งขันใหม่ได้',
    savedMatches: 'แมตช์ที่บันทึกไว้',
    rename: 'เปลี่ยนชื่อ',
    delete: 'ลบ',
    renameFile: 'เปลี่ยนชื่อไฟล์',
    newFileName: 'ชื่อไฟล์ใหม่',
    fileDelete: 'ลบไฟล์',
    fileDeleteWarning: 'คุณแน่ใจใช่ไหมว่าจะลบไฟล์นี้?',
    matchAnalysis: 'การวิเคราห์แมตช์',
    modalRenameTitle: 'ชื่อแมตช์',
    modalRenamePlaceholder: 'ชื่อแมตช์ใหม่',
    modalDeleteTitle: 'ลบแมตช์',
    modalDeletePlaceholder: 'คุณแน่ใจไหมว่าจะลบแมตช์นี้?',
  },
  connect: {
    title: 'ติดตามเรา!',
    discordConnectText: 'มาเชื่อมต่อผ่าน Discord กันเถอะ!',
    connectMe: 'ติดต่อกับเรา!',
  },
  features: {
    title: 'มีอะไรให้คุณบ้าง?',
    feature1Title: 'ทัวร์นาเม้นต์ กระดานจัดอันดับ',
    feature1Text: 'ติดตามการแข่งขันจากทั่วทุกมุมโลก',
    feature2Title: 'สถิติของผู้เล่น',
    feature2Text: 'เปรียบเทียบผู้เล่นและทักษะของพวกเขาในประเภทต่างๆ',
    feature3Title: 'การวิเคราะห์การแข่งขัน',
    feature3Text: 'วิเคราะห์การแข่งขันของคุณและเพิ่มพลังให้กับการทำงานระยะไกลร่วมกัน',
    feature4Title: 'การวิเคราะห์การเคลื่อนที่',
    feature4Text: 'รู้จักการเคลื่อนไหวของฝ่ายตรงข้ามและเตรียมกลยุทธ์การโจมตีของคุณ',
  },
  games: {
    title: 'เกมไหนที่คุณเล่น?',
    availableNow: 'ใช้ได้ในขณะนี้',
    comingSoon: 'เร็ว ๆ นี้',
  },
  partners: {
    title: 'พาทเนอร์',
  },
  pricing: {
    title: 'ราคา',
    advertisement: 'โฆษณา',
    matchAnalyzer: 'การวิเคราะห์การแข่งขัน',
    rotationAnalyzer: 'การวิเคราะห์การเคลื่อนที่',
    drawing: 'การออกแบบ',
    collaborativeDrawing: 'การออกแบบร่วมกัน',
    savingMatches: 'บันทึกแมตช์',
    premium: 'พรีเมี่ยม',
    freePeriod: 'ทดลอง 14 วัน',
    paidMonthly: 'จ่ายเป็นรายเดือน',
    paidQuarterly: 'จ่ายเป็นราย 3 เดือน',
    noCardRequired: 'ไม่ต้องใช้บัตร',
    planContact1: 'หากคุณหรือทีมของคุณต้องการแผนแบบกำหนดเองติดต่อเราได้ที่',
    planContact2: 'หรือทาง',
    planContact3: '',
    free: 'ฟรี',
  },
  payment: {
    title: 'ราคา',
    saved: 'บันทึก',
    ads: 'ลบโฆษณา',
    analyzerMapReplay: 'วิเคราะห์แผนที่ย้อนหลัง',
    seeTeamsRotations: 'ดูการเคลื่อนไหวของทีม',
    workCollaboratively: 'ทำงานร่วมกัน',
    shareDrawingInRealTime: 'แบ่งปันการออกแบบแบบเรียลไทม์',
    drawing: 'ออกแบบ',
    markImportantMoments: 'ทำเครื่องหมายช่วงเวลาสำคัญ',
    saveitForLater: 'บันทึกไว้ดูภายหลัง',
    recommended: 'แนะนำ',
    billedQuarterly: 'เรียกเก็บเงินราย 3 เดือน',
    billedMonthly: 'เรียกเก็บเงินรายเดือน',
    selectPlan: 'เลือกแผน',
    billedNow: 'เก็บเงินตอนนี้',
    alreadyPremium: 'คุณเป็นสมาชิกพรีเมี่ยมเรียบร้อยแล้ว',
    expiresInDays: 'การทดลองใช้ฟรีจะหมดลงภายใน 14 วัน',
    learnMore: 'เรียนรู้เพิ่มเติม',
  },
  welcome: {
    title1: 'การเริ่มต้นของคุณ',
    title2: 'นักกีฬาอีสปอร์ต',
    subtitle: 'เครื่องมือวิเคราะห์สำหรับผู้ชนะ',
    register: 'ลงทะเบียนฟรี',
    learnMore: 'ฉันต้องการเรียนรู้เพิ่มเติม',
  },
  forgotPassword: {
    emailSent: 'ส่ง Email แล้ว!',
    emailSentMessage: 'อีเมลพร้อมลิงก์รีเซ็ตรหัสผ่านถูกส่งถึงคุณ',
    forgotPassword: 'ลืมรหัสผ่าน',
    inputUsername: 'ใส่ชื่อบีญชีผู้ใช้ของคุณ',
    sentResetLink: 'ส่งลิ้งก์รีเซ็ต',
  },
  login: {
    signUp: 'ลงชื่อเข้าใช้',
    dontHaveAccountLong: 'ยังไม่มีบัญชีตอนนี้? คุณกำลังรออะไรอยู่! ลงทะเบียนและเริ่มพัฒนาทักษะของคุณด้วยเครื่องมือที่ยอดเยี่ยมของเรา!',
    dontHaveAccount: 'ยังไม่มีบัญชีตอนนี้?',
    registerNow: 'ลงทะเบียนตอนนี้!',
    login: 'เข้าสู่ระบบ',
    resetPassword: 'เปลี่ยนรหัสผ่าน',
    reset: 'เปลี่ยน',
    signInAccount: 'ลงชื่อเข้าใช้บัญชีของคุณ',
    passwordResetNeeded: 'คุณต้องเปลี่ยนรหัสผ่านของคุณ',
    username: 'ชื่อบัญชีผู้ใช้',
    password: 'รหัสผ่าน',
    passwordConfirmation: 'ยืนยันรหัสผ่าน',
    forgotPassword: 'ลืมรหัสผ่าน?',
    resetPasswordRequired: 'คุณต้องเปลี่ยนรหัสผ่านของคุณ',
    wrongCredentials: 'ชื่อบัญชีหรือรหัสผ่านไม่ถูกต้อง',
    passwordsDontMatch: 'รหัสผ่านไม่ตรงกัน',
    passwordToShort: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัว, ตัวอักษรอย่างน้อยหนึ่งตัว',
  },
  register: {
    register: 'ลงทะเบียน',
    thankYou: 'ขอบคุณ!',
    thankYouLong: 'ขอบคุณสำหหรับการสร้างบัญชีผู้ใช้! เช็ค eamil ของคุณเพื่อลงทะเบียนให้เสร็จสิ้น',
    username: 'ชื่อบัญชีผู้ใช้',
    email: 'Email',
    password: 'รหัสผ่าน',
    repeatPassword: 'ใส่รหัสผ่านอีกครั้ง',
    subscribeToNewsletter: 'สมัครสมาชิกเพื่อรับข่าวใหม่จากพวกเรา',
    readAndUnderstand: 'ฉันได้อ่านและทำความเข้าใจเรียบร้อยแล้ว',
    termsOfUse: 'ข้อกำหนดการใช้งาน',
    createAccount: 'สร้างบัญชีผู้ใช้',
    alreadyRegistered: 'ลงทะเบียนเรียบร้อยแล้ว?',
    invalidUsername: 'ชื่อบัญชีผู้ใช้ไม่ถูกต้อง',
    invalidEmail: 'อีเมลไม่ถูกต้อง',
    invalidPassword: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัว, ตัวอักษรอย่างน้อยหนึ่งตัวและตัวเลขหนึ่งตัว',
    passwordsDontMatch: 'รหัสผ่านไม่ตรงกัน',
    termsRequired: 'คุณต้องยอมรับเงื่อนไขการใช้งาน',
  },
  resetPassword: {
    resetPassword: 'เปลี่ยนรหัสผ่าน',
    selectNewPassword: 'ใส่รหัสผ่านใหม่',
    password: 'รหัสผ่าน',
    passwordConfirmation: 'ยืนยันรหัสผ่าน',
  },
  verification: {
    oops: 'Oops..',
    verificationFailed: 'การยืนยันล้มเหลว! ลิงก์ของคุณเสีย,หมดอายุหรือได้รับการยืนยันแล้ว',
    resendEmail: 'ส่ง email อีกครั้ง',
    verifyingEmail: 'กำลังยืนยัน email ของคุณ...',
    verificationSuccess: 'การยืนยันเสร็จสิ้น! คุณสามารถเข้าสู่ระบบได้',
  }
}